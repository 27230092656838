import { IRoute } from '@/shared/models/route.model';
import { lazy } from 'react';

export const ROUTE_LANGUAGES_DECLARATIONS = Object.freeze({
  AdminLanguagePage: 'languages',
  AdminLanguageEditPage: 'languages/:languageId/edit',
});

export const AdminLanguagesRoutes: IRoute[] = [
  {
    path: ROUTE_LANGUAGES_DECLARATIONS.AdminLanguagePage,
    component: lazy(() => import('@/admin/pages/languages/LanguagePage')),
  },
];
