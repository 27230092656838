import { api } from '@/shared/services/api/api';
import { IUserModel } from '@/shared/models/user.model';

export interface IForgotPasswordUpdate {
  password: string;
  confirmPassword: string;
  token: string;
}

const baseUrl = 'users';

export const customerUsersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMe: build.query<IUserModel, void>({
      query: () => ({
        url: `${baseUrl}:me`,
      }),
      providesTags: () => [{ type: 'CustomerUser', id: 'me' }],
    }),
    forgetPassword: build.mutation<void, { email: string }>({
      query: (values) => ({
        url: `${baseUrl}/forgot-pwd`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'CustomerUser', id: 'forgetPassword' }],
    }),
    forgetPasswordUpdate: build.mutation<any, IForgotPasswordUpdate>({
      query: (values: IForgotPasswordUpdate) => ({
        url: `${baseUrl}/forgot-pwd/update/${values.token}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'CustomerUser', id: 'forgetPassword' }],
    }),
  }),
});

export const { useGetMeQuery, useLazyGetMeQuery, useForgetPasswordMutation, useForgetPasswordUpdateMutation } =
  customerUsersApi;
