import { useRegisterPropertyMutation } from '@/editor/services/http/properties.api';
import { IPropertyApiResponseModel } from '@/shared/models/property.api.response.model';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useCallback, useEffect } from 'react';

const useRegisterCarouselProperty = (currentData: IPropertyApiResponseModel | undefined, downloadedCarouselIndex:string | null, carouselItemIndex:number) => {
    const [registerCarouselProperty] = useRegisterPropertyMutation();

  const registerProperty = useCallback(() => {
    if (currentData && (downloadedCarouselIndex == carouselItemIndex.toString())) {
      const payload = {
        propertyId: currentData?.propertyId,
        address: currentData?.propertyAddress,
      };

      registerCarouselProperty(payload)
        .unwrap()
        .then((res) => {
          const existingPropertyArray = JSON.parse(getValueFromLs('propertyArray') || '[]');
          const propertyData = {
            id: res.id,
          };

          if (JSON.stringify(existingPropertyArray[downloadedCarouselIndex]) !== JSON.stringify(propertyData)) {
            existingPropertyArray[downloadedCarouselIndex] = propertyData;
            setKeyValuePairToLS('propertyArray', JSON.stringify(existingPropertyArray));
          }
        })
        .catch((error) => {
          console.error('Error registering property:', error);
        });
    }
  }, [currentData, downloadedCarouselIndex]);


  return registerProperty;
};

export default useRegisterCarouselProperty;

export const useRegisterPropertyEffect = (registerProperty: () => void) => {

  useEffect(() => {
    registerProperty();
  }, [registerProperty]);

};