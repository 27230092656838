import ExternalCompanyIdEnum from "@/shared/constants/external-company-id.enum";

export const bidtheatreClientStyles:any = {
    [ExternalCompanyIdEnum.SMG]: {
      backgroundColor: '#859419',
      logo: '/images/svenska/svenska_logo.png',
      fontFamily: 'Arial',
      textColor: '#fff',
      primaryColor: '#859419',
    },
    [ExternalCompanyIdEnum.MSPECS]: {
      backgroundColor: '#255a88',
      logo: '/images/mspecs/mspecs_logo.png',
      textColor: '#fff',
      primaryColor: '#255a88',
    },
    [ExternalCompanyIdEnum.STRAND_MAKLERI]: {
      backgroundColor: '#161b1e',
      logo: '/images/strandmakleri/strand_logo.png',
      fontFamilyOne: 'Roboto',
      fontFamilyTwo: 'Montserrat',
      textColor: '#fff',
      primaryColor: '#161b1e',
    },
    [ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI]: {
      backgroundColor: '#5a7955',
      logo: '/images/studiofastighetsmakleri/studio_logo.png',
      fontFamilyOne: 'Montserrat',
      fontFamilyTwo: 'Montserrat',
      textColor: '#fff',
      primaryColor: '#5a7955',
    },
    [ExternalCompanyIdEnum.HOME_BY_DEAN]: {
      backgroundColor: '#aa0061',
      logo: '/images/homebydean/homebydean_logo.png',
      fontFamilyOne: 'ITCGaramondStd-LtCond',
      fontFamilyTwo: 'ITCGaramondStd-LtCond',
      textColor: '#fff',
      primaryColor: '#aa0061',
    },
  };