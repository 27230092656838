import { AvailablePlatformsEnum } from '@/editor/shared/constants/available-platforms';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import BarChart from '@/statistics/components/BarChart';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

export interface ICampaignMetaBarCharts {
  order: ISingleOrderWithDetailsModel;
}

const CampaignMetaBarCharts: FC<ICampaignMetaBarCharts> = (props: ICampaignMetaBarCharts): JSX.Element => {
  const { order } = props;
  const { t } = useTranslation();
  const [totalClicks, setTotalClicks] = useState<number>(0);
  const [totalImpressions, setTotalImpressions] = useState<number>(0);
  const [impressionsData, setImpressionsData] = useState<number[]>([0, 0, 0]);
  const [clicksData, setClicksData] = useState<number[]>([0, 0, 0]);
  const [platformList, setPlatformList] = useState<string[]>([]);

  const [impressionsLabels, setImpressionsLabels] = useState<string[]>([]);
  const [clicksLabels, setClicksLabels] = useState<string[]>([]);

  useEffect(() => {
    const data = new Map<string, { clicks: number; impressions: number }>();
    let newTotalClicks = 0;
    let newTotalImpressions = 0;
    const newImpressionsData: number[] = [];
    const newClicksData: number[] = [];

    order.advertisements.forEach((el) => {
      const platform = `${t(el.platform)}`.toUpperCase();
      if (!platformList.includes(platform)) setPlatformList((prev) => [...prev, platform]);

      setImpressionsLabels((prev) => [...prev, platform]);
      setClicksLabels((prev) => [...prev, platform]);

      const setData = (platform: string, clicks: number, impressions: number) => {
        data.set(platform, { clicks: clicks, impressions: impressions });
      };

      const adStats = el.advertisementStats;

      switch (el.platform) {
        case AvailablePlatformsEnum.META:
          if (adStats.metaStats) setData(platform, adStats.metaStats.totalClicks, adStats.metaStats.totalImpressions);
          break;
        case AvailablePlatformsEnum.DELTA:
          if (adStats.deltaStats)
            setData(platform, adStats.deltaStats.totalClicks, adStats.deltaStats.totalImpressions);
          break;
        case AvailablePlatformsEnum.SNAPCHAT:
          if (adStats.snapChatStats) setData(platform, adStats.snapChatStats.swipes, adStats.snapChatStats.impressions);
          break;
        case AvailablePlatformsEnum.BIDTHEATRE:
          if (adStats.bidtheatreStats)
            setData(platform, adStats.bidtheatreStats.totalClicks, adStats.bidtheatreStats.totalImpressions);
          break;
        case AvailablePlatformsEnum.BOARDS:
          if (adStats.doohStats) setData(platform, adStats.doohStats.totalClicks, adStats.doohStats.totalImpressions);
          break;
        default:
          break;
      }

      const platformData = data.get(platform) || { clicks: 0, impressions: 0 };
      newTotalClicks += platformData.clicks;
      newTotalImpressions += platformData.impressions;
      newImpressionsData.push(platformData.impressions);
      newClicksData.push(platformData.clicks);
    });

    if (order.propertyFinnStats) {
      newTotalClicks += order.propertyFinnStats.saved;
      newTotalImpressions += order.propertyFinnStats.views;
      setPlatformList((prev) => [...prev, 'FINN.NO']);
      setImpressionsLabels((prev) => [...prev, 'FINN.NO']);
      setClicksLabels((prev) => [...prev, 'Finn.no lagret favoritt']);
      newImpressionsData.push(order.propertyFinnStats.views);
      newClicksData.push(order.propertyFinnStats.saved);
    }

    if (order.propertyHjemStats?.length > 0) {
      const page_views = order.propertyHjemStats.reduce((sum, { page_views }) => sum + +page_views, 0);
      const views = order.propertyHjemStats.reduce((sum, { views }) => sum + +views, 0);
      const favorites = order.propertyHjemStats.reduce((sum, { favorites }) => sum + +favorites, 0);
      newTotalClicks += favorites;
      setClicksData((prev) => [...prev, favorites]);
      setClicksLabels((prev) => [...prev, 'hjem.no lagret favoritt']);
      newTotalImpressions += views;
      newTotalImpressions += page_views;
      setImpressionsLabels((prev) => [...prev, 'HJEM.NO FB annonse']);
      newImpressionsData.push(views);
      setImpressionsLabels((prev) => [...prev, 'HJEM.NO sidevisninger']);
      newImpressionsData.push(page_views);
    }

    setTotalClicks(newTotalClicks);
    setTotalImpressions(newTotalImpressions);
    setImpressionsData(newImpressionsData);
    setClicksData(newClicksData);

    console.log(newImpressionsData);
    console.log(impressionsLabels);

    return () => {};
  }, [order]);

  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <Grid item xs={12} p={2}>
          <Divider>
            <Typography variant="h4">{t('campaignSummary')}</Typography>
          </Divider>
        </Grid>
        <CardContent>
          <Grid container display={'flex'} justifyContent={'space-around'}>
            <Grid item xs={12} md={5}>
              <Box textAlign={'center'}>
                <Typography variant="h5">
                  {t('clicks')} {totalClicks}
                </Typography>
                <BarChart
                  chartType="single"
                  labels={clicksLabels}
                  dataSets={[
                    {
                      label: t('clicks'),
                      data: clicksData,
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box textAlign={'center'}>
                <Typography variant="h5">
                  {t('impressions')} {totalImpressions}
                </Typography>
                <BarChart
                  chartType="single"
                  labels={impressionsLabels}
                  dataSets={[
                    {
                      label: t('impressions'),
                      data: impressionsData,
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CampaignMetaBarCharts;
