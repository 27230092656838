import ExternalCompanyIdEnum from "@/shared/constants/external-company-id.enum";

export const nativeClientStyles:any = {
    [ExternalCompanyIdEnum.SMG]: {
      logo: '/images/svenska/svenska_logo.png',
      fontFamily: 'GothamNarrowBook',
      primaryColor: '#859419',
    },
    [ExternalCompanyIdEnum.MSPECS]: {
      logo: '/images/mspecs/mspecs_logo.png',
      primaryColor: '#255a88',
    },
    [ExternalCompanyIdEnum.SORMEGLEREN]: {
      logo: '/images/sormegleren/sormegleren_logo_a_rgb.png',
      primaryColor: '#231464',
    },
    [ExternalCompanyIdEnum.FREMEIENDOMSMEGLING]: {
      logo: '/images/fremeiendomsmegling/Native_Frem_Eiendomsmegling.png',
      primaryColor: 'rgb(244, 125, 75)',
    },
    [ExternalCompanyIdEnum.STRAND_MAKLERI]: {
      logo: '/images/strandmakleri/strand_logo.png',
      fontFamilyOne: 'Roboto',
      fontFamilyTwo: 'Montserrat',
      primaryColor: '#000',
    },
    [ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI]: {
      logo: '/images/studiofastighetsmakleri/studio_logo.png',
      fontFamilyOne: 'Montserrat',
      fontFamilyTwo: 'Montserrat',
      primaryColor: '#5a7955',
    },
    [ExternalCompanyIdEnum.HOME_BY_DEAN]: {
      logo: '/images/homebydean/homebydean_logo.png',
      fontFamilyOne: 'ITCGaramondStd-LtCond',
      fontFamilyTwo: 'ITCGaramondStd-LtCond',
      primaryColor: '#aa0061',
    },
  };