import BidtheatreSummaryStats from '@/shared/components/advertisement-info/BidtheatreSummaryStats';
import BidtheatrePreviewSummary from '@/shared/components/platform-previews/previews/bidtheatre/BidTheatrePreviewSummary';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import { useAppSelector } from '@/store/hooks/redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import SectionHeader from '../SectionHeader';
import DatesBreakDown from '../DatesBreakdown';
import { useTranslation } from 'react-i18next';

export interface IEieBidtheatreSectionProps {
  advertisement: IFullAdvertisementModel;
  extCompanyId: string;
}

const EieBidtheatreSection: FC<IEieBidtheatreSectionProps> = (props: IEieBidtheatreSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    advertisement: { advertisementStats },
    extCompanyId,
  } = props;

  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const currentCreativeForm = creativeFormsState['bidtheatre']['single'];

  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <SectionHeader
          platform={'bidtheatre'}
          totalClicks={advertisementStats?.bidtheatreStats?.totalClicks ?? 0}
          totalImpressions={advertisementStats?.bidtheatreStats?.totalImpressions ?? 0}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={5}>
              <BidtheatrePreviewSummary externalCompanyId={extCompanyId} values={currentCreativeForm!} />
            </Grid>
            {advertisementStats.bidtheatreStats?.totalClicks! > 0 ? (
              <Grid item xs={12} md={6} mx={'auto'}>
                <DatesBreakDown datesBreakdown={advertisementStats?.bidtheatreStats?.datesBreakdown!} />
                <Divider sx={{ my: 2 }} />
                <BidtheatreSummaryStats bidtheatreStats={advertisementStats?.bidtheatreStats!} />
              </Grid>
            ) : (
              <Grid item xs={12} m={2} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography m={2} variant="h2">
                  {t('adWithNoStats')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EieBidtheatreSection;
