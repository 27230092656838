import Box from '@mui/material/Box';
import { FC, JSX } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import EmptyImageCard from '../EmptyImageCard';
import 'swiper/css/navigation';
import { useAppSelector } from '../../../../../store/hooks/redux';

const DynamicSnapChatPreview: FC = (): JSX.Element => {
  const medias = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState.snapchat.dynamic!.creativeMedias
  );

  return (
    <Box sx={{ width: '100%' }}>
      {medias.length > 0 ? (
        <Swiper
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          grabCursor={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}>
          {medias.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                style={{ width: '100%', height: '100%', maxHeight: '400px', objectFit: 'cover' }}
                src={image?.preSignedUrl}
                alt="preview"></img>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default DynamicSnapChatPreview;
