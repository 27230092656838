import { useCallback, type JSX } from 'react';
import { PlatformType } from '../../../constants/available-platforms';
import {
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from './models/carousel-creative-form-values';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { useAppDispatch } from '../../../../../store/hooks/redux';
import CreativeTypesEnum from '../../../constants/creatives-types.enum';
import CommonButton from '../../../../../shared/components/CommonButton';
import { setMediaLibraryDrawerState } from '../../../../store/reducers/modals-drawers.slice';
import { IBasicCreativeFormValues } from './models/basic-creative-form-values.model';
import { addCarouselItem } from '../../../../store/reducers/creative-step.slice';
import CarouselCreativeItem from './CarouselCreativeItem';
import useDetectCarouselItemChanges from './hooks/use-detect-carousel-item-changes.hook';

export interface ICarouselCreativeItemListProps {
  platform: PlatformType;
  initialValues: IBasicCreativeFormValues;
}

const CarouselCreativeItemsList = (props: ICarouselCreativeItemListProps): JSX.Element => {
  const { platform, initialValues } = props;

  const formikCtx = useFormikContext();
  const dispatch = useAppDispatch();

  useDetectCarouselItemChanges(platform);

  const onAddItemClick = () => {
    const typedValues = (formikCtx.values! as ICarouselCreativeFormValuesModel).creativeItems;
    const preparedValue = {
      headline: initialValues?.headline?.slice(0, 34) || '',
      caption: initialValues?.caption?.slice(0, 34) || '',
      propertyId: initialValues?.propertyId?.slice(0, 34) || '',
      description: initialValues?.description?.slice(0, 90) || '',
      link: initialValues?.link?.slice(0, 90) || '',
      creativeMedia: null,
    };

    const newState = [...typedValues, preparedValue];
    setFormikCarouselItems(newState);
    dispatch(addCarouselItem({ platform, value: preparedValue }));
    openMediaLibrary(newState.length - 1);
  };

  const setFormikCarouselItems = useCallback((items: ICarouselCreativeItemFormValuesModel[]) => {
    formikCtx.setFieldValue('creativeItems', items);
  }, []);

  const openMediaLibrary = (index: number) => {
    dispatch(
      setMediaLibraryDrawerState({
        isOpened: true,
        creativeType: CreativeTypesEnum.CAROUSEL,
        carouselItemIndex: index,
        platform,
      })
    );
  };

  const typedCarouselItems = (formikCtx.getFieldMeta('creativeItems').value as ICarouselCreativeItemFormValuesModel[])!;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <CommonButton
            disabled={typedCarouselItems.length >= 5 || typedCarouselItems.some((el) => !el.creativeMedia)}
            onClick={() => onAddItemClick()}>
            Add item
          </CommonButton>
        </Box>
      </Grid>
      {typedCarouselItems.map((el: ICarouselCreativeItemFormValuesModel, index: number) => {
        return (
          <CarouselCreativeItem
            key={index}
            carouselItem={el}
            carouselItemIndex={index}
            platform={platform}
            openMediaLibrary={openMediaLibrary}
          />
        );
      })}
    </Grid>
  );
};
export default CarouselCreativeItemsList;
