import { IRoute } from '../../shared/models/route.model';
import { lazy } from 'react';

export const ROUTE_PRODUCTS_ITEMS_DECLARATIONS = Object.freeze({
  AdminProductsItemsByCompanyPage: 'products/:productId/product-items',
  AdminProductsItemsNewPage: 'products/:productId/product-items/new',
  AdminProductsItemsEditPage: 'product-items/:productItemId/edit',
});

const ProductsItemsDataTable = lazy(() => import('../pages/product-items/ProductsItemsTablePage'));
const CreateEditProductItemPage = lazy(() => import('../pages/product-items/CreateEditProductItemPage'));

export const ProductsItemsRoutes: IRoute[] = [
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsByCompanyPage,
    component: ProductsItemsDataTable,
  },
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsNewPage,
    component: CreateEditProductItemPage,
  },
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsEditPage,
    component: CreateEditProductItemPage,
  },
];
