import { FC, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IPlatformCreativeFormResolverProps } from '../models/form-resolver-props.interface';
import BasicMetaPreview from '@/shared/components/platform-previews/previews/meta/BasicMetaPreview';
import FacebookSinglePreview from '@/shared/components/platform-previews/previews/meta/FacebookSinglePreview';
import {
  carouselCreativeSchema,
  dynamicCreativeSchema,
  singleCreativeSchema,
  videoCreativeSchema,
} from '@/editor/shared/components/forms/creatives/shared/basic-validation-schema';
import BasicSingleForm from '@/editor/shared/components/forms/creatives/BasicSingleForm';
import MetaDynamicPreview from '@/shared/components/platform-previews/previews/meta/MetaDynamicPreview';
import BasicDynamicForm from '@/editor/shared/components/forms/creatives/BasicDynamicForm';
import CarouselCreativeItemsList from '@/editor/shared/components/forms/creatives/CarouselCreativeItemsList';
import MetaCarouselPreview from '@/shared/components/platform-previews/previews/meta/MetaCarouselPreview';
import BasicCarouselForm from '@/editor/shared/components/forms/creatives/BasicCarouselForm';
import MetaVideoPreview from '@/shared/components/platform-previews/previews/meta/MetaVideoPreview';
import BasicVideoForm from '@/editor/shared/components/forms/creatives/BasicVideoForm';

const MetaFormsResolver: FC<IPlatformCreativeFormResolverProps> = ({ creativeType, updating }): JSX.Element => {
  const initialValuesFromStore = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);

  const renderSwitch = (): JSX.Element => {
    switch (creativeType) {
      default:
        return <div></div>;
      case CreativeTypesEnum.SINGLE:
        return (
          <BasicSingleForm
            basePreviews={[BasicMetaPreview]}
            concretePreviews={[FacebookSinglePreview]}
            fieldsRequired={true}
            updating={updating}
            initialValues={initialValuesFromStore[AvailablePlatformsEnum.META][CreativeTypesEnum.SINGLE]}
            platform={AvailablePlatformsEnum.META}
            validationSchema={singleCreativeSchema}
          />
        );
      case CreativeTypesEnum.DYNAMIC:
        return (
          <BasicDynamicForm
            basePreviews={[BasicMetaPreview]}
            concretePreviews={[MetaDynamicPreview]}
            fieldsRequired={true}
            platform={AvailablePlatformsEnum.META}
            initialValues={initialValuesFromStore[AvailablePlatformsEnum.META][CreativeTypesEnum.DYNAMIC]}
            updating={updating}
            validationSchema={dynamicCreativeSchema}
          />
        );
      case CreativeTypesEnum.CAROUSEL:
        return (
          <BasicCarouselForm
            basePreview={[BasicMetaPreview]}
            carouselItems={CarouselCreativeItemsList}
            concretePreview={[MetaCarouselPreview]}
            fieldsRequired={true}
            platform={AvailablePlatformsEnum.META}
            initialValues={initialValuesFromStore[AvailablePlatformsEnum.META][CreativeTypesEnum.CAROUSEL]}
            updating={updating}
            validationSchema={carouselCreativeSchema}
          />
        );
      case CreativeTypesEnum.VIDEO:
        return (
          <BasicVideoForm
            basePreview={[BasicMetaPreview]}
            concretePreview={[MetaVideoPreview]}
            fieldsRequired={false}
            updating={updating}
            initialValues={initialValuesFromStore[AvailablePlatformsEnum.META][CreativeTypesEnum.VIDEO]}
            platform={AvailablePlatformsEnum.META}
            validationSchema={videoCreativeSchema}
          />
        );
    }
  };

  return <div>{renderSwitch()}</div>;
};

export default MetaFormsResolver;
