import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import moment from 'moment';

import { FC, useEffect, useState, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 25,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#1628313d',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: '#162831',
  },
}));

export interface IEieTimeLineProgressProps {
  order: ISingleOrderWithDetailsModel;
}

const EieTimeLineProgress: FC<IEieTimeLineProgressProps> = (props: IEieTimeLineProgressProps): JSX.Element => {
  const { order } = props;

  const { t } = useTranslation();

  const [timeLine, setTimeLine] = useState({
    progress: 0,
    dateStart: '',
    dateEnd: '',
  });

  useEffect(() => {
    const adToUse = order.advertisements[0];
    const adDuration = moment(adToUse.adSet.endTime).diff(moment(adToUse.adSet.startTime), 'days');
    const daysLeft = moment(adToUse.adSet.endTime).diff(moment(), 'days');
    const percentage = Math.round(100 - (daysLeft / adDuration) * 100);

    setTimeLine({
      progress: +percentage > 100 ? 100 : +percentage,
      dateStart: moment(adToUse.adSet.startTime).format('DD.MM.YYYY'),
      dateEnd: moment(adToUse.adSet.endTime).format('DD.MM.YYYY'),
    });

    return () => setTimeLine({ progress: 0, dateStart: '', dateEnd: '' });
  }, [order]);

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid item xs={12}>
            <Divider>
              <Typography fontWeight={400} variant="h4">
                {t('daysLeft')}
              </Typography>
            </Divider>

            <FormLabel
              sx={{
                top: '23px',
                left: '50%',
                zIndex: '1',
                color: '#fff',
                fontWeight: 300,
              }}>
              {timeLine.progress}%
            </FormLabel>
            <BorderLinearProgress variant="determinate" value={timeLine.progress} />

            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <Typography fontWeight={400} variant="h5">
                {timeLine.dateStart}
              </Typography>
              <Typography fontWeight={400} variant="h5">
                {timeLine.dateEnd}
              </Typography>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EieTimeLineProgress;
