import { FC, type JSX } from 'react';
import { useAppSelector } from '../../../../../../store/hooks/redux';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IPlatformCreativeFormResolverProps } from '../models/form-resolver-props.interface';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import DeltaSingleCreativeForm from './DeltaSingleCreativeForm';

const DeltaFormsResolver: FC<IPlatformCreativeFormResolverProps> = ({ handleSubmit, updating }): JSX.Element => {
  const initialValuesFromStore = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);

  return (
    <DeltaSingleCreativeForm
      updating={updating}
      initialValues={
        initialValuesFromStore[AvailablePlatformsEnum.DELTA][
          CreativeTypesEnum.SINGLE
        ]! satisfies ISingleCreativeFormValuesModel
      }
    />
  );
};

export default DeltaFormsResolver;
