import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CollectionsIcon from '@mui/icons-material/Collections';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { FC } from 'react';

interface CarouselHeaderProps {
  carouselItemIndex: number;
  handleMediaLibraryClick: (index: number) => void;
  onDeleteItemClick: (index: number) => void;
}

const CarouselCreativeItemHeader: FC<CarouselHeaderProps> = ({
  carouselItemIndex,
  handleMediaLibraryClick,
  onDeleteItemClick,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Typography color="secondary" variant="h5">
        Enter Headline, Description, URL
      </Typography>
      <IconButton
        sx={{ ml: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
          handleMediaLibraryClick(carouselItemIndex);
        }}>
        <CollectionsIcon />
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDeleteItemClick(carouselItemIndex);
        }}>
        <DeleteOutline />
      </IconButton>
    </Box>
  );
};

export default CarouselCreativeItemHeader;
