import { Formik } from 'formik';
import * as yup from 'yup';
import React, { useState, type JSX } from 'react';
import { SignInStyles } from './styles';
import { IAuthRequest } from '../models/auth.request';
import { useLoginMutation } from '../services/http/auth.api';
import { useAppDispatch } from '../../store/hooks/redux';
import { setCredentials } from '../store/reducers/auth.slice';
import { deleteKeyValuePairFromLS, setCredentialsToLS } from '../../shared/services/localstorage';
import { useNavigate } from 'react-router-dom';
import { SymfonyRolesEnum, checkValidityAndRoleFromSymfonyAccessToken } from '../services/accesstoken.handler';
import { EDITOR_LS_CONSTANTS } from '../../editor/shared/constants/editor-LS.constants';
import CommonButton from '@/shared/components/CommonButton';
import CommonTextField from '@/shared/components/CommonTextField';
import { useTranslation } from 'react-i18next';
import useAppInitLanguage from '@/shared/hooks/app-init-hook';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ForgetPasswordDialog from './forget-password/ForgetPasswordDialog';
import Loader from '@/shared/components/Loader';

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  password: yup.string().required('Password is required'),
});

const initialValues: IAuthRequest = {
  email: '',
  password: '',
};

const SignIn = (): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [login, { isLoading, isSuccess }] = useLoginMutation();
  useAppInitLanguage(isSuccess);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleForgetPassword = () => {
    setIsDialogOpen(true);
  };

  const handleSubmitLoginForm = async (credentials: IAuthRequest) => {
    try {
      const result = await login(credentials).unwrap();
      deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);
      setCredentialsToLS(result);
      dispatch(setCredentials(result));

      if (checkValidityAndRoleFromSymfonyAccessToken(result.accessToken, SymfonyRolesEnum.ADMIN)) {
        navigate('/dashboard');
        return;
      }

      navigate('/editor');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await handleSubmitLoginForm(values);
          }}>
          {({ errors, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setSubmitting }) => {
            return (
              <div>
                <Grid container item display={'flex'} height={'100vh'}>
                  <Grid display={'flex'} item xs={12} alignSelf={'center'} justifyContent={'center'}>
                    <Paper
                      sx={{
                        p: 2,
                        width: 640,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Grid item container>
                        <Grid item xs={12}>
                          <form noValidate onSubmit={(e) => handleSubmit(e)}>
                            <CommonTextField
                              name="email"
                              label="Email"
                              value={values.email}
                              onChange={handleChange}
                              autoComplete="email"
                              required
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                            />
                            <CommonTextField
                              sx={SignInStyles.elementsMarginTop}
                              type="password"
                              name="password"
                              label="Password"
                              value={values.password}
                              onChange={handleChange}
                              required
                              autoComplete="password"
                              error={Boolean(touched.password && errors.password)}
                              helperText={touched.password && errors.password}
                            />
                            <Box display={'flex'} justifyContent={'space-between'}>
                              <CommonButton type="submit" variant="contained" sx={SignInStyles.elementsMarginTop}>
                                LogIn
                              </CommonButton>
                              <CommonButton
                                onClick={handleForgetPassword}
                                variant="outlined"
                                sx={SignInStyles.elementsMarginTop}>
                                Forgot Password ?
                              </CommonButton>
                            </Box>
                          </form>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <ForgetPasswordDialog isOpen={isDialogOpen} onClose={setIsDialogOpen}></ForgetPasswordDialog>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default SignIn;
