import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Box from '@mui/material/Box';
import { FC, type JSX } from 'react';
import { IBasicPreviewPropsModel } from '../../../../../editor/shared/components/forms/creatives/models/basic-preview-props.model';

const BasicSnapChatPreview: FC<IBasicPreviewPropsModel> = ({ currentPreview, error, errorMessage }): JSX.Element => {
  return (
    <div>
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Paper sx={{ p: 1, mb: 2, border: error ? '1px solid red' : '' }}>
            {error && errorMessage && (
              <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography variant="h6" color="error" sx={{ p: 2, pl: 3 }}>
                  {errorMessage}
                </Typography>
              </Box>
            )}

            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sx={{ width: { xs: '100%' } }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backgroundColor: 'black',
                    borderRadius: '15px 15px 0 0',
                    alignItems: 'center',
                    height: '50px',
                  }}>
                  <Box sx={{ mr: '20px' }}>
                    <SignalCellularAltIcon sx={{ color: '#fff' }} />
                  </Box>
                  <Box sx={{ mr: '20px' }}>
                    <SignalWifi4BarIcon sx={{ color: '#fff' }} />
                  </Box>
                  <Box sx={{ mr: '20px' }}>
                    <BatteryFullIcon sx={{ color: '#fff' }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} minHeight={350} maxHeight={450} sx={{ width: { xs: '100%' }, overflow: 'hidden' }}>
                {currentPreview}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: { xs: '100%' },
                  mt: '-30px',
                  zIndex: 2,
                  backgroundColor: 'black',
                  borderRadius: '15px 15px 0 0',
                }}>
                <Grid container sx={{ mt: { xs: '-15px', sm: '0' } }}>
                  <Grid item xs={12} sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: '20%',
                        minWidth: '50px',
                        height: '8px',
                        borderRadius: '15px',
                        backgroundColor: 'grey',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ p: 1, display: 'flex', justifyContent: 'flex-between' }} gap={1}>
                    <Box display="flex" width="20%">
                      <Box
                        sx={{
                          width: '100%',
                          minWidth: '20px',
                          height: '40px',
                          borderRadius: '15px',
                          backgroundColor: '#404040',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <CameraAltIcon sx={{ color: '#fff', fontSize: '32px' }} />
                      </Box>
                    </Box>
                    <Box display="flex" width="60%">
                      <Box
                        sx={{
                          cursor: 'pointer',
                          width: '100%',
                          minWidth: '20px',
                          height: '40px',
                          borderRadius: '15px',
                          backgroundColor: '#404040',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography variant="h5" color="#fff">
                          VIEW
                        </Typography>
                        <ExitToAppIcon sx={{ color: '#fff', fontSize: '16px', ml: 0.5 }} />
                      </Box>
                    </Box>
                    <Box display="flex" width="20%">
                      <Box
                        sx={{
                          width: '100%',
                          minWidth: '20px',
                          height: '40px',
                          borderRadius: '15px',
                          backgroundColor: '#404040',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <PlayArrowIcon sx={{ color: '#fff', fontSize: '40px' }} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ mt: 3, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: '60%',
                      minWidth: '50px',
                      height: '8px',
                      borderRadius: '15px',
                      backgroundColor: '#fff',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicSnapChatPreview;
