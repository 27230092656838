import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import 'cropperjs/dist/cropper.css';
import { uniqueId } from 'lodash';
import { FC, useRef, type JSX } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { toast } from 'react-hot-toast';
import CommonButton from '../../../../shared/components/CommonButton';
import Loader from '../../../../shared/components/Loader';
import { useAppDispatch } from '@/store/hooks/redux';
import { imageCroppedChannel } from '../../../components/media-library/images/event-bus/image-cropped-channel';
import { setImageCropper } from '../../../store/reducers/modals-drawers.slice';
import useImageCropper from './hooks/useCropper';
import CloseIcon from '@mui/icons-material/Close';
import CropIcon from '@mui/icons-material/Crop';

// TODO REFACTOR !!!

const ImageCropper: FC = (): JSX.Element => {
  const { cropperLoading, imageBlob, modalState, croppingInProgress, setCroppingInProgress } = useImageCropper();

  const dispatch = useAppDispatch();
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleClose = () => {
    dispatch(
      setImageCropper({
        isOpened: false,
        image: null,
        platform: null,
        propertyInternalId: null,
        widthToCrop: 0,
        heightToCrop: 0,
      })
    );
  };

  const blobToUrl = async (blob: Blob) =>
    new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });

  const imgResizing = async (blob: Blob) => {
    const img = new Image();
    img.src = await blobToUrl(blob);

    return new Promise<Blob>((resolve, _) => {
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        canvas.width = modalState.widthToCrop;
        canvas.height = modalState.heightToCrop;

        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          }
        });
      };
    });
  };

  const handleCrop = async () => {
    const cropper = cropperRef?.current?.cropper;
    setCroppingInProgress(true);
    cropper?.getCroppedCanvas().toBlob(async function (blob: Blob | null) {
      if (blob && modalState.propertyInternalId) {
        const res = await toast.promise(imgResizing(blob), {
          loading: 'Processing...',
          success: <b>Successfully cropped</b>,
          error: <b>Something happened</b>,
        });

        const file = new File([res], uniqueId() + '.jpg');
        imageCroppedChannel.emit('onImageCropped', { image: file });

        setTimeout(() => {
          setCroppingInProgress(false);
          handleClose();
        }, 1000);
      }
    }, 'image/jpg');
  };

  return (
    <Dialog maxWidth={'lg'} open={modalState.isOpened} onClose={handleClose}>
      <DialogTitle align={'right'} sx={{ p: 0 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {cropperLoading || croppingInProgress ? (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ width: '300px', height: '300px' }}>
          <Box>
            <Loader absolute={0} />
            <Typography pt={2} variant={'h5'}>
              Laster inn bildet...
            </Typography>
          </Box>
        </Box>
      ) : (
        <div>
          <DialogContent>
            <Cropper
              dragMode="none"
              draggable={false}
              src={imageBlob!}
              style={{
                maxHeight: '590px',
                minHeight: '400px',
                width: modalState!.image!.width * (590 / modalState!.image!.height) + 'px',
              }}
              aspectRatio={9 / 16}
              responsive={true}
              minCropBoxWidth={1080}
              minCropBoxHeight={1920}
              guides={false}
              scalable={false}
              zoomOnWheel={false}
              zoomOnTouch={false}
              zoomable={true}
              ref={cropperRef}
            />
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <CommonButton
              startIcon={<CropIcon />}
              color="secondary"
              disabled={croppingInProgress}
              onClick={() => handleCrop()}
              autoFocus>
              Beskjær
            </CommonButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default ImageCropper;
