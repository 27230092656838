import { IAgentDetailsProps } from '@/shared/models/orders/single-order-with-details.model';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC, JSX } from 'react';

const AgentDetails: FC<IAgentDetailsProps> = (props: IAgentDetailsProps): JSX.Element => {
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Box
        component="div"
        sx={{
          height: '160px',
          width: '160px',
          backgroundImage: `url(${props.agentImage})`,
          backgroundSize: 'cover',
          borderRadius: '50%',
        }}
      />
      <Box ml="20px" color="#fff">
        <Typography fontWeight={200} variant={'h4'} component={'h2'}>
          {props.agentTitle}
        </Typography>
        <Typography fontWeight={300} variant={'h4'} component={'h2'}>
          {props.agentName + ' ' + props.agentLastName}
        </Typography>
        <Typography fontWeight={300} variant={'h4'} component={'h2'}>
          {props.agentOfficeName}
        </Typography>
        <Typography fontWeight={300} variant={'h4'} component={'h2'}>
          {props.agentEmail}
        </Typography>
        <Typography fontWeight={300} variant={'h4'} component={'h2'}>
          {props.agentPhone}
        </Typography>
      </Box>
    </Box>
  );
};

export default AgentDetails;
