import { FC, type JSX } from 'react';
import { Bar } from 'react-chartjs-2';
import { IBaseChartsProps } from './chart-props.moldel';

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
  elements: {
    bar: {
      borderColor: '#000',
    },
  },
};

const BarChart: FC<IBaseChartsProps> = ({ labels, dataSets, chartType }): JSX.Element => {
  const barThickness = chartType === 'single' ? { barThickness: '80' } : { barThickness: 'flex' };

  return (
    <Bar
      options={{ ...options, ...barThickness }}
      data={{
        labels: labels,
        datasets: dataSets.map((el) => ({
          label: el.label,
          data: el.data,
          borderColor: el.borderColor ?? '#000',
          backgroundColor: el.backgroundColor ?? '#162831',
        })),
      }}
    />
  );
};

export default BarChart;
