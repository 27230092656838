import { FC, type JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ErrorPage: FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      component="div"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px',
        textAlign: 'center',
        background: 'transparent',
      }}>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Oops...
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Error occurred...
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        We know about the error will fix it soon
      </Typography>
      <Button variant="contained" sx={{ width: '200px', height: '40px' }} onClick={() => navigate(-1)}>
        Return Back
      </Button>
    </Box>
  );
};

export default ErrorPage;
