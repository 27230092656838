import React, { FC, useEffect, useState, type JSX } from 'react';
import OverviewOrdersTableProvider from '@/shared/components/order-overviews-tables/OverviewOrdersTable';
import { useGetSelfUserPaginatedOrdersQuery } from '@/editor/services/http/orders.api';
import BaseOrderOverviewActionButtons from '@/shared/components/order-overviews-tables/BaseOrderOverviewActionButtons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserOrderOverviewTable: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const { data, isError, isFetching, isLoading, refetch, isUninitialized } = useGetSelfUserPaginatedOrdersQuery({
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
    search,
  });

  useEffect(() => {
    const impersonation = searchParams.get('impersonation');
    if (impersonation && impersonation === 'True' && !isUninitialized) {
      refetch();
      navigate('/editor');
    }
  }, []);

  return (
    <OverviewOrdersTableProvider
      data={data ?? null}
      isError={isError}
      refetchFn={refetch}
      isLoading={isLoading}
      isFetching={isFetching}
      tableDescription={t('yourOrders')}
      pagination={pagination}
      setPagination={setPagination}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      sorting={sorting}
      setSorting={setSorting}
      search={search}
      setSearch={setSearch}
      actionButtonsRenderFn={BaseOrderOverviewActionButtons}
    />
  );
};

export default UserOrderOverviewTable;
