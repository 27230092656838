import { FC, useCallback, type JSX } from 'react';
import NotFound from '../../../../shared/components/NotFound';
import AdvertisementStep from './advertisement-step/AdvertisementStep';
import { IBaseStepperComponentPropertiesModel } from './component-properties.model';
import CreativesStep from './creatives-step/CreativesStep';
import RegisterPropertyStep from './register-property-step/RegisterPropertyStep';
import AdSettingsStep from './settings-step/AdSettingsStep';

export interface IStepResolverProps extends IBaseStepperComponentPropertiesModel {
  activeStep: number;
}

const StepResolver: FC<IStepResolverProps> = ({
  activeStep,
  handleBackBtnClicked,
  handleChangeActiveStep,
}): JSX.Element => {
  const resolveSteps = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <RegisterPropertyStep
            handleChangeActiveStep={handleChangeActiveStep}
            handleBackBtnClicked={handleBackBtnClicked}
          />
        );
      case 1:
        return (
          <AdSettingsStep handleChangeActiveStep={handleChangeActiveStep} handleBackBtnClicked={handleBackBtnClicked} />
        );
      case 2:
        return (
          <CreativesStep handleChangeActiveStep={handleChangeActiveStep} handleBackBtnClicked={handleBackBtnClicked} />
        );
      case 3:
        return (
          <AdvertisementStep
            handleChangeActiveStep={handleChangeActiveStep}
            handleBackBtnClicked={handleBackBtnClicked}
          />
        );
      default:
        return <NotFound />;
    }
  }, [activeStep, handleChangeActiveStep]);

  return <div>{resolveSteps()}</div>;
};

export default StepResolver;
