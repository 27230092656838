import { FC, type JSX } from 'react';
import Button from '@mui/material/Button';

// TODO fix 'any' type
const CommonButton: FC<any> = (props): JSX.Element => {
  return (
    <Button
      {...props}
      color={props.color ? props.color : 'secondary'}
      variant={props.variant ? props.variant : 'contained'}
    />
  );
};

export default CommonButton;
