import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from '../models/carousel-creative-form-values';
import { FormikContextType } from 'formik';

const useHandleCarouselChange = (setKeyValuePairToLS: Function, updateCarouselItemField: Function) => {

  const handleChange = (
    formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>,
    carouselItemIndex: number,
    key: string,
    platform: PlatformType,
    value: string
  ) => {
    formikCtx.setFieldValue(`creativeItems[${carouselItemIndex}].${key}`, value);
    const updatedValues = formikCtx.values.creativeItems.map(
      (item: ICarouselCreativeItemFormValuesModel, index: number) =>
        index === carouselItemIndex ? { ...item, [key]: value } : item
    );
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA, JSON.stringify(updatedValues));
    updateCarouselItemField(platform, carouselItemIndex, key, value);
  };

  return handleChange;
};

export default useHandleCarouselChange;