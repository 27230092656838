import { FC, useEffect, useState, type JSX } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import QRCode from 'react-qr-code';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const WebmeglerBoardsSinglePreview: FC<IExtendedPreviewPropsModel> = ({
  employeeAvatar,
  employeeName,
  link,
  doohBrokerText,
}): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer?.creativeFormsState?.boards?.single?.media);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (employeeAvatar instanceof File) {
      const url = URL.createObjectURL(employeeAvatar);
      setAvatarUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setAvatarUrl(employeeAvatar);
    }
  }, [employeeAvatar]);

  return (
    <Grid
      container
      sx={{ overflow: 'hidden', display: 'block', width: { xs: '100%', sm: '450px' }, boxShadow: 'none' }}>
      <Grid item xs={12} sx={{ backgroundColor: '#0a1119', position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ maxWidth: '300px', height: '300px', backgroundSize: 'cover' }}
            src={avatarUrl || undefined}
            alt="employee avatar"
          />
        </Box>
        <Box sx={{ position: 'absolute', top: '255px', left: '50%', transform: 'translateX(-50%)', zIndex: 5 }}>
          <img style={{ width: '90px', height: '90px' }} src="/images/boards/boards_eie_logo.png" alt="logo" />
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: 'white',
            fontFamily: 'Maison Neue',
            position: 'absolute',
            top: '340px',
            transform: 'translateX(-50%)',
            left: '50%',
            zIndex: 5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          EIE eiendomsmegling
        </Typography>
      </Grid>
      {media?.preSignedUrl ? (
        <Grid item xs={12} sx={{ position: 'relative', height: '400px', overflow: 'hidden' }}>
          <Grid
            className="boards-preview-animation"
            sx={{
              backgroundImage: `url(${media?.preSignedUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}></Grid>
        </Grid>
      ) : (
        <EmptyImageCard />
      )}
      <Box
        sx={{
          backgroundColor: 'black',
          color: 'white',
          padding: '20px',
          position: 'relative',
        }}>
        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'Saol Display',
              position: 'absolute',
              animation: 'fadeInOut 10s linear infinite',
              fontSize: { xs: '24px', sm: '32px' },
            }}>
            SOLGT
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'Saol Display',
              position: 'absolute',
              animation: 'fadeIn 10s linear infinite',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: { xs: '20px', sm: '32px' },
            }}>
            {employeeName}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography variant="h4" sx={{ whiteSpace: 'pre-wrap' }}>
            {doohBrokerText}
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ textAlign: 'center', marginTop: '20px' }}>
          EIE eiendomsmegling
        </Typography>
        {link && (
          <Box
            sx={{
              position: { xs: 'static', sm: 'absolute' },
              bottom: '15px',
              right: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: { xs: '10px', sm: '0' },
            }}>
            <QRCode value={link} size={50} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default WebmeglerBoardsSinglePreview;
