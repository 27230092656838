import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import BasicCreativeFields from './BasicCreativeFields';
import { ChangeEvent, FC, useEffect, useRef, useState, type JSX } from 'react';
import { AvailablePlatformsEnum, PlatformType } from '../../../constants/available-platforms';
import { useFormikContext } from 'formik';
import { ICarouselCreativeItemListProps } from './CarouselCreativeItemsList';
import { IBasicCreativeFormValues } from './models/basic-creative-form-values.model';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import { setMediaLibraryDrawerState } from '@/editor/store/reducers/modals-drawers.slice';
import PhotoLibrary from '@mui/icons-material/PhotoLibrary';
import { IUserModel } from '@/shared/models/user.model';
import { setFieldOfCreativeFormForPlatform } from '@/editor/store/reducers/creative-step.slice';
import ExternalCompanyIdEnum from '@/shared/constants/external-company-id.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setKeyValuePairToLS } from '@/shared/services/localstorage';
import useRestoreState from '@/editor/pages/create-ads-steps-page/components/creatives-step/hooks/useRestoreState';

interface IBaseCreativeFormProps {
  additionalFields?: JSX.Element;
  userData?: IUserModel;
  platform: PlatformType;
  areFieldsRequired?: boolean;
  carouselItems?: FC<ICarouselCreativeItemListProps>;
  initialValues: IBasicCreativeFormValues;
  concretePreviews: FC<any>[];
  basePreviews: FC<IExtendedPreviewPropsModel>[];
  externalCompanyId?: any; // TODO REFACTOR
}

// TODO REFACTOR

const BasicCreativeForm: FC<IBaseCreativeFormProps> = ({
  additionalFields,
  platform,
  userData,
  areFieldsRequired,
  carouselItems: AdditionalItemsArea,
  initialValues,
  concretePreviews,
  basePreviews,
  externalCompanyId,
}) => {
  const [selectedImage, setImage] = useState<string>('');
  const formikCtx = useFormikContext();
  const { t } = useTranslation();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const isFormInitialized = useAppSelector((state) => state.creativeStepSliceReducer.isInitialized);
  const dispatch = useAppDispatch();
  const medias = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState.meta.dynamic.creativeMedias
  );
  const creativeFormValues = useRestoreState(platform, isFormInitialized);

  useEffect(() => {
    if (creativeFormValues !== null && !isFormInitialized) {
      formikCtx.setValues(creativeFormValues);
    }
  }, [isFormInitialized]);

  useEffect(() => {
    if (medias.length > 0) {
      setImage(medias[0].preSignedUrl);
    }
  }, [medias]);

  useEffect(() => {
    if (formikCtx.isValid && isFormInitialized) {
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATIVES_FORMS[platform], JSON.stringify(formikCtx.values));
    }
  }, [formikCtx.values, isFormInitialized]);

  const toggleMediaLibrary = () => {
    dispatch(
      setMediaLibraryDrawerState({
        isOpened: true,
        creativeType: selectedCreativeType,
        platform: platform,
      })
    );
  };

  const formik = useFormikContext();
  const handleBidFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: any = e.target.files[0];
      formik.setFieldValue('employeeAvatar', file);
      dispatch(
        setFieldOfCreativeFormForPlatform({
          platform: AvailablePlatformsEnum.BIDTHEATRE,
          creativeType: CreativeTypesEnum.SINGLE,
          field: 'employeeAvatar',
          value: file,
        })
      );
    }
  };
  const handleBoardFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: any = e.target.files[0];
      formik.setFieldValue('employeeAvatar', file);
      dispatch(
        setFieldOfCreativeFormForPlatform({
          platform: AvailablePlatformsEnum.BOARDS,
          creativeType: CreativeTypesEnum.SINGLE,
          field: 'employeeAvatar',
          value: file,
        })
      );
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Grid container>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Grid container spacing={3} item>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <form noValidate onSubmit={formikCtx.handleSubmit}>
                  <BasicCreativeFields areFieldsRequired={true} platform={platform}></BasicCreativeFields>
                  {additionalFields}
                </form>
              </Grid>
              <Grid item xs={12}>
                {AdditionalItemsArea && <AdditionalItemsArea platform={platform} initialValues={initialValues!} />}
              </Grid>
              <Grid item xs={12} mb={2} sx={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                {selectedCreativeType !== CreativeTypesEnum.CAROUSEL && (
                  <ButtonGroup variant="contained" color="secondary" size="small">
                    <Button startIcon={<PhotoLibrary />} disableElevation onClick={toggleMediaLibrary}>
                      {t('mediaLibrary')}
                    </Button>
                  </ButtonGroup>
                )}
                {(externalCompanyId == ExternalCompanyIdEnum.MSPECS ||
                  externalCompanyId == ExternalCompanyIdEnum.FREMEIENDOMSMEGLING ||
                  externalCompanyId == ExternalCompanyIdEnum.SMG ||
                  externalCompanyId == ExternalCompanyIdEnum.STRAND_MAKLERI ||
                  externalCompanyId == ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI ||
                  externalCompanyId == ExternalCompanyIdEnum.HOME_BY_DEAN) &&
                  selectedCreativeType != CreativeTypesEnum.CAROUSEL &&
                  platform === AvailablePlatformsEnum.BIDTHEATRE && (
                    <ButtonGroup variant="contained" color="secondary" size="small">
                      <Button startIcon={<PhotoLibrary />} disableElevation onClick={handleButtonClick}>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleBidFileChange}
                        />
                        {t('brokerImage')}
                      </Button>
                    </ButtonGroup>
                  )}

                {selectedCreativeType != CreativeTypesEnum.CAROUSEL && platform === AvailablePlatformsEnum.BOARDS && (
                  <ButtonGroup variant="contained" color="secondary" size="small">
                    <Button startIcon={<PhotoLibrary />} disableElevation onClick={handleButtonClick}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleBoardFileChange}
                      />
                      {t('brokerImage')}
                    </Button>
                  </ButtonGroup>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {selectedCreativeType === CreativeTypesEnum.DYNAMIC && platform === AvailablePlatformsEnum.META && (
                <div>
                  <Grid item container>
                    <Grid item xs={12}>
                      <ImageList cols={5} rowHeight={90}>
                        {medias?.map((el, index) => (
                          <ImageListItem
                            sx={{
                              border: selectedImage === el.preSignedUrl ? '2px solid #000' : '2px solid transparent',
                            }}
                            key={index}
                            onClick={() => setImage(el.preSignedUrl)}>
                            <img srcSet={`${el.preSignedUrl}`} src={`${el.preSignedUrl}`} alt={el.id} loading="lazy" />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  </Grid>
                </div>
              )}
              {basePreviews?.map((Preview, index) => {
                const ConcretePreview = concretePreviews[index];
                return (
                  <Grid item xs={12} key={index}>
                    <Preview
                      error={Boolean(formikCtx.getFieldMeta('media').touched && formikCtx.getFieldMeta('media').error)}
                      errorMessage={formikCtx.getFieldMeta('media').error}
                      headline={formikCtx.getFieldMeta('headline').value as string | null}
                      description={formikCtx.getFieldMeta('description').value as string | null}
                      propertyPriceQuote={formikCtx.getFieldMeta('priceQuote').value as number | null}
                      caption={formikCtx.getFieldMeta('caption').value as string | null}
                      propertyType={(formikCtx.getFieldMeta('propertyEstateType').value as string) || null}
                      areaInUsage={(formikCtx.getFieldMeta('areaInUsage').value as string | number) || null}
                      propertyDebt={(formikCtx.getFieldMeta('stateDebt').value as number | string) || null}
                      companyExternalId={userData?.companyExternalId as string}
                      ownershipType={formikCtx.getFieldMeta('ownershipType').value as string | null}
                      viewDate={formikCtx.getFieldMeta('viewDate').value as any}
                      nativeAd={formikCtx.getFieldMeta('nativeAd').value as any}
                      employeeName={formikCtx.getFieldMeta('employeeName').value as string}
                      employeeEmail={formikCtx.getFieldMeta('employeeEmail').value as string}
                      employeePhone={formikCtx.getFieldMeta('employeePhone').value as string}
                      employeeAvatar={formikCtx.getFieldMeta('employeeAvatar').value as string}
                      link={formikCtx.getFieldMeta('link').value as string | null}
                      doohBrokerText={formikCtx.getFieldMeta('doohBrokerText').value as string}
                      showFooter={true}
                      currentPreview={
                        <ConcretePreview
                          selectedImage={selectedImage}
                          items={formikCtx.getFieldMeta('creativeItems').value}
                        />
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BasicCreativeForm;
