import { FC, type JSX } from 'react';
import TextField from '@mui/material/TextField';

// TODO Fix any type for textfield
const CommonTextField: FC<any> = (props): JSX.Element => {
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth ?? true}
      color={props.color ? props.color : 'secondary'}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

export default CommonTextField;
