// @ts-nocheck

import { generateShadowInstApi } from '@/admin/services/http/generate-shadow-inst.api';
import { combineReducers, configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { applicationLogsApi } from '@/admin/services/http/application-logs.api';
import { companiesAutoCreationConfigsApi } from '@/admin/services/http/companies-auto-creation-configs.api';
import { companiesPlatformConfigsApi } from '@/admin/services/http/companies-platform-configs.api';
import { companiesApi } from '@/admin/services/http/companies.api';
import { companiesBrandingsApi } from '@/admin/services/http/company-branding.api';
import { officesApi } from '@/admin/services/http/offices.api';
import { retailDataProvidersApi } from '@/admin/services/http/retail-data-provider.api';
import { rolesApi } from '@/admin/services/http/roles.api';
import { usersApi } from '@/admin/services/http/users.api';
import authReducer from '../auth/store/reducers/auth.slice';
import { customerAdSetsApi } from '@/editor/services/http/ad-sets.api';
import { customerAdvertisementsApi } from '@/editor/services/http/advertisements.api';
import { customerBudgetsApi } from '@/editor/services/http/budgets.api';
import { customerCampaignsApi } from '@/editor/services/http/campaigns.api';
import { customerCreativesApi } from '@/editor/services/http/creatives.api';
import { mediaLibraryApi } from '@/editor/services/http/media-library.api';
import { customerMoovlyApi } from '@/editor/services/http/moovly.api';
import { customersOrdersApi } from '@/editor/services/http/orders.api';
import { customerPropertiesApi } from '@/editor/services/http/properties.api';
import { userRetailDataProviderApi } from '@/editor/services/http/retail-data-provider.api';
import { customerUsersApi } from '@/editor/services/http/users.api';
import creativeStepSliceReducer from '../editor/store/reducers/creative-step.slice';
import editorModalsDrawersReducer from '../editor/store/reducers/modals-drawers.slice';
import propertyInfoSliceReducer from '../editor/store/reducers/property-info.slice';
import editorActivePlatformsReducer from '../editor/store/reducers/selected-platforms.slice';
import adSetStepSliceReducer from '../editor/store/reducers/adSet-step.slice';
import { api } from '@/shared/services/api/api';
import { userProfileApi } from '@/shared/services/api/user-profiles.api';
import { rtkQueryErrorLogger } from '@/shared/services/middleware/RTQ.error-logger.middleware';
import { advertisementsAdminApi } from '@/admin/services/http/advertisements.api';

const rootReducer = combineReducers({
  authReducer,
  editorActivePlatformsReducer,
  adSetStepSliceReducer,
  editorModalsDrawersReducer,
  creativeStepSliceReducer,
  propertyInfoSliceReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [userProfileApi.reducerPath]: userProfileApi.reducer,
  [retailDataProvidersApi.reducerPath]: retailDataProvidersApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [officesApi.reducerPath]: officesApi.reducer,
  [userRetailDataProviderApi.reducerPath]: userRetailDataProviderApi.reducer,
  [customerPropertiesApi.reducerPath]: customerPropertiesApi.reducer,
  [mediaLibraryApi.reducerPath]: mediaLibraryApi.reducer,
  [customerCampaignsApi.reducerPath]: customerCampaignsApi.reducer,
  [customerAdSetsApi.reducerPath]: customerAdSetsApi.reducer,
  [customerCreativesApi.reducerPath]: customerCreativesApi.reducer,
  [customerBudgetsApi.reducerPath]: customerBudgetsApi.reducer,
  [customersOrdersApi.reducerPath]: customersOrdersApi.reducer,
  [customerAdvertisementsApi.reducerPath]: customerAdvertisementsApi.reducer,
  [companiesPlatformConfigsApi.reducerPath]: companiesPlatformConfigsApi.reducer,
  [companiesAutoCreationConfigsApi.reducerPath]: companiesAutoCreationConfigsApi.reducer,
  [companiesBrandingsApi.reducerPath]: companiesBrandingsApi.reducer,
  [customerUsersApi.reducerPath]: customerUsersApi.reducer,
  [applicationLogsApi.reducerPath]: applicationLogsApi.reducer,
  [customerMoovlyApi.reducerPath]: customerMoovlyApi.reducer,
  [generateShadowInstApi.reducerPath]: generateShadowInstApi.reducer,
  [advertisementsAdminApi.reducerPath]: advertisementsAdminApi.reducer,
});

export const createStore = (options?: ConfigureStoreOptions['preloadedState']) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorLogger),
    ...options,
  });

export const store = createStore();

export type RootReducer = ReturnType<typeof rootReducer>;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = typeof store.dispatch;
