import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import {
  ICreativeFormsStatePerPlatform,
  setCreativeFormValueForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';

interface IAftermarketingFormValues extends IBasicCreativeFormValues {
  message: string;
}

const useSetCreativeFormValues = (platform: PlatformType, creativeType: keyof ICreativeFormsStatePerPlatform) => {
  const dispatch = useAppDispatch();
  const platformFormValues = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState[platform][creativeType]
  );

  const setFormValues = (formValues: Partial<IAftermarketingFormValues>) => {
    if (formValues) {
      // since 'headline field in aftermarketing is called 'message'
      // the object has to be remapped
      const { message, ...rest } = formValues;
      const newFormValues = { ...rest, headline: message };

      const { caption, description, headline, link, ...other } = platformFormValues as IBasicCreativeFormValues;

      const mergedFormValues = {
        ...newFormValues,
        ...other,
      };

      dispatch(setCreativeFormValueForPlatform({ creativeType, platform, values: mergedFormValues }));
    }
  };

  return { setFormValues };
};

export default useSetCreativeFormValues;
