import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/nb';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { ProSidebarProvider } from 'react-pro-sidebar';
import './App.css';
import { IAuthResponse } from './auth/models/auth.response';
import { setCredentials } from './auth/store/reducers/auth.slice';
import Router from './Router';
import { getCredentialsFromLS } from './shared/services/localstorage';
import { themeOptions } from './shared/theme/theme.options';
import { useAppDispatch } from './store/hooks/redux';
import ErrorBoundary from './shared/components/ErrorBoundary';

// Translations
import './i18n';
import { nbNO, svSE } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import useAppInitLanguage from './shared/hooks/app-init-hook';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme(themeOptions, nbNO);
function App() {
  const dispatch = useAppDispatch();
  // const [theme, colorMode] = useMode();
  const credentials: IAuthResponse | null = getCredentialsFromLS();

  const { i18n } = useTranslation();

  useAppInitLanguage(true);

  useEffect(() => {
    if (credentials) {
      dispatch(setCredentials(credentials));
    }

    return () => {};
  }, [dispatch]);

  return (
    <div>
      {/* <ColorModeContext.Provider value={colorMode}> */}
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nb">
            <ProSidebarProvider>
              <Toaster position="top-center" reverseOrder={true}></Toaster>
              <Router></Router>
            </ProSidebarProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </ThemeProvider>
      {/* </ColorModeContext.Provider> */}
    </div>
  );
}

export default App;
