import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Mail from '@mui/icons-material/Mail';
import Save from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import { FC } from 'react';

interface INewspaperCard {
  platform: string;
  clicks: number;
  views: number;
  mails: number;
}

const FinnSection: FC<INewspaperCard> = ({ platform, clicks, views, mails }) => {
  return (
    <Card sx={{ width: '100%' }}>
      <Grid container p={2}>
        <Divider sx={{ width: '100%' }} />
        <Grid item p={2} xs={5} md={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography fontWeight={300} display={'flex'} alignItems={'center'} variant="h3">
            {platform}
          </Typography>
        </Grid>
        <Grid item p={2} xs={7} md={7} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography fontWeight={300} variant="h4" display={'flex'} alignItems={'center'}>
            <Visibility sx={{ mr: 0.5 }} /> {views}
          </Typography>
          <Typography fontWeight={300} variant="h4" display={'flex'} alignItems={'center'}>
            <Save sx={{ mr: 0.5 }} /> {clicks}
          </Typography>
          <Typography fontWeight={300} variant="h4" display={'flex'} alignItems={'center'}>
            <Mail sx={{ mr: 0.5 }} /> {mails}
          </Typography>
        </Grid>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    </Card>
  );
};

export default FinnSection;
