import Box from '@mui/material/Box';
import { UserCreateEditStyles } from '@/admin/pages/users/create-edit/styles';
import Button from '@mui/material/Button';
import { ChangeEvent, CSSProperties, FC, SyntheticEvent, useEffect, useRef, useState, type JSX } from 'react';
import AddPhotoAlternateRounded from '@mui/icons-material/AddPhotoAlternateRounded';

type AvatarBoxAction = (file: File) => void;

type AvatarBoxProps = {
  preSignedAvatar: string | null;
  action: AvatarBoxAction;
};

const imgStyle: CSSProperties | undefined = {
  width: '100%',
  height: '100%',
  maxHeight: '280px',
  objectFit: 'cover',
};

const AvatarBox: FC<AvatarBoxProps> = ({ action, preSignedAvatar }): JSX.Element => {
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const imgRef = useRef<HTMLInputElement>(null);

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setUploadedImage(URL.createObjectURL(img));
      action(img);
    }
  };

  useEffect(() => {
    if (preSignedAvatar) {
      setUploadedImage(preSignedAvatar);
    }
  }, [preSignedAvatar]);

  const onImagePlaceHolderClick = () => {
    imgRef?.current?.click();
  };

  return (
    <Box component="div" sx={UserCreateEditStyles.avatarBoxWrapper}>
      <Box component="div" sx={UserCreateEditStyles.avatarBoxSubWrapper} onClick={() => onImagePlaceHolderClick()}>
        {uploadedImage ? (
          <img
            alt="avatar"
            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
              return (e.currentTarget.src = '/avatar.png');
            }}
            style={imgStyle}
            src={uploadedImage}></img>
        ) : (
          <img
            alt="avatar"
            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
              return (e.currentTarget.src = '/avatar.png');
            }}
            style={imgStyle}
            src="/avatar.png"
          />
        )}

        <Button sx={UserCreateEditStyles.avatarBoxIcon}>
          <AddPhotoAlternateRounded sx={{ fontSize: '40px' }} />
        </Button>

        <input
          hidden
          ref={imgRef}
          accept="image/jpeg, image/jpg, image/png"
          type="file"
          name="myImage"
          onChange={onImageChange}
        />
      </Box>
    </Box>
  );
};

export default AvatarBox;
