import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { useTranslation } from 'react-i18next';
export interface IBoardsSummaryProps {
  currentCreativeForm: IBasicCreativeFormValues;
}
const BoardsSummary: FC<IBoardsSummaryProps> = ({ currentCreativeForm }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('caption')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.caption}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('Broker Email')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.employeeEmail}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('Broker Number')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.employeePhone}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('Broker Name')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.employeeName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('link')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.link}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('Broker Text')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.doohBrokerText}</Typography>
      </Grid>
    </div>
  );
};
export default BoardsSummary;
