import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { FC, useState, useEffect, type JSX } from 'react';
import SormeglerenBidtheatreSinglePreview from './SormeglerenBidtheatreSinglePreview';
import WebmeglerBidtheatreSinglePreview from './WebmeglerBidtheatreSinglePreview';
import ExternalCompanyIdEnum from '@/shared/constants/external-company-id.enum';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import NativeAdPreview from './NativeAdPreview';
import { useTranslation } from 'react-i18next';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { useAppSelector } from '@/store/hooks/redux';
import FremeiendomsmeglingBidtheatreSinglePreview from './FremeiendomsmeglingBidtheatreSinglePreview';
import BidtheatreClientPreview from './BidtheatreSingleClientPreview';

// TODO REFACTOR !!!

const BidTheatrePreviewResolver: FC<IExtendedPreviewPropsModel> = ({
  headline,
  description,
  caption,
  propertyPriceQuote,
  error,
  errorMessage,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
  companyExternalId,
  viewDate,
  nativeAd,
  employeeName,
  employeeAvatar,
  employeePhone,
  employeeEmail,
}): JSX.Element => {
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const { currentData } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId ?? '' },
    { skip: !propertyExtId }
  );

  const switchPreview = (companyExternalId: string) => {
    switch (companyExternalId) {
      case ExternalCompanyIdEnum.EIE:
      case ExternalCompanyIdEnum.EIE_VISMA:
        if (preview === 'html5') {
          return (
            <WebmeglerBidtheatreSinglePreview
              headline={headline}
              description={description}
              propertyPriceQuote={propertyPriceQuote}
              caption={caption}
              propertyType={propertyType}
              areaInUsage={areaInUsage}
              propertyDebt={propertyDebt}
              ownershipType={ownershipType}
              viewDate={viewDate}
              nativeAd={nativeAd}
            />
          );
        } else if (preview === 'nativead') {
          return (
            <NativeAdPreview
              headline={headline}
              caption={caption}
              viewDate={viewDate}
              nativeAd={nativeAd}
              companyExternalId={companyExternalId}
            />
          );
        }
        break;
      case ExternalCompanyIdEnum.SORMEGLEREN:
        if (preview === 'html5') {
          return (
            <SormeglerenBidtheatreSinglePreview
              headline={headline}
              description={description}
              propertyPriceQuote={propertyPriceQuote}
              caption={caption}
              propertyType={propertyType}
              areaInUsage={areaInUsage}
              propertyDebt={propertyDebt}
              ownershipType={ownershipType}
              viewDate={viewDate}
              nativeAd={nativeAd}
            />
          );
        } else if (preview === 'nativead') {
          return (
            <NativeAdPreview
              headline={headline}
              caption={caption}
              viewDate={viewDate}
              nativeAd={nativeAd}
              companyExternalId={companyExternalId}
            />
          );
        }
        break;
      case ExternalCompanyIdEnum.SMG:
      case ExternalCompanyIdEnum.MSPECS:
      case ExternalCompanyIdEnum.STRAND_MAKLERI:
      case ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI:
      case ExternalCompanyIdEnum.HOME_BY_DEAN:
        if (preview == 'html5') {
          return (
            <BidtheatreClientPreview
              headline={headline}
              description={description}
              propertyPriceQuote={propertyPriceQuote}
              caption={caption}
              propertyType={propertyType}
              areaInUsage={areaInUsage}
              propertyDebt={propertyDebt}
              ownershipType={ownershipType}
              viewDate={viewDate}
              nativeAd={nativeAd}
              employeeName={employeeName}
              employeeAvatar={employeeAvatar}
              employeePhone={employeePhone}
              employeeEmail={employeeEmail}
              companyExternalId={companyExternalId}
            />
          );
        } else if (preview === 'nativead') {
          return (
            <NativeAdPreview
              headline={headline}
              caption={caption}
              viewDate={viewDate}
              nativeAd={nativeAd}
              companyExternalId={companyExternalId}
            />
          );
        }
        break;
      case ExternalCompanyIdEnum.FREMEIENDOMSMEGLING:
        if (preview == 'html5') {
          return (
            <FremeiendomsmeglingBidtheatreSinglePreview
              headline={headline}
              description={description}
              propertyPriceQuote={propertyPriceQuote}
              caption={caption}
              propertyType={propertyType}
              areaInUsage={areaInUsage}
              propertyDebt={propertyDebt}
              ownershipType={ownershipType}
              viewDate={viewDate}
              nativeAd={nativeAd}
              employeeName={employeeName}
              employeeAvatar={employeeAvatar}
              employeePhone={employeePhone}
              employeeEmail={employeeEmail}
            />
          );
        } else if (preview === 'nativead') {
          return (
            <NativeAdPreview
              headline={headline}
              caption={caption}
              viewDate={viewDate}
              nativeAd={nativeAd}
              companyExternalId={companyExternalId}
            />
          );
        }
        break;

      default:
        return (
          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6">No preview available</Typography>
          </Box>
        );
    }
  };

  const { t } = useTranslation();
  const [preview, setPreview] = useState<any>('html5');
  const handleChange = (e: any) => {
    setPreview(e.target.value);
  };
  useEffect(() => {
    if (!nativeAd) {
      setPreview('html5');
    }
  }, [nativeAd]);
  return (
    <div>
      <Box component="div">
        {nativeAd && (
          <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="locale-label">Preview</InputLabel>
            <Select
              labelId="Preview"
              id="Preview"
              defaultValue={'html5'}
              name="Preview"
              value={preview}
              label="Preview"
              onChange={handleChange}>
              <MenuItem value={'html5'}>HTML 5</MenuItem>
              <MenuItem value={'nativead'}>{t('nativeAd')}</MenuItem>
            </Select>
          </FormControl>
        )}
        <Paper sx={{ border: error ? '1px solid red' : '', p: 1, justifyContent: 'center', display: 'flex' }}>
          {error && errorMessage && (
            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="h6" color="error" sx={{ p: 2, pl: 3 }}>
                {errorMessage}
              </Typography>
            </Box>
          )}
          <div>{switchPreview(companyExternalId as string)}</div>
        </Paper>
      </Box>
    </div>
  );
};

export default BidTheatrePreviewResolver;
