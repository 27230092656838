import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';

export interface IDeltaSummaryProps {
  currentCreativeForm: IBasicCreativeFormValues;
}

const DeltaSummary: FC<IDeltaSummaryProps> = ({ currentCreativeForm }): JSX.Element => {
  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h6">Ownership type: {currentCreativeForm?.ownershipType}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Area in usage: {currentCreativeForm?.areaInUsage}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Property debt: {currentCreativeForm?.stateDebt}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Price quote: {currentCreativeForm?.priceQuote?.toLocaleString() || 0} NOK</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Estate type: {currentCreativeForm?.propertyEstateType}</Typography>
      </Grid>
    </div>
  );
};

export default DeltaSummary;
