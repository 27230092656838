import { IAuthResponse } from '@/auth/models/auth.response';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { forEach } from 'lodash';

export const accessTokenParamName = 'accessToken';
export const impersonationBackToken = 'impersonationBackToken';
export const emailParamName = 'email';
export const impersonationBackEmail = 'impersonationBackEmail';

export const substitutionOfCredentialsForImpersonationLS = (credentials: IAuthResponse): void => {
  localStorage.setItem(impersonationBackToken, localStorage.getItem(accessTokenParamName)!);
  localStorage.setItem(impersonationBackEmail, localStorage.getItem(emailParamName)!);
  setCredentialsToLS(credentials);
};

export const cancelImpersonation = (): IAuthResponse => {
  const accessToken = localStorage.getItem(impersonationBackToken)!;
  const email = localStorage.getItem(impersonationBackEmail)!;

  setCredentialsToLS({ accessToken, email });
  deleteKeyValuePairFromLS(impersonationBackToken);
  deleteKeyValuePairFromLS(impersonationBackEmail);
  return { accessToken, email };
};

export const setCredentialsToLS = (credentials: IAuthResponse): void => {
  localStorage.setItem('accessToken', credentials.accessToken);
  localStorage.setItem('email', credentials.email);
};

export const getCredentialsFromLS = (): IAuthResponse | null => {
  const accessToken = localStorage.getItem('accessToken');
  const email = localStorage.getItem('email');

  if (accessToken && email) {
    return { accessToken, email };
  }

  return null;
};

export const clearLS = (): void => {
  localStorage.clear();
};

export const setKeyValuePairToLS = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const deleteKeyValuePairFromLS = (key: string) => {
  localStorage.removeItem(key);
};

export const refreshLSAfterAdCreation = () => {
  for (let key of Object.values(EDITOR_LS_CONSTANTS)) {
    if (key === EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP) {
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, '0');
      continue;
    }

    if (key === EDITOR_LS_CONSTANTS.COMPANY_ID) {
      continue;
    }

    if (key !== EDITOR_LS_CONSTANTS.CREATIVES_FORMS) {
      localStorage.removeItem(key as string);
      continue;
    }

    localStorage.removeItem(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA);
    localStorage.removeItem(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_INDEX);
    localStorage.removeItem(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSEL_INTERNAL_ID);
    localStorage.removeItem(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSELL_ID);
    localStorage.removeItem(EDITOR_LS_CONSTANTS.PROPERTY_ARRAY);

    forEach(EDITOR_LS_CONSTANTS.CREATIVES_FORMS, (el) => {
      localStorage.removeItem(el);
    });
  }
};

export const getValueFromLs = (key: string): string | null => {
  return localStorage.getItem(key);
};
