import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect, useRef, type JSX } from 'react';
import CommonButton from '../../../../../shared/components/CommonButton';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { PlatformType } from '../../../constants/available-platforms';
import { IEditorOrderFormPropertiesBaseModel } from '../editor-order-form-properties-base.model';
import BasicCreativeForm from './BasicCreativeForm';
import { IBasicPreviewPropsModel } from './models/basic-preview-props.model';
import { IVideoCreativeFormValuesModel } from './models/video-creative-form.values.model';
import { useTranslation } from 'react-i18next';
import { IBasicCreativeFormValues } from './models/basic-creative-form-values.model';
import { setcreativeSubmissionProgress } from '@/editor/store/reducers/creative-step.slice';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';

export interface IBasicVideoFormProps extends IEditorOrderFormPropertiesBaseModel<IVideoCreativeFormValuesModel> {
  basePreview: FC<IBasicPreviewPropsModel>[];
  concretePreview: FC[];
  fieldsRequired: boolean;
  platform: PlatformType;
  validationSchema: any;
}

const BasicVideoForm: FC<IBasicVideoFormProps> = ({
  initialValues,
  updating,
  basePreview,
  concretePreview,
  fieldsRequired,
  platform,
  validationSchema,
}): JSX.Element => {
  const videoFormFromRedux = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState[platform].video
  );
  const dispatch = useAppDispatch();
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  const fomrikRef = useRef(formik);

  useEffect(() => {
    fomrikRef?.current.setFieldValue('media', videoFormFromRedux?.media);
  }, [videoFormFromRedux?.media]);

  const { handleSubmit } = useFormSubmission({ formik, formSubmissionProgress, updating, platform });

  const handleUpdate = () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active]);

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          areFieldsRequired={fieldsRequired}
          platform={platform}
          initialValues={initialValues! satisfies IBasicCreativeFormValues}
          concretePreviews={concretePreview}
          basePreviews={basePreview}
        />
        <Grid container spacing={2}>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};

export default BasicVideoForm;
