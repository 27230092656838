import { IRoute } from '../../shared/models/route.model';
import { lazy } from 'react';

export const ROUTE_OFFICES_DECLARATIONS = Object.freeze({
  AdminRetailOfficesPage: 'offices',
  AdminRetailOfficesNewPage: 'offices/new',
  AdminRetailOfficesEditPage: 'offices/:id/edit',
  AdminRetailOfficesPlatformConfigs: 'offices/:id/platform-configs',
});

const OfficesDataTable = lazy(() => import('../pages/offices/OfficesTablePage'));
const CreateEditOfficePage = lazy(() => import('../pages/offices/CreateEditOfficePage'));
const OfficePlatformConfigsListPage = lazy(
  () => import('../pages/office-platform-configs/OfficePlatformConfigsListPage')
);

export const OfficesRoutes: IRoute[] = [
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesPage,
    component: OfficesDataTable,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesNewPage,
    component: CreateEditOfficePage,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesEditPage,
    component: CreateEditOfficePage,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesPlatformConfigs,
    component: OfficePlatformConfigsListPage,
  },
];
