import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import VideoLibraryRounded from '@mui/icons-material/VideoLibraryRounded';
import LibraryAddRounded from '@mui/icons-material/LibraryAddRounded';
import { useTranslation } from 'react-i18next';

interface NavButtonsProps {
  isSuccess: boolean;
  isError: boolean;
  view: string;
  setView: (view: string) => void;
}

const NavButtons: FC<NavButtonsProps> = ({ isSuccess, isError, view, setView }) => {
  const { t } = useTranslation();

  return (
    <>
      {isSuccess && !isError && (
        <Grid
          item
          mt={1}
          sm={6}
          gap={1}
          xs={12}
          display={'flex'}
          justifyContent={{ sm: 'flex-end' }}
          alignItems={'center'}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ':hover': { color: '#000', boxShadow: '1px 2px 0px #000' },
              bgcolor: view === 'list' ? '#000' : '#FFF',
              color: view === 'list' ? '#fff' : '#000',
            }}
            endIcon={<VideoLibraryRounded />}
            onClick={() => setView('list')}>
            {t('videoList')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ':hover': { color: '#000', boxShadow: '1px 2px 0px #000' },
              bgcolor: view === 'creator' ? '#000' : '#FFF',
              color: view === 'creator' ? '#fff' : '#000',
            }}
            endIcon={<LibraryAddRounded />}
            onClick={() => setView('creator')}>
            {t('videoMaker')}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default NavButtons;
