import { IRoute } from '@/shared/models/route.model';
import { lazy } from 'react';

export const ROUTE_COMPANIES_DECLARATIONS = Object.freeze({
  AdminRetailCompaniesPage: 'companies',
  AdminRetailCompaniesNewPage: 'companies/new',
  AdminRetailCompaniesEditPage: 'companies/:id/edit',
  AdminRetailCompaniesPlatformsConfigs: 'companies/:id/platform-configs',
  AdminRetailCompaniesAutoCreationConfigs: 'companies/:id/auto-creation',
  AdminRetailCompaniesAutoCreationConfigsNew: 'companies/:id/auto-creation/new',
  AdminRetailCompaniesAutoCreationConfigsEdit: 'companies/:id/auto-creation/:autoCreationId/edit',
});

const CompaniesDataTable = lazy(() => import('../pages/companies/CompaniesTablePage'));
const CreateEditCompanyPage = lazy(() => import('../pages/companies/CreateEditCompanyPage'));
const PlatformConfigsListPage = lazy(() => import('../pages/companies-platform-configs/PlatformConfigsListPage'));
const CompanyAutoCreationConfigsListPage = lazy(
  () => import('../pages/companies-auto-creation-configs/CompanyAutoCreationConfigsListPage')
);
const AddEditAutoCreationConfigPage = lazy(
  () => import('../pages/companies-auto-creation-configs/AddEditAutoCreationConfigPage')
);

export const CompaniesRoutes: IRoute[] = [
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesPage,
    component: CompaniesDataTable,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesNewPage,
    component: CreateEditCompanyPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesEditPage,
    component: CreateEditCompanyPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesPlatformsConfigs,
    component: PlatformConfigsListPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigs,
    component: CompanyAutoCreationConfigsListPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigsNew,
    component: AddEditAutoCreationConfigPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigsEdit,
    component: AddEditAutoCreationConfigPage,
  },
];
