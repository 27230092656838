import { lazy } from 'react';
import { IRoute } from '../../shared/models/route.model';

export const ROUTE_APP_LOGS = Object.freeze({
  AdminApplicationLogsTablePage: 'application-logs',
});

const ApplicationLogsTable = lazy(() => import('../pages/application-logs/ApplicationLogsTable'));

export const ApplicationLogsRoutes: IRoute[] = [
  {
    path: ROUTE_APP_LOGS.AdminApplicationLogsTablePage,
    component: ApplicationLogsTable,
  },
];
