import { FC, useEffect, type JSX } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { setFinishingCreationOfAd } from '../../../store/reducers/modals-drawers.slice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CommonButton from '../../../../shared/components/CommonButton';
import DoneIcon from '@mui/icons-material/Done';
import { refreshLSAfterAdCreation } from '../../../../shared/services/localstorage';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const FinishingCreationOfAd: FC = (): JSX.Element => {
  const modalOpened = useAppSelector((state) => state.editorModalsDrawersReducer.finishingCreationOfAd);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (modalOpened) {
      refreshLSAfterAdCreation();
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [modalOpened]);

  const { t } = useTranslation();

  const handleClose = () => {
    refreshLSAfterAdCreation();
    dispatch(setFinishingCreationOfAd(false));
    navigate('/editor');
  };

  return (
    <Dialog open={modalOpened} onClose={() => handleClose()}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <DoneIcon color="secondary" fontSize="large" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="finish-dialog-description">{t('finishedCreationOfAd')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CommonButton disableRipple color="secondary" onClick={() => handleClose()}>
          {t('leaveCreation')}
        </CommonButton>
      </DialogActions>
    </Dialog>
  );
};

export default FinishingCreationOfAd;
