import { FC, useState, type JSX } from 'react';
import EditUserDetails, { IUserProfileAddEditFormValues } from '../EditUserDetails';
import AvatarBox from '../AvatarBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Loader from '../../Loader';
import { IUserProfileModel } from '../../../models/user-profile.model';
import { useGetLanguagesForUserQuery } from '@/admin/services/http/languages.api';
import useAppInitLanguage from '@/shared/hooks/app-init-hook';

export type HandleSubmitFn = (values: FormData) => void;

export interface IUserDetailsProps {
  updateSuccess?: boolean;
  userProfile?: IUserProfileModel;
  handleSubmitFn: HandleSubmitFn;
  loading?: boolean;
  actionLoading?: boolean;
}

const UserDetails: FC<IUserDetailsProps> = ({
  userProfile,
  updateSuccess,
  handleSubmitFn,
  loading,
  actionLoading,
}): JSX.Element => {
  const [changedAvatar, setChangedAvatar] = useState<File | null>(null);

  useAppInitLanguage(updateSuccess as boolean);
  const handleImageChanged = (file: File) => {
    setChangedAvatar(file);
  };

  const { data: availableLanguages = [], isLoading: languagesLoading } = useGetLanguagesForUserQuery();

  const handleSubmit = async (values: IUserProfileAddEditFormValues) => {
    const formData = new FormData();
    if (changedAvatar) {
      formData.append('avatar', changedAvatar);
    }

    formData.append('title', values.title ?? '');
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('locale', values.locale ?? '');
    handleSubmitFn(formData);
  };

  return (
    <div>
      {loading || languagesLoading ? (
        <Loader />
      ) : (
        <Grid container={true} justifyContent={'center'}>
          <Grid item xs={8}>
            <Grid component={Card} container p={3}>
              <Grid item pr={3} xs={12} md={5} sx={{ display: 'flex' }}>
                <AvatarBox
                  preSignedAvatar={userProfile?.avatar as string | null}
                  action={handleImageChanged}></AvatarBox>
              </Grid>
              <Grid item xs={12} md={7}>
                <EditUserDetails
                  initialValues={{
                    firstName: userProfile?.firstName || '',
                    lastName: userProfile?.lastName || '',
                    title: userProfile?.title || '',
                    phone: userProfile?.phone || '',
                    locale: userProfile?.locale || availableLanguages[0].locale,
                  }}
                  availableLanguages={availableLanguages}
                  actionLoading={actionLoading}
                  submitFn={async (values) => await handleSubmit(values)}></EditUserDetails>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserDetails;
