import Download from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { FC } from 'react';

interface PropertyIdFormProps {
  handleDownloadClick: (propertyId: string) => Promise<void>;
  t: (key: string) => string;
}

const PropertyIdForm: FC<PropertyIdFormProps> = ({ handleDownloadClick, t }) => {
  return (
    <Formik
      initialValues={{ propertyId: '' }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await handleDownloadClick(values.propertyId);
        setSubmitting(false);
      }}>
      {({ handleChange, handleSubmit, touched, errors, values, getFieldMeta }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              sx={{ mt: 1 }}
              name="propertyId"
              value={values.propertyId}
              required
              type="text"
              fullWidth
              size="small"
              onChange={handleChange}
              id="property-id"
              label={t('propertyId')}
              variant="outlined"
              error={Boolean(touched && errors.propertyId)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleDownloadClick(values.propertyId);
                  ev.preventDefault();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                    <IconButton onClick={() => handleDownloadClick(getFieldMeta('propertyId').value as string)}>
                      <Download />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default PropertyIdForm;
