import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import { bidtheatreClientStyles } from './BidtheatreClientStyle';

const BidtheatreClientPreview: FC<IExtendedPreviewPropsModel> = ({
  caption,
  description,
  propertyPriceQuote,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
  viewDate,
  employeeName,
  employeeAvatar,
  employeePhone,
  employeeEmail,
  companyExternalId,
}): JSX.Element => {
  const bidtheatreClientStyle = companyExternalId ? bidtheatreClientStyles[companyExternalId] || {} : {};

  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (employeeAvatar instanceof File) {
      const url = URL.createObjectURL(employeeAvatar);
      setAvatarUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setAvatarUrl(employeeAvatar);
    }
  }, [employeeAvatar]);

  return (
    <div>
      <Grid container sx={{ overflow: 'hidden' }}>
        {media?.preSignedUrl ? (
          <Grid
            item
            xs={12}
            className="eie-preview-box"
            sx={{
              backgroundImage: `url(${media?.preSignedUrl})`,
            }}>
            <Box sx={{ position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'end', marginTop: '200px' }}>
              <img
                style={{ height: '120px', maxHeight: '200px', maxWidth: '120px', objectFit: 'contain' }}
                src={avatarUrl || undefined}
                alt="logo"
                className="logo"
              />
            </Box>
            <Box
              sx={{
                backgroundColor: bidtheatreClientStyle.backgroundColor,
                zIndex: '0',
                color: bidtheatreClientStyle.textColor || '#fff',
                width: '130px',
                height: '132px',
                top: '-13%',
                float: 'right',
                position: 'relative',
                padding: '7px',
              }}>
              <Typography
                sx={{
                  paddingTop: '50px',
                  fontWeight: '800',
                  fontSize: '13px',
                  fontFamily: bidtheatreClientStyle.fontFamilyOne || '',
                }}>
                <span>{employeeName && <div>{employeeName}</div>}</span>
              </Typography>
              <Typography
                sx={{ fontWeight: '600', fontSize: '10px', fontFamily: bidtheatreClientStyle.fontFamilyTwo || '' }}>
                Reg. Fastighetsmäklare
              </Typography>
              <Typography
                sx={{
                  fontWeight: '800',
                  fontSize: '13px',
                  paddingTop: '3px',
                  fontFamily: bidtheatreClientStyle.fontFamilyTwo || '',
                }}>
                <span>{employeePhone && <div>{employeePhone}</div>}</span>
              </Typography>
              <Typography
                sx={{ fontWeight: '600', fontSize: '10px', fontFamily: bidtheatreClientStyle.fontFamilyTwo || '' }}>
                <span>{employeeEmail && <div>{employeeEmail}</div>}</span>
              </Typography>
            </Box>
          </Grid>
        ) : (
          <EmptyImageCard></EmptyImageCard>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ backgroundColor: '#0000' }}>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img style={{ width: '100%' }} src={bidtheatreClientStyle.logo} alt="logo" className="logo" />
            </Grid>
            <Grid item xs={9}>
              <Grid container flexDirection="column">
                <Grid item xs={12} sx={{ padding: '5px' }}>
                  <Typography
                    sx={{ fontSize: '21px' }}
                    className={`${viewDate && 'animated-words'}`}
                    variant="h3"
                    fontFamily={bidtheatreClientStyle.fontFamilyTwo || ''}
                    fontWeight="medium"
                    color="#232331">
                    <span>{caption && <div>{caption}</div>}</span>
                    <span>{viewDate && <div>{viewDate}</div>}</span>
                  </Typography>
                  <Typography
                    sx={{ fontSize: '23px' }}
                    className={`${ownershipType && 'animated-words'}`}
                    variant="h3"
                    fontFamily={bidtheatreClientStyle.fontFamilyTwo || ''}
                    fontWeight="900"
                    color={bidtheatreClientStyle.primaryColor || '#fff'}>
                    <span>
                      {propertyType || areaInUsage ? (
                        <div>
                          {propertyType?.toUpperCase()} {areaInUsage}
                        </div>
                      ) : null}
                    </span>
                    <span>{ownershipType && <div>{ownershipType}</div>}</span>
                  </Typography>
                  <Typography variant="h6" fontFamily="" color="#232331" fontSize="14px">
                    {description && description.length > 50 ? description.slice(0, 50) + '...' : description}
                  </Typography>
                  <Typography
                    className="animated-words"
                    variant="h4"
                    fontFamily={bidtheatreClientStyle.fontFamilyTwo || ''}
                    fontWeight="900"
                    fontSize="21px"
                    color={bidtheatreClientStyle.primaryColor}>
                    <span>{propertyPriceQuote && <div> {propertyPriceQuote.toLocaleString()}</div>}</span>
                    <span>{propertyDebt && <div> {propertyDebt.toLocaleString()}</div>}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BidtheatreClientPreview;
