import { lazy } from 'react';
import { IRoute } from '../../shared/models/route.model';

export const ROUTE_BUDGETS_ITEMS_DECLARATIONS = Object.freeze({
  AdminBudgetsItemsByCompanyPage: 'budgets/:budgetId/budget-items',
  AdminBudgetsItemsNewPage: 'budgets/:budgetId/budget-items/new',
  AdminBudgetsItemsEditPage: 'budget-items/:budgetItemId/edit',
});

const BudgetsItemsDataTable = lazy(() => import('../pages/budget-items/BudgetsItemsTablePage'));
const CreateEditBudgetItemPage = lazy(() => import('../pages/budget-items/CreateEditBudgetItemPage'));

export const BudgetsItemsRoutes: IRoute[] = [
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsByCompanyPage,
    component: BudgetsItemsDataTable,
  },
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsNewPage,
    component: CreateEditBudgetItemPage,
  },
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsEditPage,
    component: CreateEditBudgetItemPage,
  },
];
