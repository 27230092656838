import {
  useDeleteSharedLinkMetaMutation,
  useGenerateSharedLinkMetaMutation,
} from '@/editor/services/http/advertisements.api';
import CommonButton from '@/shared/components/CommonButton';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FC, useState, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';

export interface ISharedLinkProps {
  advertisement: IFullAdvertisementModel;
}

const SharedLink: FC<ISharedLinkProps> = (props: ISharedLinkProps): JSX.Element => {
  const { advertisement } = props;
  const [genLinkToShow, setGetLinkToShow] = useState<string | null>(null);
  const [generateShareLink, { isLoading }] = useGenerateSharedLinkMetaMutation();
  const [deleteShareLink, { isLoading: deleteShareLinkLoading }] = useDeleteSharedLinkMetaMutation();

  const handleClick = () => {
    toast.promise(navigator.clipboard.writeText(advertisement.shareLink!), {
      loading: 'Copying to clipboard',
      error: 'Unable to copy to clipboard',
      success: 'Link has been copied to clipboard',
    });
  };

  const handleClickGenerateShareLink = async () => {
    const res = await toast.promise(generateShareLink({ id: advertisement.id }), {
      loading: 'Generating...',
      success: 'Successfully generated link',
      error: 'Something happened',
    });

    'data' in res ? setGetLinkToShow(res.data as string) : setGetLinkToShow(null);
  };

  const handleDeleteSharedLink = async () => {
    await toast.promise(deleteShareLink({ id: advertisement.id }).unwrap(), {
      loading: 'Deleting...',
      success: 'Successfully deleted',
      error: 'Cannot delete shared link',
    });
  };

  return (
    <div>
      {advertisement.platform === 'meta' && (
        <div>
          {advertisement.shareLink ? (
            <ListItem>
              <ListItemText id="sharedlink" primary={'Shared link'} />
              <IconButton onClick={handleClick}>
                <CopyAllTwoTone></CopyAllTwoTone>
              </IconButton>
              <IconButton onClick={handleDeleteSharedLink} disabled={deleteShareLinkLoading}>
                <DeleteOutlineTwoToneIcon></DeleteOutlineTwoToneIcon>
              </IconButton>
            </ListItem>
          ) : (
            <div>
              {!genLinkToShow && advertisement.adReferenceId && advertisement.creative.creativeReferenceId && (
                <ListItem>
                  <ListItemText id="sharedlink" primary={'Shared link'} />
                  <CommonButton disabled={isLoading} onClick={handleClickGenerateShareLink} variant="outlined">
                    Generate share link
                  </CommonButton>
                </ListItem>
              )}
              {genLinkToShow && (
                <ListItem>
                  <ListItemText id="sharedlink_gen" primary={'Generated link to copy'} />
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(genLinkToShow);
                      toast.success('Link has been copied to clipboard');
                    }}>
                    <CopyAllTwoTone></CopyAllTwoTone>
                  </IconButton>
                </ListItem>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SharedLink;
