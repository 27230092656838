import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import CommonTextField from '../../../../../../shared/components/CommonTextField';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import {
  setcreativeSubmissionProgress,
  setFieldOfCreativeFormForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IEditorOrderFormPropertiesBaseModel } from '../../editor-order-form-properties-base.model';
import BasicCreativeForm from '../BasicCreativeForm';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import { basicBidtheatreCreativeSchema } from '../shared/basic-validation-schema';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';
import BoardsPreviewResolver from '@/shared/components/platform-previews/previews/boards/BoardsPreviewResolver';

// TODO REFACTOR !!!

export interface IBoardsSingleCreativeFormProps
  extends IEditorOrderFormPropertiesBaseModel<ISingleCreativeFormValuesModel> {}

const BoardsSingleCreativeForm: FC<IBoardsSingleCreativeFormProps> = ({
  initialValues,
  updating,
  externalCompanyId,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const singleReduxFormState = useAppSelector(
    (state) =>
      state.creativeStepSliceReducer.creativeFormsState[AvailablePlatformsEnum.BOARDS][CreativeTypesEnum.SINGLE]
  );
  const platform = AvailablePlatformsEnum.BOARDS;
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const { t } = useTranslation();
  const { data: userData } = useGetMeQuery();

  const formik = useFormik({
    initialValues: {
      ...initialValues!,
      nativeAd: true,
      employeeName: initialValues?.employeeName ?? '',
      employeePhone: initialValues?.employeePhone ?? '',
      employeeEmail: initialValues?.employeeEmail ?? '',
      employeeAvatar: initialValues?.employeeAvatar ?? '',
      doohBrokerText: initialValues?.doohBrokerText ?? '',
    },
    validationSchema: basicBidtheatreCreativeSchema.concat(
      Yup.object({
        media: Yup.object().nullable().required('Media required'),
      })
    ),
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue('media', singleReduxFormState?.media);
  }, [singleReduxFormState?.media]);

  const { handleSubmit } = useFormSubmission({
    formik,
    formSubmissionProgress,
    updating,
    platform: AvailablePlatformsEnum.BOARDS,
  });

  const handleUpdate = () => {
    const { caption } = formik.values;
    basicBidtheatreCreativeSchema
      .validateAt('caption', { caption })
      .then(() => {
        dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
      })
      .catch((validationError) => {
        dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: platform }));
        toast.error(validationError.errors);
      });
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active]);

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          userData={userData}
          basePreviews={[BoardsPreviewResolver]}
          concretePreviews={[BoardsPreviewResolver]}
          areFieldsRequired={true}
          platform={AvailablePlatformsEnum.BOARDS}
          initialValues={initialValues!}
          externalCompanyId={externalCompanyId}
          additionalFields={
            <div>
              <CommonTextField
                sx={{ mb: 2 }}
                type="text"
                name="employeeName"
                label={`${t('Broker Name')}`}
                value={formik.getFieldMeta('employeeName').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BOARDS,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'employeeName',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: 2 }}
                type="text"
                name="link"
                label={`${t('link')}`}
                value={formik.getFieldMeta('link').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BOARDS,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'link',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: 2 }}
                type="text"
                name="doohBrokerText"
                label={`${t('Broker Text')}`}
                value={formik.getFieldMeta('doohBrokerText').value}
                multiline
                minRows={2}
                maxRows={5}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BOARDS,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'doohBrokerText',
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
          }
        />
        <Grid container>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};
export default BoardsSingleCreativeForm;
