import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Autocomplete, GoogleMap, LoadScript } from '@react-google-maps/api';
import { FC, useCallback, useEffect, useMemo, useRef, useState, type JSX } from 'react';
import Loader from '../../../../../shared/components/Loader';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { useTranslation } from 'react-i18next';
import { addPlace, removeAllPlaces, removePlace } from '@/editor/store/reducers/adSet-step.slice';
import Clear from '@mui/icons-material/Clear';
import Warning from '@mui/icons-material/Warning';
import { useParams } from 'react-router-dom';
import { useGetOrderWithDetailsByIdQuery } from '@/editor/services/http/orders.api';
import { useGetMeQuery } from '@/editor/services/http/users.api';

type AutoComplete = google.maps.places.Autocomplete;
type MapsEventListener = google.maps.MapsEventListener;

export interface ICircleViewModel {
  address: string;
  circle: google.maps.Circle;
}

// TODO Refactor

const ConfiguredGoogleMap: FC = (): JSX.Element => {
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const storedCircles = useAppSelector((state) => state.adSetStepSliceReducer.places);
  const { id } = useParams<{ id: string }>();

  const { currentData } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId ?? '' },
    { skip: !propertyExtId }
  );
  const { data: orderData } = useGetOrderWithDetailsByIdQuery({ id: id! }, { skip: !id });
  const locationRef = useRef<HTMLInputElement>(null);

  const [map, setMap] = useState<google.maps.Map>();

  const [currentSearchValue, setCurrentSearchValue] = useState<string>('');
  const [currentPlaceListenerId, setCurrentPlaceListenerId] = useState<MapsEventListener | null>(null);
  const [googleMapCircles, setGoogleMapCircles] = useState<ICircleViewModel[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const center = useMemo(() => ({ lat: 59.9131016, lng: 10.7390799 }), []);
  const mapOptions = useMemo(() => ({ disableDefaultUI: true, zoomControl: true }), []);
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const libraries = useMemo(() => ['places'], []);

  const slidersState = useAppSelector((state) => state.editorActivePlatformsReducer.slidersState);
  const hasMetaPlatform = slidersState.some((slider) => slider.platform === 'meta' && slider.isActive);
  const platform = orderData?.advertisements.map((ad) => ad.platform);
  const isMeta = platform?.some((slider) => slider.includes('meta'));
  const radiusAutoComplete = hasMetaPlatform || isMeta ? 17000 : 3000;

  const { data: userData } = useGetMeQuery();
  const language = userData?.companyLocale == 'sw' ? 'sv' : userData?.companyLocale;

  useEffect(() => {
    if (locationRef.current) {
      generateAutoComplete();
    }
  }, [locationRef.current]);

  useEffect(() => {
    googleMapCircles.forEach((circleModel) => {
      circleModel.circle.setMap(map as any);
      map?.panTo(circleModel.circle.getCenter()!);
    });
  }, [googleMapCircles, map]);

  useEffect(() => {
    if (isLoaded && map) {
      googleMapCircles.forEach((circleModel) => {
        circleModel.circle.setMap(null);
      });
      setGoogleMapCircles([]);
      loadInitialCircle(radiusAutoComplete);
    }
  }, [isLoaded, map, slidersState]);

  const loadCirclesFromStore = (radiusAutoComplete: any) => {
    storedCircles.forEach((el) => {
      createCircle(el.center.lat, el.center.lng, radiusAutoComplete, el.address, true);
    });
  };

  const loadInitialCircle = (radiusAutoComplete: any) => {
    if (storedCircles.length !== 0) {
      loadCirclesFromStore(radiusAutoComplete);
      return;
    }

    const geodecoder = new google.maps.Geocoder();
    geodecoder
      .geocode({
        address: currentData?.propertyAddress,
      })
      .then((resp) => {
        if (!resp.results || resp.results.length === 0) return;
        const place = resp.results[0];
        const placeName = place.formatted_address.substring(0, place.formatted_address.indexOf(','));

        const circle = createCircle(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          radiusAutoComplete,
          placeName
        );

        map?.panTo(circle?.getCenter()!);
      });
  };

  const createCircle = (
    lat: number,
    lng: number,
    radius: number = radiusAutoComplete,
    address: string,
    fromStore?: boolean
  ) => {
    const circle = new google.maps.Circle({
      center: new google.maps.LatLng({ lat, lng }),
      radius,
      editable: true,
      draggable: true,
      map: map as google.maps.Map | null | undefined,
    });

    const minRadius = hasMetaPlatform || isMeta ? 17000 : 3000;
    const maxRadius = 80000;

    circle.addListener('radius_changed', function () {
      if (circle.getRadius() > maxRadius) circle.setRadius(maxRadius);
      if (circle.getRadius() < minRadius) circle.setRadius(minRadius);
    });

    if (!fromStore) {
      dispatch(addPlace({ address: address, center: { lat, lng }, radius }));
    } else {
      setTimeout(() => {
        dispatch(removeAllPlaces());
        storedCircles.forEach((el) => {
          let lat = el.center.lat;
          let lng = el.center.lng;
          dispatch(addPlace({ address: el.address, center: { lat, lng }, radius: radiusAutoComplete }));
        });
      }, 1000);
    }
    map?.panTo(circle.getCenter()!);
    setGoogleMapCircles((prev) => [...prev, { address, circle }]);
    return circle;
  };

  const handleDelete = (element: ICircleViewModel, index: number) => {
    element.circle.setMap(null);
    setGoogleMapCircles((prev) => prev.filter((_, i) => i !== index));
    dispatch(removePlace(index));
  };

  const autoCompleteHandler = (autocomplete: AutoComplete) => {
    let listener = autocomplete?.addListener('place_changed', function () {
      const place = autocomplete!.getPlace();
      if (!place.geometry || !place.geometry.location || !place.formatted_address) return;

      const placeName = place.formatted_address.substring(0, place.formatted_address.indexOf(','));
      const circle = createCircle(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        radiusAutoComplete,
        placeName
      );

      setCurrentSearchValue('');
      map?.panTo(circle.getCenter()!);
    });

    setCurrentPlaceListenerId(listener);
  };

  const generateAutoComplete = () => {
    google.maps.event.removeListener(currentPlaceListenerId!);
    setCurrentPlaceListenerId(null);
    const searchInputField = document.getElementById(`autocomplete-1`);
    const autocomplete = new window.google.maps.places.Autocomplete(searchInputField as HTMLInputElement, {
      fields: ['geometry', 'formatted_address', 'name'],
    });
    autoCompleteHandler(autocomplete);
  };
  const handleScriptLoad = () => {
    setIsLoaded(true);
  };
  const handleScriptError = () => {
    setIsLoaded(false);
  };

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <Box component="div" sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Box component="div" sx={{ width: '100%' }}>
            <Autocomplete
              options={{
                fields: ['geometry', 'formatted_address', 'name'],
              }}>
              <TextField
                size="small"
                error={googleMapCircles.length === 0}
                label={googleMapCircles.length > 0 ? t('pickLocation') : t('searchForLocation')}
                value={currentSearchValue}
                onChange={(e) => setCurrentSearchValue(e.target.value)}
                id={`autocomplete-1`}
                ref={locationRef}
                type="text"
                sx={{ width: '100%' }}
              />
            </Autocomplete>
            <Box>
              {googleMapCircles.length > 0 ? (
                <Box sx={{ mt: 1, mb: 1 }}>
                  {googleMapCircles.map((el, index) => (
                    <Chip
                      sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        '& .MuiChip-deleteIcon': {
                          color: '#fff',
                        },
                        '&:hover': {
                          backgroundColor: '#ffa737',
                          color: '#000',
                          '& .MuiChip-deleteIcon': {
                            color: '#000',
                          },
                        },
                        borderRadius: '8px',
                        mr: 1,
                      }}
                      color="secondary"
                      deleteIcon={<Clear />}
                      variant="outlined"
                      key={index}
                      label={el.address}
                      onDelete={() => handleDelete(el, index)}
                      onClick={() => map?.panTo(el.circle.getCenter()!)}
                    />
                  ))}
                </Box>
              ) : (
                <Box component="div" sx={{ mt: 1, mb: 1 }}>
                  <Chip
                    sx={{
                      backgroundColor: '#fff',
                      color: '#000',
                      borderRadius: '8px',
                      borderColor: 'error.light',
                      mr: 1,
                      '& .MuiChip-icon': {
                        color: 'error.main',
                      },
                    }}
                    variant="outlined"
                    icon={<Warning />}
                    label={t('googleMapError')}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            zoom={7}
            options={mapOptions}
            onLoad={(map) => onLoad(map)}
          />
        </Box>
      )}
      <LoadScript
        googleMapsApiKey="AIzaSyC-MgcSme2UDlU0hZeEoBN97nqvlV83yx4"
        language={language}
        libraries={libraries as any}
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      />
    </>
  );
};

export default ConfiguredGoogleMap;
