import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, type JSX } from 'react';
import * as Yup from 'yup';
import CommonTextField from '../../../../../../shared/components/CommonTextField';
import DeltaPreviewResolver from '../../../../../../shared/components/platform-previews/previews/delta/DeltaPreviewResolver';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import {
  setcreativeSubmissionProgress,
  setFieldOfCreativeFormForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IEditorOrderFormPropertiesBaseModel } from '../../editor-order-form-properties-base.model';
import BasicCreativeForm from '../BasicCreativeForm';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import { basicCreativeSchema } from '../shared/basic-validation-schema';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';

// TODO REFACTOR !!!

export interface IDeltaSingleCreativeFormProps
  extends IEditorOrderFormPropertiesBaseModel<ISingleCreativeFormValuesModel> {}

const DeltaSingleCreativeForm: FC<IDeltaSingleCreativeFormProps> = ({ initialValues, updating }): JSX.Element => {
  const dispatch = useAppDispatch();
  const singleReduxFormState = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState[AvailablePlatformsEnum.DELTA][CreativeTypesEnum.SINGLE]
  );

  const platform = AvailablePlatformsEnum.DELTA;

  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);

  const { t } = useTranslation();

  const { data: userData } = useGetMeQuery();

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: basicCreativeSchema.concat(
      Yup.object({
        media: Yup.object().required('Media required'),
      })
    ),
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue('media', singleReduxFormState?.media);
  }, [singleReduxFormState?.media]);

  const { handleSubmit } = useFormSubmission({
    formik,
    formSubmissionProgress,
    updating,
    platform: AvailablePlatformsEnum.DELTA,
  });

  const handleUpdate = () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active]);

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          userData={userData}
          basePreviews={[DeltaPreviewResolver]}
          concretePreviews={[DeltaPreviewResolver]}
          areFieldsRequired={true}
          platform={AvailablePlatformsEnum.DELTA}
          initialValues={initialValues!}
          additionalFields={
            <div>
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="ownershipType"
                label={t('ownershipType')}
                value={formik.getFieldMeta('ownershipType').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.DELTA,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'ownershipType',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="areaInUsage"
                label={t('areaInUsage')}
                value={formik.getFieldMeta('areaInUsage').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.DELTA,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'areaInUsage',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <DateTimePicker
                label={t('viewDate')}
                format="[Visning] DD.MM.YYYY [kl.] HH:mm"
                ampmInClock={false}
                ampm={false}
                value={formik.getFieldMeta('viewDate').value}
                onChange={(e) => {
                  formik.setFieldValue('viewDate', e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.DELTA,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'viewDate',
                      value: moment(e).format('DD.MM.YYYY HH:mm'),
                    })
                  );
                }}
                // renderInput={(params) => (
                //   <TextField
                //     sx={{ mb: '15px' }}
                //     {...params}
                //     fullWidth
                //     color="secondary"
                //     variant="outlined"
                //     error={false}
                //   />
                // )}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="number"
                name="stateDebt"
                label={t('propertyDebt')}
                value={formik.getFieldMeta('stateDebt').value || ''}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.DELTA,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'stateDebt',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="number"
                name="priceQuote"
                label={t('propertyPriceQuote')}
                value={formik.getFieldMeta('priceQuote').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      creativeType: CreativeTypesEnum.SINGLE,
                      platform: AvailablePlatformsEnum.DELTA,
                      field: 'priceQuote',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="propertyEstateType"
                label={t('propertyEstateType')}
                value={formik.getFieldMeta('propertyEstateType').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      creativeType: CreativeTypesEnum.SINGLE,
                      platform: AvailablePlatformsEnum.DELTA,
                      field: 'propertyEstateType',
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
          }
        />
        <Grid container>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};

export default DeltaSingleCreativeForm;
