import SyncIcon from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ImageList from '@mui/material/ImageList';
import Loader from '../../../../shared/components/Loader';
import MediaLibraryImageItem from './MediaLibraryImageItem';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store/hooks/redux';
import ImagesUploader from './ImagesUploader';
import useImageList from './hooks/useImageList';
import { useGetMediaLibraryImagesQuery } from '@/editor/services/http/media-library.api';
import { getValueFromLs } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { FC, memo, SyntheticEvent, useState, type JSX } from 'react';

const MediaLibraryImages: FC = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(1);
  const { t } = useTranslation();
  const drawerState = useAppSelector((state) => state.editorModalsDrawersReducer.mediaLibraryDrawerState);
  const creativeState = useAppSelector((state) => state.creativeStepSliceReducer);
  const propertyId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);

  const {
    data: mediaLibraryData,
    isLoading: mediaLibraryIsLoading,
    isFetching: mediaLibraryIsFetching,
    refetch: mediaLibraryImagesRefetch,
  } = useGetMediaLibraryImagesQuery(
    { propertyId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)! },
    { skip: !getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID) }
  );

  const { uploadedImages, externalImages } = useImageList(
    drawerState,
    propertyId!,
    mediaLibraryData,
    setTabValue,
    mediaLibraryImagesRefetch
  );

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 0) return;
    setTabValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: '100%', pl: 1, pr: 1 }}>
        {mediaLibraryIsLoading ? (
          <Loader />
        ) : (
          <div>
            <Box>
              {mediaLibraryData && mediaLibraryData.length >= 0 && (
                <Box>
                  <Box
                    justifyContent={'center'}
                    sx={{ display: 'flex', width: '100%', bgcolor: 'background.paper', borderBottom: '1px solid' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered>
                      <Tab label={t('externalImages')} value={1} />
                      <Tab label={t('uploadedCroppedImages')} value={2} />
                      <Tab
                        onClick={mediaLibraryImagesRefetch}
                        icon={mediaLibraryIsFetching ? <CircularProgress size={20} /> : <SyncIcon />}
                        value={0}
                        aria-label="refresh"
                      />
                    </Tabs>
                    <ImagesUploader refetchAction={mediaLibraryImagesRefetch} />
                  </Box>
                  <Box>
                    {tabValue === 1 && (
                      <ImageList cols={3} gap={8} variant="masonry">
                        {externalImages!.map((item, key) => (
                          <MediaLibraryImageItem
                            key={item.id}
                            queue={key}
                            item={item}
                            drawerState={drawerState}
                            creativeState={creativeState}
                          />
                        ))}
                      </ImageList>
                    )}
                    {tabValue === 2 && (
                      <ImageList cols={3} gap={8} variant="masonry">
                        {uploadedImages!.map((item, key) => (
                          <MediaLibraryImageItem
                            key={item.id}
                            queue={key}
                            item={item}
                            drawerState={drawerState}
                            creativeState={creativeState}
                          />
                        ))}
                      </ImageList>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default memo(MediaLibraryImages);
