import { lazy } from 'react';
import { IRoute } from '../../shared/models/route.model';

export const ROUTE_RETAIL_PROVIDERS_DECLARATIONS = Object.freeze({
  AdminRetailProvidersPage: 'providers',
  AdminRetailProvidersNewPage: 'providers/new',
  AdminRetailProvidersEditPage: 'providers/:providerId/edit',
  AdminRetailProvidersDetailsPage: 'providers/:providerId/details',
});

const RetailProvidersDataTable = lazy(() => import('../pages/retail-providers/RetailProvidersTablePage'));
const CreateEditProviderPage = lazy(() => import('../pages/retail-providers/CreateEditProviderPage'));

export const RetailDataProvidersRoutes: IRoute[] = [
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersPage,
    component: RetailProvidersDataTable,
  },
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersNewPage,
    component: CreateEditProviderPage,
  },
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersEditPage,
    component: CreateEditProviderPage,
  },
];
