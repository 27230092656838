import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

export interface IBasicSummaryProps {
  values: IBasicCreativeFormValues;
  platformsFromStoreIds?: string[] | string;
}

const BasicSummary: FC<IBasicSummaryProps> = ({ values, platformsFromStoreIds }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Grid item xs={12}>
        {platformsFromStoreIds != 'bidtheatre' && (
          <div>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('headline')}:
            </Typography>
            <Typography variant="h6">{values?.headline}</Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('description')}:
        </Typography>
        <Typography variant="h6">{values?.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('caption')}:
        </Typography>
        <Typography variant="h6">{values?.caption}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('link')}:
        </Typography>
        <Typography variant="h6">{values?.link}</Typography>
      </Grid>
    </div>
  );
};
export default BasicSummary;
