import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const FremeiendomsmeglingBidtheatreSinglePreview: FC<IExtendedPreviewPropsModel> = ({
  caption,
  description,
  propertyPriceQuote,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
  viewDate,
  employeeName,
  employeeAvatar,
  employeePhone,
  employeeEmail,
}): JSX.Element => {
  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (employeeAvatar instanceof File) {
      const url = URL.createObjectURL(employeeAvatar);
      setAvatarUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setAvatarUrl(employeeAvatar);
    }
  }, [employeeAvatar]);

  return (
    <div>
      <Grid container sx={{ overflow: 'hidden' }}>
        {media?.preSignedUrl ? (
          <Grid
            item
            xs={12}
            className="eie-preview-box"
            sx={{
              backgroundImage: `url(${media?.preSignedUrl})`,
            }}></Grid>
        ) : (
          <EmptyImageCard></EmptyImageCard>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ backgroundColor: '#0000' }}>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                style={{ width: '125%', paddingLeft: '14px' }}
                src="/images/fremeiendomsmegling/fremeiendomsmegling_logo.png"
                alt="logo"
                className="logo"
              />
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container flexDirection="column">
                <Grid item xs={12} sx={{ padding: '5px', paddingLeft: '25px' }}>
                  <Typography
                    sx={{ fontSize: '21px' }}
                    className={`${viewDate && 'animated-words'}`}
                    variant="h3"
                    fontFamily=""
                    fontWeight="medium"
                    color="#232331">
                    <span>{caption && <div>{caption}</div>}</span>
                    <span>{viewDate && <div>{viewDate}</div>}</span>
                  </Typography>
                  <Typography
                    sx={{ fontSize: '27px' }}
                    className={`${ownershipType && 'animated-words'}`}
                    variant="h3"
                    fontFamily=""
                    fontWeight="900"
                    color="rgb(244, 125, 75)">
                    <span>
                      {propertyType || areaInUsage ? (
                        <div>
                          {propertyType?.toUpperCase()} {areaInUsage}
                        </div>
                      ) : null}
                    </span>
                    <span>{ownershipType && <div>{ownershipType}</div>}</span>
                  </Typography>
                  <Typography variant="h6" fontFamily="" color="#232331" fontSize="14px">
                    {description && description.length > 50 ? description.slice(0, 50) + '...' : description}
                  </Typography>
                  <Typography
                    className={`${propertyDebt && 'animated-words'}`}
                    variant="h4"
                    fontFamily=""
                    fontWeight="900"
                    fontSize="21px"
                    color="rgb(244, 125, 75)">
                    <span>{propertyPriceQuote && <div> {propertyPriceQuote.toLocaleString()}</div>}</span>
                    <span>{propertyDebt && <div> {propertyDebt.toLocaleString()}</div>}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FremeiendomsmeglingBidtheatreSinglePreview;
