import Box from '@mui/material/Box';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { useEffect, useRef, type JSX } from 'react';

const MetaVideoPreview = (): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const formVideo = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState.meta.video.media);

  const timestamp = new Date().getTime();

  useEffect(() => {
    videoRef?.current?.load();
  }, [formVideo?.preSignedUrl]);

  return (
    <Box component="div" sx={{ maxWidth: '632px', height: '100%' }}>
      {formVideo?.preSignedUrl ? (
        <video ref={videoRef} preload="metadata" style={{ width: '100%', borderRadius: '5px' }} controls>
          <source src={formVideo?.preSignedUrl + '#t=' + timestamp} type="video/mp4" />
        </video>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default MetaVideoPreview;
