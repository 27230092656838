import { useAppSelector } from '@/store/hooks/redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const SormeglerenDeltaSinglePreview: FC<IExtendedPreviewPropsModel> = ({
  caption,
  propertyPriceQuote,
  areaInUsage,
  propertyDebt,
  ownershipType,
  description,
}): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer?.creativeFormsState?.delta?.single?.media);

  return (
    <div>
      <Grid container sx={{ border: '1px solid #231464', maxWidth: '300px', height: '600px' }}>
        <Grid item xs={12} sx={{ minHeight: '325px', pb: 2, backgroundColor: '#FFF' }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}>
            <img
              className="sormegleren-preview-box"
              src={media?.preSignedUrl}
              alt="property"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box
            sx={{
              height: '13px',
              width: '100%',
              backgroundImage: 'linear-gradient(to right, #604696,#df4114,#f7a600)',
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ zIndex: 10 }} p={2}>
          <Typography
            className="animated-words"
            sx={{ textOverflow: 'ellipsis' }}
            variant="h4"
            fontFamily="GothamNarrowMedium">
            <span>{caption}</span>
            {ownershipType && <span>{ownershipType}</span>}
          </Typography>
          <Typography variant="h5" fontFamily="GothamNarrowBook">
            {description}
          </Typography>
          <Typography variant="h5" fontFamily="GothamNarrowBook">
            {areaInUsage && <span>{areaInUsage}</span>}
          </Typography>
          <Typography className={propertyDebt ? 'animated-words' : ''} variant="h5" fontFamily="GothamNarrowBook">
            {propertyPriceQuote && (
              <span>Prisantydning: {propertyPriceQuote?.toLocaleString().replace(/,/g, ' ')},-</span>
            )}
            {propertyDebt && <span>Fellesgjeld: {propertyDebt?.toLocaleString().replace(/,/g, ' ')},-</span>}
          </Typography>
          <Box sx={{ float: 'right', position: 'relative', top: '40px', left: '10px' }}>
            <img
              style={{ width: '100px' }}
              src="/images/sormegleren/sormegleren_logo_a_rgb.png"
              alt="sormegleren-logo"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SormeglerenDeltaSinglePreview;
