import { FC, type JSX } from 'react';
import Grid from '@mui/material/Grid';
import OrdersTableWrapper from './components/OrdersTableWrapper';

const MainPage: FC = (): JSX.Element => {
  return (
    <Grid container sx={{ p: 4 }}>
      <Grid item xs={12}>
        <OrdersTableWrapper />
      </Grid>
    </Grid>
  );
};

export default MainPage;
