import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const WebmegleBidtheatreSinglePreview: FC<IExtendedPreviewPropsModel> = ({
  caption,
  description,
  propertyPriceQuote,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
  viewDate,
}): JSX.Element => {
  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );

  return (
    <div>
      <Grid container sx={{ overflow: 'hidden' }}>
        {media?.preSignedUrl ? (
          <Grid
            item
            xs={12}
            className="eie-preview-box"
            sx={{
              backgroundImage: `url(${media?.preSignedUrl})`,
            }}>
            <Box className="eie-preview-box__button-container">
              <Button className="eie-preview-box__button-container__button">
                <Typography variant="h4">Se bolig</Typography>
              </Button>
            </Box>
          </Grid>
        ) : (
          <EmptyImageCard></EmptyImageCard>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ backgroundColor: '#0a1119' }}>
            <Grid item xs={3}>
              <img style={{ width: '100%' }} src="/images/eie/logo-with-text.png" alt="logo" className="logo" />
            </Grid>
            <Grid item xs={9}>
              <Grid container flexDirection="column">
                <Grid item xs={12}>
                  <Typography
                    className={`${viewDate && 'animated-words'}`}
                    variant="h3"
                    fontFamily="saol"
                    fontWeight="bold"
                    color="#fff">
                    <span>{caption && <div>{caption}</div>}</span>
                    <span>{viewDate && <div>{viewDate}</div>}</span>
                  </Typography>
                  <Typography
                    className={`${ownershipType && 'animated-words'}`}
                    variant="h3"
                    fontFamily="saol"
                    fontWeight="bold"
                    color="#fff">
                    <span>
                      {propertyType || areaInUsage ? (
                        <div>
                          {propertyType?.toUpperCase()} {areaInUsage}
                        </div>
                      ) : null}
                    </span>
                    <span>{ownershipType && <div>{ownershipType}</div>}</span>
                  </Typography>
                  <Typography variant="h6" fontFamily="Helvetica" color="#fff">
                    {description && description.length > 50 ? description.slice(0, 50) + '...' : description}
                  </Typography>
                  <Typography
                    className={`${propertyDebt && 'animated-words'}`}
                    variant="h4"
                    fontFamily="saol"
                    fontWeight="bold"
                    color="#fff">
                    <span>{propertyPriceQuote && <div> {propertyPriceQuote.toLocaleString()}</div>}</span>
                    <span>{propertyDebt && <div> {propertyDebt.toLocaleString()}</div>}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WebmegleBidtheatreSinglePreview;
