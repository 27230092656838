import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import LoopRounded from '@mui/icons-material/LoopRounded';
import MoreVert from '@mui/icons-material/MoreVert';
import Search from '@mui/icons-material/Search';
import UploadFileRounded from '@mui/icons-material/UploadFileRounded';
import CommonTextField from '@/shared/components/CommonTextField';
import { ChangeEvent, MouseEvent, RefObject, FC } from 'react';

export interface VideoListMenuProps {
  handleInputClick: () => void;
  handleFileSelected: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (value: string) => void;
  isLoading: boolean;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleVideoCreatorClick: () => void;
  handleClose: () => void;
  handleUploadClick: () => void;
  handleRefresh: () => void;
  inputRef: RefObject<HTMLInputElement | null>;
  handleSearchChange: (value: string) => void;
}

const VideoListMenu: FC<VideoListMenuProps> = ({
  handleFileSelected,
  isLoading,
  handleClick,
  anchorEl,
  open,
  handleVideoCreatorClick,
  handleClose,
  handleUploadClick,
  inputRef,
  handleRefresh,
  handleSearchChange,
}) => {
  return (
    <Grid item xs={12} display={'flex'} justifyContent={{ md: 'flex-end', xs: 'flex-start' }} alignItems={'center'}>
      {isLoading && (
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            size={30}
            sx={{
              color: '#000',
              position: 'absolute',
              top: -4,
              left: -5,
              zIndex: 1,
            }}
          />
          <UploadFileRounded />
        </Box>
      )}
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <MoreVert></MoreVert>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={handleUploadClick}>
            <UploadFileRounded />
            Last opp video
          </MenuItem>
          <MenuItem onClick={handleRefresh}>
            <LoopRounded />
            Refresh
          </MenuItem>
          <MenuItem onClick={handleVideoCreatorClick}>
            <AddBoxRounded />
            Lag ny video
          </MenuItem>
        </Menu>
      </div>
      <CommonTextField
        fullWidth={false}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSearchChange(e.target.value)}
      />
      <input type="file" accept="video/mp4" hidden ref={inputRef} onInput={handleFileSelected}></input>
    </Grid>
  );
};

export default VideoListMenu;
