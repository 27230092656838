import Box from '@mui/material/Box';
import { FC, memo, type JSX } from 'react';
import ImagesUploader from './images/ImagesUploader';
import VideosUploader from './videos/VideosUploader';

type ConcreteUploaderType = 'image' | 'video';

export interface IRefetchAction {
  refetchAction: () => void;
  propertyId?: string;
}

export interface IMediaUploaderProps extends IRefetchAction {
  concreteUploaderType?: ConcreteUploaderType;
}

const MediaUploader: FC<IMediaUploaderProps> = ({ concreteUploaderType, refetchAction }): JSX.Element => {
  return (
    <Box>
      {concreteUploaderType === 'image' ? (
        <ImagesUploader refetchAction={refetchAction} />
      ) : (
        <VideosUploader refetchAction={refetchAction} />
      )}
    </Box>
  );
};

export default memo(MediaUploader);
