export const themeOptions = {
  palette: {
    primary: {
      main: '#000',
      light: '#BFACE2',
      dark: '#000',
    },
    background: {
      default: '#EEEEEE',
    },
    secondary: {
      main: '#000',
      light: '#ffa737',
      dark: '#ffa737',
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 40,
    },
    h2: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 32,
    },
    h3: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 24,
    },
    h4: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 20,
    },
    h5: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 16,
    },
    h6: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 14,
    },
  },
};
