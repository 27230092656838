import { FC, type JSX } from 'react';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import SormeglerenStatisticsHeader from '@/statistics/templates/sormegleren/components/header/SormeglerenStatisticsHeader';
import SormeglerenTimeLeft from '@/statistics/templates/sormegleren/components/header/SormeglerenTimeLeft';
import SormeglerenFooter from '@/statistics/templates/sormegleren/components/footer/SormeglerenFooter';
import Grid from '@mui/material/Grid';
import EieTimeLineProgress from '../eie/components/EieTimeLineProgress';
import CampaignMetaBarCharts from '../eie/components/CampaignMetaBarCharts';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import EieMetaSection from '../eie/components/meta-section/EieMetaSection';
import EieDeltaSection from '../eie/components/delta-section/EieDeltaSection';
import EieBidtheatreSection from '../eie/components/bidtheatre-section/EieBidtheatreSection';
import EieBoardsSection from '../eie/components/boards-section/EieBoardsSection';
import EieSnapchatSection from '../eie/components/snapchat-section/EieSnapchatSection';
import HjemSection from '../eie/components/simple-section/HjemSection';
import FinnSection from '../eie/components/simple-section/FinnSection';

interface ISormeglerenTemplateProps {
  order: ISingleOrderWithDetailsModel;
}

const SormeglerenTemplate: FC<ISormeglerenTemplateProps> = (props: ISormeglerenTemplateProps): JSX.Element => {
  const { order } = props;

  const startDate = order.advertisements[0].adSet?.startTime;
  const endDate = order.advertisements[0].adSet?.endTime;

  const findAd = (platform: PlatformType): IFullAdvertisementModel | null => {
    return order.advertisements.find((el) => el.platform === platform) ?? null;
  };

  const sumValues = (array: any[], param: string): number => {
    return array.reduce((sum, el) => sum + +el[param], 0);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SormeglerenStatisticsHeader order={order} />
        <SormeglerenTimeLeft startTime={startDate} endTime={endDate} />
      </Grid>
      <Grid container item spacing={2} p={2}>
        <Grid item xs={12}>
          <EieTimeLineProgress order={order} />
        </Grid>
        <Grid item xs={12}>
          <CampaignMetaBarCharts order={order} />
        </Grid>

        {findAd('meta') && (
          <Grid item xs={12}>
            <EieMetaSection advertisement={findAd('meta')!}></EieMetaSection>
            {/*<EieInstagramSection advertisement={findAd('meta')!} />*/}
          </Grid>
        )}
        {findAd('delta') && (
          <Grid item xs={12}>
            <EieDeltaSection advertisement={findAd('delta')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('bidtheatre') && (
          <Grid item xs={12}>
            <EieBidtheatreSection advertisement={findAd('bidtheatre')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('boards') && (
          <Grid item xs={12}>
            <EieBoardsSection advertisement={findAd('boards')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('snapchat') && <EieSnapchatSection advertisement={findAd('snapchat')!} />}
        <Grid item xs={12} md={6}>
          <HjemSection
            platform={'Hjem.no'}
            favorites={sumValues(order.propertyHjemStats, 'favorites')}
            clicks={sumValues(order.propertyHjemStats, 'page_views')}
            mails={sumValues(order.propertyHjemStats, 'emails')}
            views={sumValues(order.propertyHjemStats, 'views')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FinnSection
            platform={'Finn.no'}
            clicks={order.propertyFinnStats.saved}
            mails={order.propertyFinnStats.mails}
            views={order.propertyFinnStats.views}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} display="flex" direction="column" justifyContent="flex-end">
        <SormeglerenFooter userDetails={order.userDetails} />
      </Grid>
    </Grid>
  );
};

export default SormeglerenTemplate;
