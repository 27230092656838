import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import EmptyImageCard from '../../../../../shared/components/platform-previews/previews/EmptyImageCard';
import {
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from './models/carousel-creative-form-values';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileRounded from '@mui/icons-material/UploadFileRounded';
import { FormikContextType } from 'formik';
import { PlatformType } from '../../../constants/available-platforms';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC } from 'react';

interface CarouselDetailsProps {
  carouselItem: ICarouselCreativeItemFormValuesModel;
  carouselItemIndex: number;
  platform: PlatformType;
  downloadedCarouselIndex: string | null;
  isLoading: boolean;
  isFetching: boolean;
  handleMediaLibraryClick: (index: number) => void;
  handleChange: (
    formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>,
    index: number,
    field: string,
    platform: PlatformType,
    value: string
  ) => void;
  handleClickOnDownloadIconCarousel: (propertyId: string, index: number) => void;
  formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>;
}

const CarouselCreativeItemDetails: FC<CarouselDetailsProps> = ({
  carouselItem,
  carouselItemIndex,
  platform,
  downloadedCarouselIndex,
  isLoading,
  isFetching,
  handleMediaLibraryClick,
  handleChange,
  handleClickOnDownloadIconCarousel,
  formikCtx,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          handleMediaLibraryClick(carouselItemIndex);
        }}>
        {carouselItem.creativeMedia?.preSignedUrl ? (
          <Tooltip title="Click image to open media library" placement="right">
            <img
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
              }}
              src={carouselItem.creativeMedia?.preSignedUrl || ''}
              alt={`item ${carouselItemIndex}`}
            />
          </Tooltip>
        ) : (
          <EmptyImageCard />
        )}
      </Grid>
      <CommonTextField
        sx={{ mt: '12px' }}
        name={`creativeItems[${carouselItemIndex}].propertyId`}
        label={`${t('propertyId')}`}
        value={formikCtx.values.creativeItems[carouselItemIndex]?.propertyId || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(formikCtx, carouselItemIndex, 'propertyId', platform, e.target.value)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() =>
                handleClickOnDownloadIconCarousel(
                  formikCtx.values.creativeItems[carouselItemIndex]?.propertyId || '',
                  carouselItemIndex
                )
              }
              position="end"
              sx={{ cursor: 'pointer' }}>
              {(isLoading || isFetching) && carouselItemIndex.toString() === downloadedCarouselIndex ? (
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress
                    variant="indeterminate"
                    size={30}
                    sx={{
                      color: '#000',
                      position: 'absolute',
                      top: -4,
                      left: -5,
                      zIndex: 1,
                    }}
                  />
                  <UploadFileRounded color="secondary" />
                </Box>
              ) : (
                <Tooltip title={'Download'}>
                  <DownloadIcon />
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
      <CommonTextField
        required
        sx={{ mt: 2 }}
        type="text"
        name={`creativeItems[${carouselItemIndex}].headline`}
        label={`${t('headline')} ${carouselItem.headline?.length ?? 0}/34`}
        value={formikCtx.values.creativeItems[carouselItemIndex]?.headline || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(formikCtx, carouselItemIndex, 'headline', platform, e.target.value)
        }
      />
      <CommonTextField
        required
        sx={{ mt: 2 }}
        type="text"
        name={`creativeItems[${carouselItemIndex}].caption`}
        label={`${t('caption')} ${carouselItem?.caption?.length ?? 0}/34`}
        value={formikCtx.values.creativeItems[carouselItemIndex]?.caption || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(formikCtx, carouselItemIndex, 'caption', platform, e.target.value)
        }
      />
      <CommonTextField
        required
        sx={{ mt: 2 }}
        type="text"
        name={`creativeItems[${carouselItemIndex}].description`}
        label={`${t('description')} ${carouselItem.description?.length ?? 0}/90`}
        value={formikCtx.values.creativeItems[carouselItemIndex]?.description || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(formikCtx, carouselItemIndex, 'description', platform, e.target.value)
        }
      />
      <CommonTextField
        required
        sx={{ mt: 2 }}
        type="text"
        name={`creativeItems[${carouselItemIndex}].link`}
        label={`${t('link')} ${carouselItem.link?.length ?? 0}/90`}
        value={formikCtx.values.creativeItems[carouselItemIndex]?.link || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(formikCtx, carouselItemIndex, 'link', platform, e.target.value)
        }
      />
    </Grid>
  );
};

export default CarouselCreativeItemDetails;
