import { IPasswordFormValues } from '@/admin/pages/users/create-edit/components/EditUserPasswordForm';
import { useForgetPasswordUpdateMutation } from '@/editor/services/http/users.api';
import SendOutlined from '@mui/icons-material/SendOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Formik, useFormik } from 'formik';
import { FC, useState, type JSX } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  password: yup.string().required('Password required').min(6, 'Minimum password length 6'),
  confirmPassword: yup
    .string()
    .required('Confirm password required')
    .min(6, 'Minimum password length 6')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const ForgetPassword: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams('token');
  const [forgetPasswordUpdate, { isLoading }] = useForgetPasswordUpdateMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const handleClickShowPassword = (type: string) => {
    if (type === 'pass') {
      setShowPassword(!showPassword);
      return;
    }
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (type: string) => {
    if (type === 'pass') {
      setShowPassword(!showPassword);
      return;
    }
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues: IPasswordFormValues = {
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values: IPasswordFormValues) => {
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    if (formik.touched && formik.isValid) {
      toast.promise(
        forgetPasswordUpdate({ password: password, confirmPassword: confirmPassword, token: token as string }),
        {
          success: () => {
            navigate('/sign-in');
            return t('passwordUpdatedSuccessfully');
          },
          loading: t('updating'),
          error: t('errorWhileUpdatingPassword'),
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Grid container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={6}>
          <Paper sx={{ width: '100%', minHeight: '350px' }}>
            <Grid container height={'100%'} display={'flex'}>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: '#000', display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ maxWidth: '250px', p: 1 }}>
                    <img width={'100%'} height={'auto'} src="/makeads_logo_white.png"></img>
                  </Box>
                </Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values: IPasswordFormValues) => {
                    await handleSubmit(values);
                  }}>
                  {({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                    <form noValidate onSubmit={(e) => handleSubmit(e)}>
                      <Grid container item minHeight={'300px'} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={9} mt={3}>
                          <TextField
                            id={'password'}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            required
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            label={t('password')}
                            variant="standard"
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword('pass')}
                                    onMouseDown={() => handleMouseDownPassword('pass')}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} mt={2}>
                          <TextField
                            id={'confirmPassword'}
                            sx={{ mt: 2 }}
                            name="confirmPassword"
                            label={t('confirmPassword')}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            required
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            type={showConfirmPassword ? 'text' : 'password'}
                            fullWidth
                            variant="standard"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword('confirm')}
                                    onMouseDown={() => handleMouseDownPassword('confirm')}>
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} mt={2} alignSelf={'flex-end'} justifyContent={'flex-end'} display={'flex'}>
                          <Button
                            sx={{ mb: 3 }}
                            type="submit"
                            endIcon={<SendOutlined fontSize="small" />}
                            variant="contained">
                            {t('submit')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgetPassword;
