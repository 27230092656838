import { useCallback } from 'react';
import { setCarouselPropertyExtId } from '@/editor/store/reducers/property-info.slice';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { ICarouselCreativeFormValuesModel, ICarouselCreativeItemFormValuesModel } from '../models/carousel-creative-form-values';
import { useAppDispatch } from '@/store/hooks/redux';
import { FormikContextType } from 'formik';

const useHandleCarouselDownload = (
  formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>,
  platform: string,
  updateCarouselItemField: Function,
  setUpdatedIndex: (index: number) => void
) => {

  const existingDownloadedData: ICarouselCreativeItemFormValuesModel[] = JSON.parse(
    getValueFromLs(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA) || '[]'
  );

  const dispatch = useAppDispatch();

  const handleClickOnDownloadIconCarousel = useCallback(
    (id: string, index: number | null) => {
      if (index !== null) {
        if (existingDownloadedData[index].headline !== '') {
          formikCtx.setFieldValue(`creativeItems[${index}].propertyId`, '');
          formikCtx.setFieldValue(`creativeItems[${index}].headline`, '');
          formikCtx.setFieldValue(`creativeItems[${index}].caption`, '');
          formikCtx.setFieldValue(`creativeItems[${index}].link`, '');
          formikCtx.setFieldValue(`creativeItems[${index}].description`, '');
          updateCarouselItemField(platform, index, 'propertyId', '');
          updateCarouselItemField(platform, index, 'headline', '');
          updateCarouselItemField(platform, index, 'caption', '');
          updateCarouselItemField(platform, index, 'link', '');
          updateCarouselItemField(platform, index, 'description', '');
        }
        dispatch(setCarouselPropertyExtId(id));
        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSELL_ID, id);
        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_INDEX, JSON.stringify(index));
        setUpdatedIndex(index);
      }
    },
    [dispatch, formikCtx, existingDownloadedData, platform, updateCarouselItemField, setUpdatedIndex]
  );

  return handleClickOnDownloadIconCarousel;
};

export default useHandleCarouselDownload;