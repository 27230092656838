import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import BarChart from '@/statistics/components/BarChart';
import { IBaseChartsProps } from '@/statistics/components/chart-props.moldel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import NoData from '../../../shared/NoData';
import Visibility from '@mui/icons-material/Visibility';
import AdsClick from '@mui/icons-material/AdsClick';
import { useTranslation } from 'react-i18next';

export interface ISnapchatDataProps {
  advertisement: IFullAdvertisementModel;
}

const SnapchatData: FC<ISnapchatDataProps> = (props: ISnapchatDataProps): JSX.Element => {
  const {
    advertisement: {
      advertisementStats: { snapChatStats },
    },
  } = props;

  const { t } = useTranslation();

  const prepareDataSets = (): IBaseChartsProps | null => {
    return {
      labels: [t('impressions'), t('swipes')],
      dataSets: [
        {
          label: 'Snapchat',
          data: [snapChatStats?.impressions ?? 0, snapChatStats?.swipes ?? 0],
        },
      ],
    };
  };

  return (
    <div>
      {snapChatStats ? (
        <Grid container item mt={2}>
          <Grid
            item
            xs={12}
            md={2}
            my={{ xs: 2, md: 8 }}
            display={'flex'}
            justifyContent={'space-around'}
            flexDirection={{ md: 'column' }}>
            <Box display={'flex'}>
              <Visibility></Visibility>
              <Typography variant="h5">{snapChatStats.impressions}</Typography>
            </Box>
            <Box display={'flex'}>
              <AdsClick></AdsClick>
              <Typography variant="h5">{snapChatStats.swipes}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <BarChart
              chartType="single"
              labels={prepareDataSets()?.labels ?? []}
              dataSets={prepareDataSets()?.dataSets ?? []}
            />
          </Grid>
        </Grid>
      ) : (
        <NoData title="Instagram" />
      )}
    </div>
  );
};

export default SnapchatData;
