import { setCompanyId, setPropertyExtId } from '@/editor/store/reducers/property-info.slice';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FC, useEffect, type JSX } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteCredentials, setCredentials } from '@/auth/store/reducers/auth.slice';
import Loader from '../../shared/components/Loader';
import {
  clearLS,
  deleteKeyValuePairFromLS,
  refreshLSAfterAdCreation,
  setCredentialsToLS,
  setKeyValuePairToLS,
} from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import { useConnectMutation } from '../services/http/connect-user.http.service';
import { useTranslation } from 'react-i18next';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';

const RetailDataUserConnection: FC = (): JSX.Element => {
  const { companyId, propertyId } = useParams();
  const [connectUser, { isLoading, isError }] = useConnectMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const redirectToLogin = () => {
    navigate('/sign-in');
  };

  useEffect(() => {
    const makeCallAsync = () => {
      const queryParams = new URLSearchParams(location.search);
      const orderId = queryParams.get('orderId');
      const provider = queryParams.get('provider');

      connectUser({
        companyExternalId: companyId!,
        propertyId: propertyId!,
        orderId: orderId!,
        provider: provider!,
      })
        .unwrap()
        .then((result) => {
          refreshLSAfterAdCreation();

          setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID, propertyId!);
          setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, '0');
          setKeyValuePairToLS(EDITOR_LS_CONSTANTS.COMPANY_ID, companyId!);
          deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS);

          setCredentialsToLS(result);
          dispatch(setCredentials(result));
          dispatch(setPropertyExtId(propertyId!));
          dispatch(setCompanyId(companyId!));
          navigate('/editor/advertise/add');
        })
        .catch((e) => {
          console.error(e);
          clearLS();
          dispatch(deleteCredentials());
        });
    };

    makeCallAsync();
  }, []);

  return (
    <div>
      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <Loader absolute={0} />
          <Typography pt={5} variant={'h4'}>
            {t('connectionMessage')}
          </Typography>
        </Box>
      )}
      {isError && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography p={3} variant={'h4'}>
            {t('integrationError')}
          </Typography>
          <Button variant="contained" color="secondary" onClick={redirectToLogin}>
            {t('signIn')}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default RetailDataUserConnection;
