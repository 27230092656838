import ListItem from '@mui/material/ListItem';
import { FC, type JSX } from 'react';
import DeltaSummaryStats from '../../../../../../shared/components/advertisement-info/DeltaSummaryStats';
import { IFullAdvertisementModel } from '../../../../../../shared/models/advertisements/full-advertisement-model';
import MetaSummaryStats from './MetaSummaryStats';
import SnapChatSummaryStats from './SnapChatSummaryStats';
import BidtheatreSummaryStats from '@/shared/components/advertisement-info/BidtheatreSummaryStats';
import BoardsSummaryStats from '@/shared/components/advertisement-info/BoardsSummaryStats';

interface ISummaryStatsProps {
  advertisement: IFullAdvertisementModel;
}

const SummaryStats: FC<ISummaryStatsProps> = (props: ISummaryStatsProps): JSX.Element => {
  const {
    advertisement: { advertisementStats },
  } = props;

  return (
    <div>
      {(advertisementStats?.deltaStats ||
        advertisementStats?.metaStats ||
        advertisementStats?.snapChatStats ||
        advertisementStats?.bidtheatreStats ||
        advertisementStats?.doohStats) && (
        <ListItem>
          {advertisementStats?.metaStats && <MetaSummaryStats metaStats={advertisementStats?.metaStats} />}
          {advertisementStats?.deltaStats && <DeltaSummaryStats deltaStats={advertisementStats.deltaStats} />}
          {advertisementStats?.bidtheatreStats && (
            <BidtheatreSummaryStats bidtheatreStats={advertisementStats?.bidtheatreStats} />
          )}
          {advertisementStats?.doohStats && <BoardsSummaryStats doohStats={advertisementStats?.doohStats} />}
          {advertisementStats?.snapChatStats && (
            <SnapChatSummaryStats snapchatStats={advertisementStats?.snapChatStats} />
          )}
        </ListItem>
      )}
    </div>
  );
};

export default SummaryStats;
