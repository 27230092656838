import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ImageIcon from '@mui/icons-material/Image';
import type { FC, JSX } from 'react';

// TODO fix 'any' type
const EmptyImageCard: FC<any> = (props: any): JSX.Element => {
  return (
    <Card {...props} variant="outlined" sx={{ width: '100%', minHeight: '300px', height: '100%' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
        <ImageIcon fontSize="large" sx={{ color: 'text.tertiary' }} />
      </Box>
    </Card>
  );
};

export default EmptyImageCard;
