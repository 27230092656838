import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, useState, type JSX } from 'react';
import VideoList from './video-list/VideoList';
import MoovlyCreatorTab from '@/editor/components/media-library/videos/video-creator/MoovlyCreatorTab';
import { useGetUserVideosQuery } from '@/editor/services/http/media-library.api';
import { useTranslation } from 'react-i18next';
import NavButtons from './components/NavButtons';

const VideoLibrary: FC = () => {
  const [view, setView] = useState<string>('list');
  const { t } = useTranslation();

  const {
    data: videoResponse,
    isLoading: isLoadingVideoList,
    isFetching: isFetchingVideoList,
    isUninitialized,
    isSuccess,
    isError,
    refetch: refetchVideos,
  } = useGetUserVideosQuery();

  const renderSwitch = (): JSX.Element => {
    switch (view) {
      default:
        return <div></div>;
      case 'list':
        return (
          <VideoList
            isFetchingVideoList={isFetchingVideoList}
            isLoadingVideoList={isLoadingVideoList}
            isUninitialized={isUninitialized}
            videos={videoResponse!}
            setView={setView}
            refetch={refetchVideos}
          />
        );
      case 'creator':
        return <MoovlyCreatorTab setView={setView} />;
    }
  };

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000', pb: 1 }}>
        <Toolbar>
          <Grid container spacing={1} display={'flex'} alignItems={'center'}>
            <Grid item xs={12} sm={3}>
              <Typography variant="h5">Video Maker</Typography>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}></Grid>
            <NavButtons isSuccess={isSuccess} isError={isError} view={view} setView={setView} />
          </Grid>
        </Toolbar>
      </AppBar>
      {!isError && (
        <Grid container spacing={2} p={2} display={'grid'}>
          <Grid item md={12}>
            {renderSwitch()}
          </Grid>
        </Grid>
      )}
      {isError && (
        <Grid container display={'flex'} justifyContent={'center'}>
          <Grid item xs={6} mt={5}>
            <Paper sx={{ width: '100%', p: 3, textAlign: 'center' }}>
              <Typography variant="h4">{t('videoMakerError')}</Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default VideoLibrary;
