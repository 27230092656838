import { FC, SyntheticEvent, type JSX } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IMoovlyVariablesApiResponseModel } from '../../../../../shared/models/moovly/moovly-variables-api-response.model';

interface IImgVariableRepresentorProps {
  el: IMoovlyVariablesApiResponseModel;
  imgKey: number;
  setSelectedImgIndex: (key: number) => void;
}

const ImgVariableRepresentor: FC<IImgVariableRepresentorProps> = (props: IImgVariableRepresentorProps): JSX.Element => {
  const { el, imgKey, setSelectedImgIndex } = props;
  return (
    <Box>
      <Box>
        <Typography>{el.name} </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '200px',
          overflow: 'hidden',
          position: 'relative',
        }}>
        <img
          onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
            return (e.currentTarget.src = '/avatar.png');
          }}
          onClick={() => setSelectedImgIndex(imgKey)}
          src={el.default}
          alt={el.default}
          width="100%"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            left: 0,
            borderRadius: '5px',
          }}
        />
      </Box>
    </Box>
  );
};

export default ImgVariableRepresentor;
