import { MRT_ColumnDef } from 'material-react-table';
import { IOrderOverviewModel } from '@/shared/models/orders/order-overview.model';
import { DatePicker } from '@mui/x-date-pickers';
import CommonTextField from '@/shared/components/CommonTextField';
import availablePlatforms from '@/editor/shared/constants/available-platforms';
import moment from 'moment';

const orderOverviewTableColumns: MRT_ColumnDef<IOrderOverviewModel>[] = [
  { accessorKey: 'propertyAddress', header: 'propertyAddress', id: 'propertyAddress' },
  { accessorKey: 'status', header: 'status', id: 'status' },
  {
    accessorKey: 'platforms',
    id: 'platforms',
    accessorFn(originalRow) {
      const icons: any[] = [];
      originalRow.platforms.forEach((platform) => {
        const Icon = availablePlatforms.find((el) => el.id === platform)?.icon;
        icons.push(<Icon fontSize={'small'}></Icon>);
      });
      return icons;
    },
    header: 'platforms',
  },
  { accessorKey: 'propertyExternalId', header: 'externalId', id: 'externalId' },
  { accessorKey: 'officeTitle', header: 'officeTitle', id: 'officeTitle' },
  { accessorKey: 'userFullName', header: 'userFullName', id: 'userFullName' },
  {
    accessorKey: 'budgetTitle',
    header: 'budget',
    id: 'budget',
    accessorFn: (row: IOrderOverviewModel) => row.budgetTitle || row.productTitle,
  },
  { accessorKey: 'statistics.totalClicks', header: 'totalClicks', id: 'totalClicks' },
  { accessorKey: 'statistics.totalImpressions', header: 'totalImpressions', id: 'totalImpressions' },
  {
    accessorKey: 'createdAt',
    header: 'createdAt',
    id: 'createdAt',
    Filter: ({ column }) => {
      return (
        <DatePicker
          onChange={(newValue) => {
            column.setFilterValue(newValue);
          }}
          // renderInput={(params) => <CommonTextField {...params} fullWidth variant="standard" error={false} />}
          value={moment(column.getFilterValue() as string)}
        />
      );
    },
  },
];

export default orderOverviewTableColumns;
