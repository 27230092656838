import { useGetOrderWithDetailsByIdQuery } from '@/editor/services/http/orders.api';
import Loader from '@/shared/components/Loader';
import usePrepareCreativeOrderDetailsAdvertisements from '@/shared/hooks/order-details-ads.hook';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import ExternalCompanyIdEnum from '../../shared/constants/external-company-id.enum';
import EieTemplate from './eie/EieTemplate';
import SormeglerenTemplate from '@/statistics/templates/sormegleren/SormeglerenTemplate';
import DefaultTemplate from './default/DefaultTemplate';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, type JSX } from 'react';

const AuthenticatedStatisticsTemplateResolver: FC = (): JSX.Element => {
  let { id } = useParams();
  const { i18n } = useTranslation();

  const { data: orderDetailsData, isLoading, isError } = useGetOrderWithDetailsByIdQuery({ id: id! });
  const { ready } = usePrepareCreativeOrderDetailsAdvertisements(orderDetailsData);

  useEffect(() => {
    i18n.changeLanguage(orderDetailsData?.userDetails.locale, (err) => {
      if (err) return console.log(err);
    });
  }, [orderDetailsData]);

  function renderSwitch(externalOfficeId: string | number) {
    switch (externalOfficeId) {
      case ExternalCompanyIdEnum.EIE:
      case ExternalCompanyIdEnum.EIE_VISMA:
        return <EieTemplate order={orderDetailsData!} />;
      case ExternalCompanyIdEnum.SORMEGLEREN:
        return <SormeglerenTemplate order={orderDetailsData!} />;
      default:
        return <DefaultTemplate order={orderDetailsData!}></DefaultTemplate>;
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {isError ? (
            <Typography>ERROR</Typography>
          ) : (
            <div>
              {ready ? (
                <Box component="div">
                  <AppBar position="static">
                    <Container maxWidth="xl"></Container>
                  </AppBar>
                  {renderSwitch(orderDetailsData?.companyExternalId!)}
                </Box>
              ) : (
                <Loader />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthenticatedStatisticsTemplateResolver;
