import { useCallback } from 'react';
import { deleteKeyValuePairFromLS, getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { ICarouselCreativeFormValuesModel } from '../models/carousel-creative-form-values';
import { useAppDispatch } from '@/store/hooks/redux';
import { removeCarouselItem } from '@/editor/store/reducers/creative-step.slice';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { FormikContextType } from 'formik';

const useHandleCarouselDelete = (
  formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>,
  platform: PlatformType,
  setUpdatedIndex: (index: number) => void
) => {
  const dispatch = useAppDispatch();

  const onDeleteItemClick = useCallback(
    (index: number) => {
      let currentValues = (formikCtx.values! as ICarouselCreativeFormValuesModel).creativeItems;
      dispatch(removeCarouselItem({ platform, index }));

      const updatedValues = currentValues.filter((_, i) => i !== index);
      formikCtx.setFieldValue('creativeItems', updatedValues);

      const propertyArray = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_ARRAY) || '[]');
      const updatedPropertyArray = propertyArray.filter((_: number, i: number) => i !== index);
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_ARRAY, JSON.stringify(updatedPropertyArray));

      if (updatedValues.length === 0) {
        deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_INDEX);
        deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_ARRAY);
      }
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA, JSON.stringify(updatedValues));
    },
    [dispatch, formikCtx, platform, setUpdatedIndex]
  );

  return onDeleteItemClick;
};

export default useHandleCarouselDelete;