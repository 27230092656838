import { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import SnapChatPreviewSummary from '@/shared/components/platform-previews/previews/snapchat/SnapChatPreviewSummary';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import SectionHeader from '../SectionHeader';
import SnapchatData from './SnapchatData';
import { useTranslation } from 'react-i18next';

export interface IEieSnapchatSectionProps {
  advertisement: IFullAdvertisementModel;
}

const EieSnapchatSection: FC<IEieSnapchatSectionProps> = (props: IEieSnapchatSectionProps): JSX.Element => {
  const {
    advertisement: {
      advertisementStats: { snapChatStats },
      creative,
    },
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <SectionHeader
          platform="Snapchat"
          totalClicks={snapChatStats?.swipes ?? 0}
          totalImpressions={snapChatStats?.impressions ?? 0}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4}>
              <SnapChatPreviewSummary selectedType={creative.creativeType as CreativeType} />
            </Grid>
            <Divider sx={{ mt: 6, display: { md: 'none' } }} />
            {props.advertisement.advertisementStats.snapChatStats?.impressions! > 50 ? (
              <Grid item xs={12} md={7} mx={'auto'}>
                <SnapchatData advertisement={props.advertisement} />
              </Grid>
            ) : (
              <Grid item xs={12} m={2} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography m={2} variant="h2">
                  {t('adWithNoStats')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EieSnapchatSection;
