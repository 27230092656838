import { getValueFromLs } from '@/shared/services/localstorage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDITOR_LS_CONSTANTS } from '../../shared/constants/editor-LS.constants';

interface IPropertyInfoState {
  propertyExtId: string | null;
  propertyCarouselExtId: string | null;
  propertyEstateType: string;
  propertyInternalId: string;
  companyId: string;
  propertyIsSold?: boolean;
}

const getFromStorage = (): IPropertyInfoState => ({
  propertyExtId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID),
  propertyCarouselExtId: getValueFromLs('propertyCarouselId'),
  propertyEstateType: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_ESTATE_TYPE) ?? '',
  propertyInternalId: '',
  companyId: getValueFromLs(EDITOR_LS_CONSTANTS.COMPANY_ID) ?? '',
  propertyIsSold: false,
});

const initialState: IPropertyInfoState = getFromStorage();

const propertyInfoSlice = createSlice({
  name: 'property-info-state',
  initialState,
  reducers: {
    setPropertyExtId(state: IPropertyInfoState, action: PayloadAction<string>) {
      state.propertyExtId = action.payload;
    },
    setCarouselPropertyExtId(state: IPropertyInfoState, action: PayloadAction<string>) {
      state.propertyCarouselExtId = action.payload;
    },
    setPropertEstateType(state: IPropertyInfoState, action: PayloadAction<string>) {
      state.propertyEstateType = action.payload;
    },
    setPropertyInternalId(state: IPropertyInfoState, action: PayloadAction<string>) {
      state.propertyInternalId = action.payload;
    },
    setCompanyId(state: IPropertyInfoState, action: PayloadAction<string>) {
      state.companyId = action.payload;
    },
    setPropertyIsSold(state: IPropertyInfoState, action: PayloadAction<boolean>) {
      state.propertyIsSold = action.payload;
    },
  },
});

export const { setPropertyExtId, setPropertEstateType, setPropertyInternalId, setCompanyId, setPropertyIsSold, setCarouselPropertyExtId } = propertyInfoSlice.actions;

export default propertyInfoSlice.reducer;
