import { FC, MouseEvent, type JSX } from 'react';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { IMediaLibraryVideoModel } from '../../../../shared/models/media-library/media-library-video.model';
import { AvailablePlatformsEnum, PlatformType } from '../../../shared/constants/available-platforms';
import CreativeTypesEnum from '../../../shared/constants/creatives-types.enum';
import { useAppSelector } from '../../../../store/hooks/redux';

interface IVideoListItemProps {
  el: IMediaLibraryVideoModel;
  itemIndex: number;
  handleClickListItem: (e: MouseEvent<HTMLElement>, video: IMediaLibraryVideoModel) => void;
}

const VideoListItem: FC<IVideoListItemProps> = (props: IVideoListItemProps): JSX.Element => {
  const { el, itemIndex, handleClickListItem } = props;
  const creativeState = useAppSelector((state) => state.creativeStepSliceReducer);

  const isVideoSelected = (video: IMediaLibraryVideoModel) => {
    const platformKeys = Object.values(AvailablePlatformsEnum) as PlatformType[];
    let found = false;
    for (let i = 0; i < platformKeys.length; i++) {
      if (found) break;

      const searchResult =
        creativeState.creativeFormsState[platformKeys[i]][CreativeTypesEnum.VIDEO]?.media?.id === video.id;

      if (searchResult) {
        found = true;
        break;
      }
    }

    return found;
  };

  return (
    <ListItem
      component={'li'}
      dense={false}
      divider={true}
      key={itemIndex}
      sx={{
        ':hover': { cursor: 'pointer' },
      }}
      // selected={isVideoSelected(el)}
      onClick={(e) => handleClickListItem(e, el)}>
      <ListItemAvatar>
        <video
          preload="metadata"
          style={{
            width: '120px',
            borderRadius: '10px',
            border: isVideoSelected(el) ? '3px solid #7743DB' : '3px solid #BFACE2',
          }}>
          <source src={el?.preSignedUrl + '#t=0.1'} type="video/mp4" />
        </video>
      </ListItemAvatar>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="subtitle2">{el.fileName.split('/')[1]}</Typography>
      </Box>
    </ListItem>
  );
};

export default VideoListItem;
