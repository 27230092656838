import React, { FC, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { ICarouselCreativeFormValuesModel } from '../models/carousel-creative-form-values';
import { IDynamicCreativeFormValuesModel } from '../models/dynamic-creative-form-values.model';
import { IPlatformCreativeFormResolverProps } from '../models/form-resolver-props.interface';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import BasicSnapChatPreview from '@/shared/components/platform-previews/previews/snapchat/BasicSnapChatPreview';
import SingleSnapChatPreview from '@/shared/components/platform-previews/previews/snapchat/SingleSnapChatPreview';
import BasicSingleForm from '@/editor/shared/components/forms/creatives/BasicSingleForm';
import DynamicSnapChatPreview from '@/shared/components/platform-previews/previews/snapchat/DynamicSnapChatPreview';
import BasicDynamicForm from '@/editor/shared/components/forms/creatives/BasicDynamicForm';
import CarouselCreativeItemsList from '@/editor/shared/components/forms/creatives/CarouselCreativeItemsList';
import CarouselSnapChatPreview from '@/shared/components/platform-previews/previews/snapchat/CarouselSnapChatPreview';
import BasicCarouselForm from '@/editor/shared/components/forms/creatives/BasicCarouselForm';
import {
  carouselCreativeSchema,
  dynamicCreativeSchema,
  singleCreativeSchema,
} from '@/editor/shared/components/forms/creatives/shared/basic-validation-schema';

const SnapChatFormsResolver: FC<IPlatformCreativeFormResolverProps> = ({ creativeType, updating }): JSX.Element => {
  const initialValuesFromStore = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);

  const renderSwitch = (): JSX.Element => {
    switch (creativeType) {
      case CreativeTypesEnum.SINGLE:
        return (
          <BasicSingleForm
            basePreviews={[BasicSnapChatPreview]}
            concretePreviews={[SingleSnapChatPreview]}
            fieldsRequired={false}
            updating={updating}
            initialValues={
              initialValuesFromStore[AvailablePlatformsEnum.SNAPCHAT][
                CreativeTypesEnum.SINGLE
              ]! satisfies ISingleCreativeFormValuesModel
            }
            platform={AvailablePlatformsEnum.SNAPCHAT}
            validationSchema={singleCreativeSchema}
          />
        );
      case CreativeTypesEnum.DYNAMIC:
        return (
          <BasicDynamicForm
            basePreviews={[BasicSnapChatPreview]}
            concretePreviews={[DynamicSnapChatPreview]}
            fieldsRequired={true}
            platform={AvailablePlatformsEnum.SNAPCHAT}
            initialValues={
              initialValuesFromStore[AvailablePlatformsEnum.SNAPCHAT][
                CreativeTypesEnum.DYNAMIC
              ]! satisfies IDynamicCreativeFormValuesModel
            }
            validationSchema={dynamicCreativeSchema}
            updating={updating}
          />
        );
      case CreativeTypesEnum.CAROUSEL:
        return (
          <BasicCarouselForm
            updating={updating}
            basePreview={[BasicSnapChatPreview]}
            carouselItems={CarouselCreativeItemsList}
            concretePreview={[CarouselSnapChatPreview]}
            fieldsRequired={true}
            platform={AvailablePlatformsEnum.SNAPCHAT}
            initialValues={
              initialValuesFromStore[AvailablePlatformsEnum.SNAPCHAT][
                CreativeTypesEnum.CAROUSEL
              ]! satisfies ICarouselCreativeFormValuesModel
            }
            validationSchema={carouselCreativeSchema}
          />
        );
      default:
        return <div>Invalid creative type</div>;
    }
  };

  return <div>{renderSwitch()}</div>;
};
export default SnapChatFormsResolver;
