import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import List from '@mui/material/List';
import { FC, type JSX } from 'react';
import StatisticsSharedToken from './StatisticsSharedToken';
import OrderStatus from './OrderStatus';

export interface IOrderInfoProps {
  order: ISingleOrderWithDetailsModel;
}

const OrderInfo: FC<IOrderInfoProps> = (props: IOrderInfoProps): JSX.Element => {
  const { order } = props;

  return (
    <List>
      <StatisticsSharedToken order={order} />
      <OrderStatus order={order} />
    </List>
  );
};

export default OrderInfo;
