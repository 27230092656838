import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, type JSX } from 'react';

const AuthLayout = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('sign-in');
    }

    return () => {};
  }, []);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthLayout;
