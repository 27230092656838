import { useGetOrderWithDetailsByIdSharedStatisticsQuery } from '@/editor/services/http/orders.api';
import Loader from '@/shared/components/Loader';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import ExternalCompanyIdEnum from '../../shared/constants/external-company-id.enum';
import usePrepareCreativeOrderDetailsAdvertisements from '@/shared/hooks/order-details-ads.hook';
import EieTemplate from '@/statistics/templates/eie/EieTemplate';
import SormeglerenTemplate from '@/statistics/templates/sormegleren/SormeglerenTemplate';
import DefaultTemplate from './default/DefaultTemplate';

import type { FC, JSX } from 'react';

const SharedStatisticsTemplateResolver: FC = (): JSX.Element => {
  let { id, token } = useParams();

  const {
    data: orderDetailsData,
    isLoading,
    isError,
  } = useGetOrderWithDetailsByIdSharedStatisticsQuery({ id: id!, token: token ?? '' });
  usePrepareCreativeOrderDetailsAdvertisements(orderDetailsData);

  function renderSwitch(externalOfficeId: string | number) {
    switch (externalOfficeId) {
      case ExternalCompanyIdEnum.EIE:
      case ExternalCompanyIdEnum.EIE_VISMA:
        return <EieTemplate order={orderDetailsData!} />;
      case ExternalCompanyIdEnum.SORMEGLEREN:
        return <SormeglerenTemplate order={orderDetailsData!} />;
      default:
        return <DefaultTemplate order={orderDetailsData!}></DefaultTemplate>;
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {isError ? (
            <Typography>ERROR</Typography>
          ) : (
            <Box component="div" sx={{ height: '100%', width: '100%' }}>
              <AppBar position="static">
                <Container maxWidth="xl"></Container>
              </AppBar>

              {renderSwitch(orderDetailsData?.companyExternalId ?? '')}
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default SharedStatisticsTemplateResolver;
