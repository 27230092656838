import { lazy } from 'react';
import { IRoute } from '../../shared/models/route.model';

export const ROUTE_PRODUCTS_DECLARATIONS = Object.freeze({
  AdminProductsPage: 'products',
  AdminProductsNewPage: 'products/new',
  AdminProductsEditPage: 'products/:id/edit',
});

const ProductsDataTable = lazy(() => import('../pages/products/ProductTablePage'));
const CreateEditProductPage = lazy(() => import('../pages/products/CreateEditProductPage'));

export const AdminProductsRoutes: IRoute[] = [
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsPage,
    component: ProductsDataTable,
  },
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsNewPage,
    component: CreateEditProductPage,
  },
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsEditPage,
    component: CreateEditProductPage,
  },
];
