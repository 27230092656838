import { IRoute } from '../../shared/models/route.model';
import { lazy } from 'react';

export const ROUTE_USERS_DECLARATIONS = Object.freeze({
  AdminUsersPage: 'users',
  AdminUsersNewPage: 'users/new',
  AdminUsersEditPage: 'users/:userId/edit',
  AdminUsersDetailsPage: 'users/:userId/user-profile',
});

const UsersTableLazy = lazy(() => import('../pages/users/UsersTablePage'));
const CreateEditUserLazy = lazy(() => import('../pages/users/create-edit/CreateEditUser'));
const AdminUserProfilePageLazy = lazy(() => import('../pages/users/user-profile/AdminUserProfilePage'));

export const AdminUsersRoutes: IRoute[] = [
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersPage,
    component: UsersTableLazy,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersNewPage,
    component: CreateEditUserLazy,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersEditPage,
    component: CreateEditUserLazy,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersDetailsPage,
    component: AdminUserProfilePageLazy,
  },
];
