import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import Grid from '@mui/material/Grid';
import { FC, type JSX } from 'react';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import CampaignMetaBarCharts from './components/CampaignMetaBarCharts';
import EieHeader from './components/EieHeader';
import EieTimeLineProgress from './components/EieTimeLineProgress';
import EieMetaSection from './components/meta-section/EieMetaSection';
import EieSnapchatSection from './components/snapchat-section/EieSnapchatSection';
import EieBidtheatreSection from './components/bidtheatre-section/EieBidtheatreSection';
import EieBoardsSection from './components/boards-section/EieBoardsSection';
import EieDeltaSection from './components/delta-section/EieDeltaSection';
import HjemSection from './components/simple-section/HjemSection';
import FinnSection from './components/simple-section/FinnSection';

interface IEieTemplateProps {
  order: ISingleOrderWithDetailsModel;
}

const EieTemplate: FC<IEieTemplateProps> = ({ order }): JSX.Element => {
  const findAd = (platform: PlatformType): IFullAdvertisementModel | null => {
    return order.advertisements.find((el) => el.platform === platform) ?? null;
  };

  const sumValues = (array: any[], param: string): number => {
    return array?.reduce((sum, el) => sum + +el[param], 0);
  };

  return (
    <Grid container>
      <EieHeader order={order} />
      <Grid spacing={2} p={2} container item>
        <Grid item xs={12}>
          <EieTimeLineProgress order={order} />
        </Grid>
        <Grid item xs={12}>
          <CampaignMetaBarCharts order={order} />
        </Grid>

        {findAd('meta') && (
          <Grid item xs={12}>
            <EieMetaSection advertisement={findAd('meta')!}></EieMetaSection>
            {/*<EieInstagramSection advertisement={findAd('meta')!} />*/}
          </Grid>
        )}
        {findAd('delta') && (
          <Grid item xs={12}>
            <EieDeltaSection advertisement={findAd('delta')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('bidtheatre') && (
          <Grid item xs={12}>
            <EieBidtheatreSection advertisement={findAd('bidtheatre')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('boards') && (
          <Grid item xs={12}>
            <EieBoardsSection advertisement={findAd('boards')!} extCompanyId={order.companyExternalId} />
          </Grid>
        )}
        {findAd('snapchat') && <EieSnapchatSection advertisement={findAd('snapchat')!} />}
        <Grid item xs={12} md={6}>
          <HjemSection
            platform={'Hjem.no'}
            favorites={sumValues(order.propertyHjemStats, 'favorites')}
            clicks={sumValues(order.propertyHjemStats, 'page_views')}
            mails={sumValues(order.propertyHjemStats, 'emails')}
            views={sumValues(order.propertyHjemStats, 'views')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FinnSection
            platform={'Finn.no'}
            clicks={order.propertyFinnStats.saved}
            mails={order.propertyFinnStats.mails}
            views={order.propertyFinnStats.views}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EieTemplate;
