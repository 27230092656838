import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

const NoData: FC<{ title: string }> = (props): JSX.Element => {
  const { title } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Card sx={{ p: 5, mt: '3rem' }}>
        <CardHeader title={title} />
        <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography color="secondary" variant="h3">
            {t('adWithNoStats')}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoData;
