import { useNavigate } from 'react-router-dom';
import { IGuardPropsModel } from '../../shared/models/guard-props.model';
import { useAppSelector } from '../../store/hooks/redux';
import {
  SymfonyRolesEnum,
  checkRoleFromAccessTokenWithCb,
  checkTokenPresented,
  setupAccessTokenFromLS,
} from '../../auth/services/accesstoken.handler';
import { FC, useEffect, type JSX } from 'react';

const UserGuard: FC<IGuardPropsModel> = ({ children }): JSX.Element => {
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const checkTokenRole = (token: string): void => {
    checkRoleFromAccessTokenWithCb(token, SymfonyRolesEnum.USER, () => navigate('/sign-in'));
  };

  useEffect(() => {
    let isUserSubscription = true;
    if (isUserSubscription) {
      if (checkTokenPresented(accessToken)) {
        checkTokenRole(accessToken!);
        return;
      }

      let credentials = setupAccessTokenFromLS();
      if (credentials) {
        checkTokenRole(credentials.accessToken);
        return;
      }
    }

    return () => {
      isUserSubscription = false;
    };
  }, [accessToken]);

  return children;
};

export default UserGuard;
