import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useMemo, type JSX } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import usePrepareCreativeOrderDetailsAdvertisements from '../../../../shared/hooks/order-details-ads.hook';
import { useGetAllBudgetsQuery } from '../../../services/http/budgets.api';
import { useGetOrderWithDetailsByIdQuery } from '../../../services/http/orders.api';
import AdvertisementInfo from './components/AdvertisementInfos/AdvertisementInfo';
import FullAdvertisementWrapper from './components/FullAdvertisementWrapper';
import OrderInfo from './components/OrderInfos/OrderInfo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store/hooks/redux';
import { setPropertyIsSold } from '@/editor/store/reducers/property-info.slice';

export type PossibleView = 'creatives' | 'ad settings';

const OrderDetailsPage: FC = (): JSX.Element => {
  const { id } = useParams<{
    id: string;
  }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { data: orderData, isLoading: orderGetDataLoading } = useGetOrderWithDetailsByIdQuery({ id: id! });
  const { data: budgets, isLoading: budgetsLoading, isFetching: budgetsFetching } = useGetAllBudgetsQuery();

  const { platformsOfOrder, activePlatform } = usePrepareCreativeOrderDetailsAdvertisements(orderData);
  const advertisements = useMemo(() => orderData?.advertisements, [orderData, activePlatform]);
  const bidtheatreCreative = advertisements?.find((ad) => ad.platform === 'bidtheatre')?.creative;
  const bidtheatreCreativeId = bidtheatreCreative ? bidtheatreCreative.id : null;
  const boardsCreative = advertisements?.find((ad) => ad.platform === 'boards')?.creative;
  const boardsCreativeId = boardsCreative ? boardsCreative.id : null;
  // const activeBudget = useMemo(
  //   () => budgets?.find((el) => el.id === orderData?.budgetId ),
  //   [budgets, orderData?.budgetId]
  // );
  const activeBudget = orderData?.budgetId;

  useEffect(() => {
    if (orderData?.propertyIsSold !== undefined) {
      dispatch(setPropertyIsSold(orderData?.propertyIsSold!));
    }
  }, [orderData]);

  return (
    <div>
      {orderGetDataLoading || budgetsLoading || budgetsFetching ? (
        <Loader />
      ) : (
        <Grid container p={3}>
          <Typography color="secondary" variant="h4" sx={{ mb: '10px' }}>
            {orderData?.propertyAddress}
          </Typography>
          {advertisements ? (
            <Grid item xs={12}>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{t('orderDetails')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderInfo order={orderData!} />
                </AccordionDetails>
              </Accordion>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography>{t('adDetails')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {advertisements.map((el) => {
                    return <AdvertisementInfo key={el.platform} advertisement={el} />;
                  })}
                </AccordionDetails>
              </Accordion>
              {activePlatform && advertisements ? (
                <FullAdvertisementWrapper
                  budgets={budgets!}
                  advertisements={advertisements}
                  activeBudget={activeBudget!}
                  activePlatforms={platformsOfOrder}
                  bidtheatreCreativeId={bidtheatreCreativeId}
                  boardsCreativeId={boardsCreativeId}
                />
              ) : (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h5">Error</Typography>
                </Box>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h5">{t('noAdData')}</Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default OrderDetailsPage;
