import { MRT_Row } from 'material-react-table';
import { IOrderOverviewModel } from '@/shared/models/orders/order-overview.model';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { FC, type JSX } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAdminOrderOverviewActionButtonsProps {
  row: MRT_Row<Partial<IOrderOverviewModel> | any>;
}

const AdminOrderOverviewActionButtons: FC<IAdminOrderOverviewActionButtonsProps> = (
  props: IAdminOrderOverviewActionButtonsProps
): JSX.Element => {
  const { row } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Tooltip title="Statistics">
        <IconButton onClick={() => navigate(`/statistics/orders/${row.original.id}`)}>
          <StackedLineChartIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
export default AdminOrderOverviewActionButtons;
