import Box from '@mui/material/Box';
import { FC, type JSX } from 'react';
import MediaLibraryVideos from './MediaLibraryVideos';

const MediaLibraryVideosWrapper: FC = (): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MediaLibraryVideos />
      </Box>
    </Box>
  );
};

export default MediaLibraryVideosWrapper;
