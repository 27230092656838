import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { useCallback, type JSX, useEffect, useState, FC } from 'react';
import CommonButton from '../../../../../shared/components/CommonButton';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/redux';
import { updateFieldOfCarouselItem } from '../../../../store/reducers/creative-step.slice';
import { PlatformType } from '../../../constants/available-platforms';
import {
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from './models/carousel-creative-form-values';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import toast from 'react-hot-toast';
import { useGetOrderWithDetailsByIdQuery } from '@/editor/services/http/orders.api';
import { useParams } from 'react-router-dom';
import useHandleCarouselDelete from './hooks/use-carousel-delete-hook';
import useHandleCarouselDownload from './hooks/use-carousel-download-hook';
import useHandleCarouselChange from './hooks/use-carousel-change-hook';
import useHandleCarouselMedia from './hooks/use-carousel-media-hook';
import CarouselCreativeItemDetails from './CarouselCreativeItemDetails';
import CarouselCreativeItemHeader from './CarouselCreativeItemHeader';
import useRegisterCarouselProperty, { useRegisterPropertyEffect } from './hooks/use-carousel-register-property-hook';
import { handleError, syncDownloadedData, updateFormikValues } from './CarouselCreativeItemComponents';

export interface ICarouselCreativeItemProps {
  carouselItem: ICarouselCreativeItemFormValuesModel;
  carouselItemIndex: number;
  platform: PlatformType;
  openMediaLibrary: (index: number) => void;
}

const CarouselCreativeItem: FC<ICarouselCreativeItemProps> = ({
  platform,
  carouselItem,
  carouselItemIndex,
  openMediaLibrary,
}): JSX.Element => {
  const propertyCarouselExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyCarouselExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);

  const { currentData, isError, isLoading, isFetching } = useGetRetailPropertyDataQuery(
    { propertyId: propertyCarouselExtId ?? '', companyId: companyId },
    { skip: !propertyCarouselExtId }
  );

  const { id } = useParams<{ id: string }>();
  const { data: orderData } = useGetOrderWithDetailsByIdQuery({ id: id! }, { skip: !id });
  const creative = orderData?.advertisements?.find((ad) => ad.platform === 'meta')?.creative;
  const formikCtx = useFormikContext<ICarouselCreativeFormValuesModel>();

  const dispatch = useAppDispatch();

  const downloadedCarouselIndex: string | null = getValueFromLs(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_INDEX);
  const existingDownloadedData = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA) || '[]');

  const [updatedIndex, setUpdatedIndex] = useState(0);

  const updateCarouselItemField = useCallback((platform: PlatformType, index: number, field: string, value: string) => {
    dispatch(updateFieldOfCarouselItem({ platform, index, field, value }));
  }, []);

  const registerProperty = useRegisterCarouselProperty(currentData, downloadedCarouselIndex, carouselItemIndex);
  const handleMediaLibraryClick = useHandleCarouselMedia(
    creative,
    setKeyValuePairToLS,
    getValueFromLs,
    openMediaLibrary
  );
  const onDeleteItemClick = useHandleCarouselDelete(formikCtx, platform, setUpdatedIndex);
  const handleClickOnDownloadIconCarousel = useHandleCarouselDownload(
    formikCtx,
    platform,
    updateCarouselItemField,
    setUpdatedIndex
  );
  const handleChange = useHandleCarouselChange(setKeyValuePairToLS, updateCarouselItemField);

  useRegisterPropertyEffect(registerProperty);

  useEffect(() => {
    handleError(isError, dispatch, toast);
    syncDownloadedData(downloadedCarouselIndex, currentData!, existingDownloadedData, setKeyValuePairToLS);
    updateFormikValues(formikCtx, existingDownloadedData, platform, updateCarouselItemField);
  }, [currentData, isError, updatedIndex, downloadedCarouselIndex]);

  return (
    <Grid item xs={12} key={carouselItemIndex} sx={{ mt: carouselItemIndex === 0 ? 3 : 0 }}>
      <Accordion TransitionProps={{ unmountOnExit: false }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <CarouselCreativeItemHeader
            carouselItemIndex={carouselItemIndex}
            handleMediaLibraryClick={handleMediaLibraryClick}
            onDeleteItemClick={onDeleteItemClick}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CarouselCreativeItemDetails
            carouselItem={carouselItem}
            carouselItemIndex={carouselItemIndex}
            platform={platform}
            downloadedCarouselIndex={downloadedCarouselIndex}
            isLoading={isLoading}
            isFetching={isFetching}
            handleMediaLibraryClick={handleMediaLibraryClick}
            handleChange={handleChange}
            handleClickOnDownloadIconCarousel={handleClickOnDownloadIconCarousel}
            formikCtx={formikCtx}
          />
        </AccordionDetails>
        <AccordionActions>
          <CommonButton size="small" color="error" onClick={() => onDeleteItemClick(carouselItemIndex)}>
            Delete
          </CommonButton>
        </AccordionActions>
      </Accordion>
    </Grid>
  );
};

export default CarouselCreativeItem;
