import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import List from '@mui/material/List';
import { FC, type JSX } from 'react';
import SharedLink from './SharedLink';
import StatusBadge from './StatusBadge';
import SummaryStats from './SummaryStats';

interface IAdvertisementInfoProps {
  advertisement: IFullAdvertisementModel;
}

const AdvertisementInfo: FC<IAdvertisementInfoProps> = (props: IAdvertisementInfoProps): JSX.Element => {
  const { advertisement } = props;

  return (
    <List>
      {/*<MediaLibraryTrigger advertisement={advertisement} />*/}
      <StatusBadge advertisement={advertisement} />
      <SharedLink advertisement={advertisement} />
      <SummaryStats advertisement={advertisement} />
    </List>
  );
};

export default AdvertisementInfo;
