import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { IBaseCreativeModel } from '@/shared/models/creatives/base-creative.model';

const useHandleCarouselMedia = (
  creative: IBaseCreativeModel | undefined,
  setKeyValuePairToLS: Function,
  getValueFromLs: Function,
  openMediaLibrary: Function,
) => {

  const handleMediaLibraryClick = (carouselItemIndex: number) => {
    openMediaLibrary(carouselItemIndex);
    const storedData = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_ARRAY) || '[]');
    const item = storedData[carouselItemIndex];
    const propertyIds = creative?.creativeItems?.map((item: any) => item.creativeMedia.propertyFile.propertyId);

    if (item && typeof item === 'object' && Object.keys(item).length > 0) {
      const propertyInternalId = storedData[carouselItemIndex]?.id;
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, propertyInternalId);
    } else if (propertyIds) {
      const id = propertyIds[carouselItemIndex];
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, id);
    } else {
      const id = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSEL_INTERNAL_ID);
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, id!);
    }
  };

  return handleMediaLibraryClick;
};

export default useHandleCarouselMedia;