import { FC } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForgetPasswordMutation } from '@/editor/services/http/users.api';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import Close from '@mui/icons-material/Close';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { Formik, useFormik } from 'formik';

import * as yup from 'yup';

import type { JSX } from 'react';

const validationSchema = yup.object().shape({
  email: yup.string().required('Required'),
});

export interface IForgetPass {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ForgetPasswordDialog: FC<IForgetPass> = ({ isOpen, onClose }): JSX.Element => {
  const { t } = useTranslation();
  const [forgetPassword] = useForgetPasswordMutation();
  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async (values: { email: string }) => {
    if (formik.touched && formik.isValid) {
      toast.promise(forgetPassword({ email: values.email }), {
        success: t('passwordUpdatedSuccessfully'),
        loading: t('updating'),
        error: t('errorWhileUpdatingPassword'),
      });
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: handleSubmit,
  });

  return (
    <Grid container>
      <Grid item xs={4}>
        <BootstrapDialog fullWidth open={isOpen} onClose={handleClose}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Send glemt passord
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <Close />
          </IconButton>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values: { email: string }) => {
              await handleSubmit(values);
            }}>
            {({ handleChange, handleSubmit }) => (
              <form noValidate onSubmit={(e) => handleSubmit(e)}>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    onChange={handleChange}
                    margin="dense"
                    id="name"
                    name="email"
                    label={t('email')}
                    type="email"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button type="submit" endIcon={<SendOutlined fontSize="small" />} variant="contained">
                    {t('submit')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </BootstrapDialog>
      </Grid>
    </Grid>
  );
};
export default ForgetPasswordDialog;
