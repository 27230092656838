import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const WebmeglerDeltaSinglePreview: FC<IExtendedPreviewPropsModel> = ({
  caption,
  propertyPriceQuote,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
}): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer?.creativeFormsState?.delta?.single?.media);
  return (
    <div>
      <Grid container sx={{ overflow: 'hidden' }}>
        {media?.preSignedUrl ? (
          <Grid
            item
            xs={12}
            className="eie-preview-box"
            sx={{
              backgroundImage: `url(${media?.preSignedUrl})`,
            }}>
            <Box className="eie-preview-box__button-container">
              <Button className="eie-preview-box__button-container__button">
                <Typography variant="h4">Se bolig</Typography>
              </Button>
            </Box>
          </Grid>
        ) : (
          <EmptyImageCard></EmptyImageCard>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ backgroundColor: '#0a1119' }}>
            <Grid item xs={3}>
              <img style={{ width: '100%' }} src="/images/eie/logo-with-text.png" alt="logo" className="logo" />
            </Grid>
            <Grid item xs={9}>
              <Grid container flexDirection="column">
                <Grid item xs={12}>
                  <Typography variant="h3" fontFamily="saol" fontWeight="bold" color="#fff">
                    <span>{caption}</span>
                  </Typography>
                  <Typography className="animated-words" variant="h3" fontFamily="saol" fontWeight="bold" color="#fff">
                    {propertyType && areaInUsage && (
                      <span>
                        {propertyType?.toUpperCase()} - {areaInUsage} kvm
                      </span>
                    )}
                    {ownershipType && <span>{ownershipType}</span>}
                  </Typography>
                  <Typography
                    className={propertyDebt ? 'animated-words' : ''}
                    variant="h3"
                    fontFamily="saol"
                    fontWeight="bold"
                    color="#fff">
                    {propertyPriceQuote && <span>{propertyPriceQuote?.toLocaleString()},-</span>}
                    {propertyDebt && <span>Fellesgjeld: {propertyDebt?.toLocaleString()},-</span>}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WebmeglerDeltaSinglePreview;
