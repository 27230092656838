import { toast } from 'react-hot-toast';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { clearLS } from '../localstorage';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // @ts-ignore
    const { originalStatus, status } = action.payload;

    if (originalStatus === 401 || originalStatus === 403 || status === 401 || status === 403) {
      clearLS();

      if (
        !window.location.href.includes('/sign-in') &&
        !window.location.href.includes('/integrations') &&
        !window.location.href.includes('/connections')
      ) {
        toast.error('Unauthorized action');
        setTimeout(() => (window.location.href = '/sign-in'), 200);
        return;
      }

      // toast.error('Incorrect credentials');
    }
  }

  return next(action);
};
