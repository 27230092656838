import { lazy } from 'react';
import { IRoute } from '../../shared/models/route.model';

export const ROUTE_ADVERTISEMENTS = Object.freeze({
  AdminAdvertisementsTablePage: 'advertisements',
});

export const AdvertisementsRoutes: IRoute[] = [
  {
    path: ROUTE_ADVERTISEMENTS.AdminAdvertisementsTablePage,
    component: lazy(() => import('../pages/advertisements/AdvertisementsOverviewAdminTable')),
  },
];
