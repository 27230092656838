import { FC, useCallback, type JSX } from 'react';
import CreativeTypesEnum, { CreativeType } from '../../../../../editor/shared/constants/creatives-types.enum';
import BasicSnapChatPreview from './BasicSnapChatPreview';
import CarouselSnapChatPreview from './CarouselSnapChatPreview';
import DynamicSnapChatPreview from './DynamicSnapChatPreview';
import SingleSnapChatPreview from './SingleSnapChatPreview';
import { useAppSelector } from '@/store/hooks/redux';

const SnapChatPreviewSummary: FC<{
  selectedType: CreativeType;
}> = ({ selectedType }): JSX.Element => {
  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);

  const resolveCurrentPreview = useCallback(() => {
    switch (selectedType) {
      case CreativeTypesEnum.SINGLE:
        return <SingleSnapChatPreview />;
      case CreativeTypesEnum.CAROUSEL:
        return <CarouselSnapChatPreview items={creativeFormsState['snapchat']['carousel']?.creativeItems ?? []} />;
      case CreativeTypesEnum.DYNAMIC:
        return <DynamicSnapChatPreview />;
    }
  }, [selectedType, creativeFormsState]);

  return (
    <div>
      <BasicSnapChatPreview showFooter={true} currentPreview={resolveCurrentPreview()!} />
    </div>
  );
};

export default SnapChatPreviewSummary;
