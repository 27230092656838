import { FC, type JSX } from 'react';
import { useAppSelector } from '../../../../../../store/hooks/redux';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IPlatformCreativeFormResolverProps } from '../models/form-resolver-props.interface';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import BidtheatreSingleCreativeForm from './BidtheatreSingleCreativeForm';
import { useGetMeQuery } from '@/editor/services/http/users.api';

const BidtheatreFormsResolver: FC<IPlatformCreativeFormResolverProps> = ({
  creativeType,
  handleSubmit,
  updating,
}): JSX.Element => {
  const initialValuesFromStore = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const { data: userData, isLoading: userDataLoading } = useGetMeQuery();

  return (
    <BidtheatreSingleCreativeForm
      updating={updating}
      initialValues={
        initialValuesFromStore[AvailablePlatformsEnum.BIDTHEATRE][
          CreativeTypesEnum.SINGLE
        ]! satisfies ISingleCreativeFormValuesModel
      }
      externalCompanyId={userData?.companyExternalId || ''}
    />
  );
};

export default BidtheatreFormsResolver;
