import { useFormikContext } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { debounce } from '@/shared/hooks/debounce';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import {
  ICreativeFormsStatePerPlatform,
  setFieldOfCreativeFormForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { AvailablePlatformsEnum, PlatformType } from '../../../constants/available-platforms';
import CreativeTypesEnum from '../../../constants/creatives-types.enum';
import { IBasicCreativeFormValues } from './models/basic-creative-form-values.model';
import { useTranslation } from 'react-i18next';

const BasicCreativeFields: FC<{
  platform: PlatformType;
  areFieldsRequired: boolean;
}> = ({ platform, areFieldsRequired }) => {
  const { getFieldMeta, handleChange } = useFormikContext();
  const dispatch = useAppDispatch();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);

  const { t } = useTranslation();

  const handleFieldChange = (value: string | number, field: keyof IBasicCreativeFormValues) => {
    debounce((platform: PlatformType, field: keyof IBasicCreativeFormValues, value: string | number) => {
      const creativeType =
        platform === AvailablePlatformsEnum.DELTA ||
        platform === AvailablePlatformsEnum.BIDTHEATRE ||
        platform === AvailablePlatformsEnum.BOARDS
          ? CreativeTypesEnum.SINGLE
          : selectedCreativeType;

      return dispatch(
        setFieldOfCreativeFormForPlatform({
          platform,
          creativeType: creativeType as keyof ICreativeFormsStatePerPlatform,
          field,
          value,
        })
      );
    }, 500)(platform, field, value);
  };

  return (
    <div>
      <CommonTextField
        sx={{
          mb: 2,
          display:
            platform === AvailablePlatformsEnum.DELTA ||
            platform === AvailablePlatformsEnum.SNAPCHAT ||
            platform === AvailablePlatformsEnum.BIDTHEATRE ||
            platform === AvailablePlatformsEnum.BOARDS
              ? 'none'
              : '',
        }}
        type="text"
        name="headline"
        label={`${t('headline')} ${(getFieldMeta('headline').value as string)?.length ?? 0}/1024`}
        value={getFieldMeta('headline').value as string}
        required
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
          handleFieldChange(e.target.value, 'headline');
        }}
        multiline
        minRows={3}
        maxRows={5}
        error={Boolean(getFieldMeta('headline').touched && getFieldMeta('headline').error)}
        fullWidth
        helperText={getFieldMeta('headline').touched && getFieldMeta('headline').error}
      />
      <CommonTextField
        sx={{ mb: 2 }}
        type="text"
        name="caption"
        required={areFieldsRequired}
        label={
          platform === AvailablePlatformsEnum.BIDTHEATRE
            ? `${t('caption')} ${(getFieldMeta('caption').value as string)?.length ?? 0}/40`
            : `${t('caption')} ${(getFieldMeta('caption').value as string)?.length ?? 0}/40`
        }
        value={getFieldMeta('caption').value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
          handleFieldChange(e.target.value, 'caption');
        }}
        error={Boolean(getFieldMeta('caption').touched && getFieldMeta('caption').error)}
        helperText={getFieldMeta('caption').touched && getFieldMeta('caption').error}
      />
      {platform !== AvailablePlatformsEnum.BOARDS ? (
        <div>
          <CommonTextField
            sx={{ mb: 2 }}
            type="text"
            name="description"
            required={areFieldsRequired}
            label={`${t('description')} ${(getFieldMeta('description').value as string)?.length ?? 0}/255`}
            value={getFieldMeta('description').value as string}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              handleFieldChange(e.target.value, 'description');
            }}
            multiline
            minRows={2}
            maxRows={4}
            error={Boolean(getFieldMeta('description').touched && getFieldMeta('description').error)}
            fullWidth
            helperText={getFieldMeta('description').touched && getFieldMeta('description').error}
          />
          <CommonTextField
            sx={{ mb: 2 }}
            type="text"
            name="link"
            required
            label={`${t('link')} ${(getFieldMeta('link').value as string)?.length ?? 0}/255`}
            value={getFieldMeta('link').value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              handleFieldChange(e.target.value, 'link');
            }}
            error={Boolean(getFieldMeta('link').touched && getFieldMeta('link').error)}
            helperText={getFieldMeta('link').touched && getFieldMeta('link').error}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default BasicCreativeFields;
