import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import CommonTextField from '../../../../../../shared/components/CommonTextField';
import BidTheatrePreviewResolver from '@/shared/components/platform-previews/previews/bidtheatre/BidTheatrePreviewResolver';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import {
  setcreativeSubmissionProgress,
  setFieldOfCreativeFormForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IEditorOrderFormPropertiesBaseModel } from '../../editor-order-form-properties-base.model';
import BasicCreativeForm from '../BasicCreativeForm';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import { basicBidtheatreCreativeSchema } from '../shared/basic-validation-schema';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';
import ExternalCompanyIdEnum from '@/shared/constants/external-company-id.enum';

// TODO REFACTOR !!!

export interface IBidtheatreSingleCreativeFormProps
  extends IEditorOrderFormPropertiesBaseModel<ISingleCreativeFormValuesModel> {}

const BidtheatreSingleCreativeForm: FC<IBidtheatreSingleCreativeFormProps> = ({
  initialValues,
  updating,
  externalCompanyId,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const singleReduxFormState = useAppSelector(
    (state) =>
      state.creativeStepSliceReducer.creativeFormsState[AvailablePlatformsEnum.BIDTHEATRE][CreativeTypesEnum.SINGLE]
  );
  const platform = AvailablePlatformsEnum.BIDTHEATRE;
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const { t } = useTranslation();
  const { data: userData } = useGetMeQuery();

  const formik = useFormik({
    initialValues: {
      ...initialValues!,
      nativeAd: true,
      employeeName: initialValues?.employeeName ?? '',
      employeePhone: initialValues?.employeePhone ?? '',
      employeeEmail: initialValues?.employeeEmail ?? '',
      employeeAvatar: initialValues?.employeeAvatar ?? '',
    },
    validationSchema: basicBidtheatreCreativeSchema.concat(
      Yup.object({
        media: Yup.object().nullable().required('Media required'),
      })
    ),
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue('media', singleReduxFormState?.media);
  }, [singleReduxFormState?.media]);

  const { handleSubmit } = useFormSubmission({
    formik,
    formSubmissionProgress,
    updating,
    platform: AvailablePlatformsEnum.BIDTHEATRE,
  });

  const handleUpdate = () => {
    const { caption, viewDate, link, description, propertyEstateType, propertyDescription2 } = formik.values;
    formik.setTouched({
      caption: true,
      link: true,
      description: true,
    });

    basicBidtheatreCreativeSchema
      .validate({
        caption,
        viewDate,
        propertyEstateType,
        propertyDescription2,
        link,
        description,
      })
      .then(() => {
        dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
      })
      .catch((validationError) => {
        dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: platform }));
        toast.error(validationError.errors);
      });
  };

  useEffect(() => {
    handleSubmit();
    getViewDateValue();
    getDebtValue();
    getPriceValue();
    getNativeAddValue();
  }, [formSubmissionProgress.active, formik.values.priceQuote, formik.values.bidPriceQuote]);

  const getViewDateValue = () => {
    if (formik.getFieldMeta('propertyDescription2').value) {
      var viewDate = formik.getFieldMeta('propertyDescription2').value;
    } else {
      var viewDate = formik.getFieldMeta('viewDate').value;
    }

    formik.setFieldValue('viewDate', viewDate);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BIDTHEATRE,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'viewDate',
        value: viewDate,
      })
    );
  };

  const getDebtValue = () => {
    if (formik.getFieldMeta('bidStateDebt').value) {
      var stateDebt = formik.getFieldMeta('bidStateDebt').value;
    } else {
      var stateDebt = formik.getFieldMeta('stateDebt').value;
    }

    formik.setFieldValue('stateDebt', stateDebt);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BIDTHEATRE,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'stateDebt',
        value: stateDebt,
      })
    );
  };

  const getPriceValue = () => {
    if (formik.getFieldMeta('bidPriceQuote').value) {
      var priceQuote = formik.getFieldMeta('bidPriceQuote').value;
    } else {
      var priceQuote = formik.getFieldMeta('priceQuote').value;
    }

    formik.setFieldValue('priceQuote', priceQuote);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        creativeType: CreativeTypesEnum.SINGLE,
        platform: AvailablePlatformsEnum.BIDTHEATRE,
        field: 'priceQuote',
        value: priceQuote,
      })
    );
  };

  const getNativeAddValue = () => {
    var nativeAd = initialValues?.nativeAd !== undefined ? initialValues?.nativeAd : true;
    formik.setFieldValue('nativeAd', nativeAd);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BIDTHEATRE,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'nativeAd',
        value: nativeAd,
      })
    );
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          userData={userData}
          basePreviews={[BidTheatrePreviewResolver]}
          concretePreviews={[BidTheatrePreviewResolver]}
          areFieldsRequired={true}
          platform={AvailablePlatformsEnum.BIDTHEATRE}
          initialValues={initialValues!}
          externalCompanyId={externalCompanyId}
          additionalFields={
            <div>
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="ownershipType"
                label={`${t('ownershipType')} ${
                  (formik.getFieldMeta('ownershipType').value as string)?.length ?? 0
                }/255*`}
                value={formik.getFieldMeta('ownershipType').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'ownershipType',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="areaInUsage"
                label={`${t('areaInUsage')} ${(formik.getFieldMeta('areaInUsage').value as string)?.length ?? 0}/255*`}
                value={formik.getFieldMeta('areaInUsage').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'areaInUsage',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="propertyDescription2"
                label={`${t('viewDate')} ${(formik.getFieldMeta('viewDate').value as string)?.length ?? 0}/45*`}
                value={formik.getFieldMeta('viewDate').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'propertyDescription2',
                      value: e.target.value,
                    })
                  );
                  formik.setFieldValue('viewDate', e.target.value);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'viewDate',
                      value: e.target.value,
                    })
                  );
                }}
                helperText={formik.errors.viewDate}
                error={Boolean(formik.errors.viewDate)}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="bidStateDebt"
                label={`${t('propertyDebt')} ${(formik.getFieldMeta('stateDebt').value as string)?.length ?? 0}/255*`}
                value={formik.getFieldMeta('stateDebt').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'bidStateDebt',
                      value: e.target.value,
                    })
                  );
                  formik.setFieldValue('stateDebt', e.target.value);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'stateDebt',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="bidPriceQuote"
                label={`${t('propertyPriceQuote')} ${
                  (formik.getFieldMeta('priceQuote').value as string)?.length ?? 0
                }/255*`}
                value={formik.getFieldMeta('priceQuote').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'bidPriceQuote',
                      value: e.target.value,
                    })
                  );
                  formik.setFieldValue('priceQuote', e.target.value);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'priceQuote',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <CommonTextField
                sx={{ mb: '15px' }}
                type="text"
                name="propertyEstateType"
                label={`${t('propertyEstateType')} ${
                  (formik.getFieldMeta('propertyEstateType').value as string)?.length ?? 0
                }/20*`}
                value={formik.getFieldMeta('propertyEstateType').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      creativeType: CreativeTypesEnum.SINGLE,
                      platform: AvailablePlatformsEnum.BIDTHEATRE,
                      field: 'propertyEstateType',
                      value: e.target.value,
                    })
                  );
                }}
                helperText={formik.errors.propertyEstateType}
                error={Boolean(formik.errors.propertyEstateType)}
              />
              {(externalCompanyId == ExternalCompanyIdEnum.MSPECS ||
                externalCompanyId == ExternalCompanyIdEnum.FREMEIENDOMSMEGLING ||
                externalCompanyId == ExternalCompanyIdEnum.SMG ||
                externalCompanyId == ExternalCompanyIdEnum.STRAND_MAKLERI ||
                externalCompanyId == ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI ||
                externalCompanyId == ExternalCompanyIdEnum.HOME_BY_DEAN) && (
                <div>
                  <CommonTextField
                    sx={{ mb: '15px' }}
                    type="email"
                    name="employeeEmail"
                    label={`${t('Broker Email')}`}
                    value={formik.getFieldMeta('employeeEmail').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BIDTHEATRE,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'employeeEmail',
                          value: e.target.value,
                        })
                      );
                    }}
                  />

                  <CommonTextField
                    sx={{ mb: '15px' }}
                    type="text"
                    name="employeePhone"
                    label={`${t('Broker Number')}`}
                    value={formik.getFieldMeta('employeePhone').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BIDTHEATRE,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'employeePhone',
                          value: e.target.value,
                        })
                      );
                    }}
                  />

                  <CommonTextField
                    sx={{ mb: '15px' }}
                    type="text"
                    name="employeeName"
                    label={`${t('Broker Name')}`}
                    value={formik.getFieldMeta('employeeName').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BIDTHEATRE,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'employeeName',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </div>
              )}
              <FormControlLabel
                sx={{ mb: '15px' }}
                control={
                  <Checkbox
                    name="nativeAd"
                    defaultChecked={initialValues?.nativeAd !== undefined ? initialValues?.nativeAd : true}
                    value={formik.getFieldMeta('nativeAd').value}
                    onChange={(e) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          creativeType: CreativeTypesEnum.SINGLE,
                          platform: AvailablePlatformsEnum.BIDTHEATRE,
                          field: 'nativeAd',
                          value: e.target.checked,
                        })
                      );
                    }}
                  />
                }
                label={t('nativeAd')}
                disabled={updating}
              />
            </div>
          }
        />
        <Grid container>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};
export default BidtheatreSingleCreativeForm;
