import { api } from '../../../shared/services/api/api';
import { IMoovlyTemplateApiResponseModel } from '../../../shared/models/moovly/moovly-template-api-response.model';
import { IMoovlyReadyToSendModel } from '../../../shared/models/moovly/moovly-ready-to-send.model';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IPropertyApiResponseModel } from '@/shared/models/property.api.response.model';
import { IPropertyModel } from '@/shared/models/property.model';
import { useAppDispatch } from '@/store/hooks/redux';
import { setPropertyExtId } from '@/editor/store/reducers/property-info.slice';

const baseUrl = 'video-maker';

interface IMoovlyResponse {
  templateData: IMoovlyTemplateApiResponseModel;
  internalPropertyId: string;
}

export const customerMoovlyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMoovlyTemplates: build.query<IMoovlyResponse, { propertyExtId: string | null; companyId: string }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const propertyQuery = await fetchWithBQ(`retail-providers/data/${_arg.companyId}/${_arg.propertyExtId}`);

        if (propertyQuery.error) return { error: propertyQuery.error as FetchBaseQueryError };

        const propertyData = propertyQuery.data as IPropertyApiResponseModel;

        console.log(propertyData);

        const registerPropertyRequest = await fetchWithBQ({
          url: `/properties`,
          method: 'POST',
          body: { propertyId: _arg.propertyExtId, address: propertyData.propertyAddress },
        });

        if (registerPropertyRequest.error) return { error: registerPropertyRequest.error as FetchBaseQueryError };
        const registeredPropertyData = registerPropertyRequest.data as IPropertyModel;

        console.log(registeredPropertyData);

        const templateQuery = await fetchWithBQ({
          url: `${baseUrl}/templates`,
          method: 'GET',
          params: {
            propertyExtId: registeredPropertyData.propertyId,
          },
        });

        return templateQuery.data
          ? {
              data: {
                templateData: templateQuery.data as IMoovlyTemplateApiResponseModel,
                internalPropertyId: registeredPropertyData.id,
              },
            }
          : { error: templateQuery.error as FetchBaseQueryError };
      },
    }),
    createMoovly: build.mutation<void, IMoovlyReadyToSendModel>({
      query: (val: IMoovlyReadyToSendModel) => ({
        url: `${baseUrl}/actions:initProduction`,
        method: 'POST',
        body: val,
      }),
    }),
  }),
});

export const { useGetMoovlyTemplatesQuery, useLazyGetMoovlyTemplatesQuery, useCreateMoovlyMutation } =
  customerMoovlyApi;
