import { IRoute, ISideBarLink } from '@/shared/models/route.model';
import AdminLayout from '../layout/AdminLayout';
import MainAdminPage from '../pages/main-page/MainAdminPage';
import AdminGuard from '../guards/AdminGuard';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import DiamondTwoToneIcon from '@mui/icons-material/DiamondTwoTone';
import { ROUTE_USERS_DECLARATIONS, AdminUsersRoutes } from './users-routes';
import { ROUTE_RETAIL_PROVIDERS_DECLARATIONS, RetailDataProvidersRoutes } from './retail-data-providers-routes';
import { CompaniesRoutes } from './companies-routes';
import { OfficesRoutes } from './offices-routes';
import { BudgetsRoutes } from './budgets-routes';
import { BudgetsItemsRoutes } from './budget-items-routes';
import { ApplicationLogsRoutes, ROUTE_APP_LOGS } from './application-logs-routes';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import TranslateIcon from '@mui/icons-material/Translate';
import { AdvertisementsRoutes, ROUTE_ADVERTISEMENTS } from './advertisements-routes';
import { AdminLanguagesRoutes, ROUTE_LANGUAGES_DECLARATIONS } from '@/admin/routes/language-routes';
import { AdminProductsRoutes, ROUTE_PRODUCTS_DECLARATIONS } from '@/admin/routes/products-routes';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { ProductsItemsRoutes } from './product-items-routes';

export const ROUTES_DECLARATIONS = Object.freeze({
  AdminPanel: '/dashboard',
  AdminMainPage: 'main',
  AdminCompaniesListPage: 'companies',
  AdminOfficesListPage: 'offices',
});

export const adminRoutes: IRoute[] = [
  {
    path: ROUTES_DECLARATIONS.AdminPanel,
    component: AdminLayout,
    guard: AdminGuard,
    children: [
      {
        path: ROUTES_DECLARATIONS.AdminMainPage,
        component: MainAdminPage,
      },
      ...AdminUsersRoutes,
      ...RetailDataProvidersRoutes,
      ...CompaniesRoutes,
      ...OfficesRoutes,
      ...BudgetsRoutes,
      ...BudgetsItemsRoutes,
      ...ApplicationLogsRoutes,
      ...AdvertisementsRoutes,
      ...AdminLanguagesRoutes,
      ...AdminProductsRoutes,
      ...ProductsItemsRoutes,
    ],
  },
];

export const adminSideBarLinks: ISideBarLink[] = [
  {
    title: 'orders',
    path: ROUTES_DECLARATIONS.AdminMainPage,
    icon: DashboardTwoToneIcon,
  },
  {
    title: 'ads',
    path: ROUTE_ADVERTISEMENTS.AdminAdvertisementsTablePage,
    icon: ContentPasteSearchIcon,
  },
  {
    title: 'users',
    path: ROUTE_USERS_DECLARATIONS.AdminUsersPage,
    icon: GroupTwoToneIcon,
  },
  {
    title: 'companies',
    path: ROUTES_DECLARATIONS.AdminCompaniesListPage,
    icon: ApartmentTwoToneIcon,
  },
  {
    title: 'offices',
    path: ROUTES_DECLARATIONS.AdminOfficesListPage,
    icon: MeetingRoomTwoToneIcon,
  },
  {
    title: 'providers',
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersPage,
    icon: DiamondTwoToneIcon,
  },
  {
    title: 'languages',
    path: ROUTE_LANGUAGES_DECLARATIONS.AdminLanguagePage,
    icon: TranslateIcon,
  },
  {
    title: 'applicationLogs',
    path: ROUTE_APP_LOGS.AdminApplicationLogsTablePage,
    icon: ContentPasteSearchIcon,
  },
  {
    title: 'products',
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsPage,
    icon: Inventory2OutlinedIcon,
  },
];
