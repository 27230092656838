import { IBasicPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import FacebookOutlined from '@mui/icons-material/FacebookOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';

const BasicMetaPreview: FC<IBasicPreviewPropsModel> = ({
  headline,
  caption,
  description,
  currentPreview,
  error,
  errorMessage,
  showFooter,
}): JSX.Element => {
  return (
    <Box component="div">
      <Paper sx={{ border: error ? '1px solid red' : '', minWidth: '450px', maxWidth: '600px', mb: 2 }}>
        {error && errorMessage && (
          <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="h6" color="error" sx={{ p: 2, pl: 3 }}>
              {errorMessage}
            </Typography>
          </Box>
        )}
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <FacebookOutlined fontSize="large"></FacebookOutlined>
              <Typography variant="caption">Sponset</Typography>
            </Box>
            <Box component="div">
              <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>
                {headline}
              </Typography>
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ m: 0 }}>
              {currentPreview}
            </Grid>
          </Grid>
          {showFooter && (
            <Grid container sx={{ mt: '-7px', maxWidth: '600px' }}>
              <Grid item xs={12} sx={{ backgroundColor: '#f2f3f5', p: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'pre-wrap' }}>
                  {caption}
                </Typography>
                <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="caption">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default BasicMetaPreview;
