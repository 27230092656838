import DeltaSummaryStats from '@/shared/components/advertisement-info/DeltaSummaryStats';
import DeltaPreviewSummary from '@/shared/components/platform-previews/previews/delta/DeltaPreviewSummary';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import NoData from '@/statistics/templates/shared/NoData';
import { useAppSelector } from '@/store/hooks/redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import SectionHeader from '../SectionHeader';

export interface IEieDeltaSectionProps {
  advertisement: IFullAdvertisementModel;
  extCompanyId: string;
}

const EieDeltaSection: FC<IEieDeltaSectionProps> = (props: IEieDeltaSectionProps): JSX.Element => {
  const {
    advertisement: { advertisementStats },
    extCompanyId,
  } = props;

  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const currentCreativeForm = creativeFormsState['delta']['single'];

  return (
    <div>
      {advertisementStats.deltaStats ? (
        <Card sx={{ width: '100%' }}>
          <SectionHeader
            platform="OG Display"
            totalClicks={advertisementStats?.deltaStats?.totalClicks ?? 0}
            totalImpressions={advertisementStats?.deltaStats?.totalImpressions ?? 0}
          />
          <CardContent>
            <Grid container display="flex" justifyContent="center" alignItems="center" spacing={5}>
              <Grid item xs={12} sm={5} display="flex" justifyContent="center">
                <DeltaPreviewSummary externalCompanyId={extCompanyId} values={currentCreativeForm!} />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 6 }} />

            {advertisementStats.deltaStats?.totalClicks > 50 ? (
              <Grid container mt="3rem">
                <Grid item xs={12}>
                  <DeltaSummaryStats deltaStats={advertisementStats?.deltaStats!} />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} m={2} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography m={2} variant="h2">
                  Annonsen din har ikke generert nok visninger ennå, statistikk vil bli tilgjengelig senere.
                </Typography>
              </Grid>
            )}
          </CardContent>
        </Card>
      ) : (
        <NoData title="OG Display stats" />
      )}
    </div>
  );
};

export default EieDeltaSection;
